import { PrimaryButton } from '@/components/common';
import DropdownTemplate from '@/components/common/dropdown-template/dropdown-template';
import { useExperienceContent } from '@/hooks/use-server-content';
import { ExperienceFragmentModel } from '@/services/content-service/content-service.interface';
import { useOrderDetailsContent } from '@/views/order-details-view/hooks/use-order-details-content';
import React, { useState } from 'react';

export interface ReasonDropdownContent extends ExperienceFragmentModel {
    dropdownOptions: ReasonOptions[];
    ':type': string;
}

export interface ReasonOptions {
    returnReasonLabel: string;
    returnReasonValue: string;
}

const CreateReturnComponent = () => {
    const [reasonValue, setReasonValue] = useState('');
    const [experienceFragmentReasonDropdownContent] =
        useExperienceContent<ReasonDropdownContent>(
            'common',
            'return-reason',
            'orderreturnreasondro'
        );
    const createReturnContent = useOrderDetailsContent();

    const dropdownTemplateContent = [];
    experienceFragmentReasonDropdownContent?.dropdownOptions.map((item) =>
        dropdownTemplateContent.push(item.returnReasonValue)
    );

    return (
        <>
            <div
                className="create-return-component-container"
                data-test-id="create-return-component"
            >
                <h1 className="create-return-component-title">
                    {createReturnContent?.returnspageHeader ||
                        'Select Items to Return'}
                </h1>
                <div className="create-return-component-items"></div>
                <hr className="hr-line" aria-hidden={true} />
                <div className="create-return-component-reason-box">
                    <p>{createReturnContent?.returnsDropdown}</p>
                    <DropdownTemplate
                        DropdownTemplateContent={dropdownTemplateContent}
                        field={''}
                        selectedItem={reasonValue}
                        setSelectedItem={setReasonValue}
                        dropdownTemplateLabel=""
                    ></DropdownTemplate>
                    <PrimaryButton>
                        {createReturnContent?.continue || 'continue'}
                    </PrimaryButton>
                </div>
            </div>
        </>
    );
};

export default CreateReturnComponent;
