export const profileWithVehicleResponse = {
    profile: {
        address1: '123 test drive',
        appreciationId: '12345',
        memberId: 2131123,
        city: 'Allen Park',
        communicationMethod: 1,
        consumerType: 'Personal',
        country: 'USA',
        email: 'test@gmail.com',
        firstName: 'test',
        language: 'en-US',
        lastName: 'test',
        lastUpdatedDate: '2020-07-06T15:37:00.970+0000',
        notificationPrefBillReminder: 0,
        notificationPrefMarketingMsg: 0,
        notificationPrefOwnership: 0,
        notificationPrefVehicleHealth: 0,
        notificationPrefVehicleRemote: 0,
        phoneNumber: '1234567890',
        address2: '',
        partnerRelationships: [
            {
                identifierInPartnerSystem:
                    '998C0E3D-2CC1-4608-94AF-BAFD45AEDF78',
                partnerName: 'IdpUserId',
            },
        ],
        preferredLanguage: 'en-US',
        privacyAccepted: false,
        privacyPreferences: [
            {
                preferenceCode: 'EmailPermission',
                preferenceType: 'Y',
                preferenceStartDate: '2020-06-13T00:23:32.000Z',
                preferenceName: 'Email Permission',
            },
            {
                preferenceCode: 'LincolnSalesOffers',
                preferenceType: '1',
                preferenceStartDate: '2020-03-12T21:15:14.013Z',
                preferenceName: 'LincolnSalesOffers',
            },
            {
                preferenceCode: 'LincolnServiceOffers',
                preferenceType: '1',
                preferenceStartDate: '2020-03-12T21:15:14.013Z',
                preferenceName: 'LincolnServiceOffers',
            },
            {
                preferenceCode: 'CorpPhilanthropic',
                preferenceType: '1',
                preferenceStartDate: '2020-03-12T21:15:14.013Z',
                preferenceName: 'CorpPhilanthropic',
            },
            {
                preferenceCode: 'CorpSweepStakes',
                preferenceType: '1',
                preferenceStartDate: '2020-03-12T21:15:14.013Z',
                preferenceName: 'CorpSweepStakes',
            },
            {
                preferenceCode: 'CorpFordParts',
                preferenceType: '1',
                preferenceStartDate: '2020-03-12T21:15:14.013Z',
                preferenceName: 'CorpFordParts',
            },
            {
                preferenceCode: 'LincolnWay',
                preferenceType: '1',
                preferenceStartDate: '2020-03-12T21:15:14.013Z',
                preferenceName: 'LincolnWay',
            },
            {
                preferenceCode: 'CorpFordPerformance',
                preferenceType: '0',
                preferenceStartDate: '2020-03-10T23:46:42.043Z',
                preferenceName: 'CorpFordPerformance',
            },
            {
                preferenceCode: 'FordPass',
                preferenceType: '1',
                preferenceStartDate: '2020-06-03T20:06:42.043Z',
                preferenceName: 'FordPass',
            },
            {
                preferenceCode: 'FordProductUpdates',
                preferenceType: '1',
                preferenceStartDate: '2020-03-12T21:15:14.013Z',
                preferenceName: 'FordProductUpdates',
            },
            {
                preferenceCode: 'FordSalesOffers',
                preferenceType: '1',
                preferenceStartDate: '2020-03-12T21:15:14.013Z',
                preferenceName: 'FordSalesOffers',
            },
            {
                preferenceCode: 'FordServiceOffers',
                preferenceType: '1',
                preferenceStartDate: '2020-03-12T21:15:14.013Z',
                preferenceName: 'FordServiceOffers',
            },
            {
                preferenceCode: 'LincolnProductUpdates',
                preferenceType: '1',
                preferenceStartDate: '2020-03-12T21:15:14.013Z',
                preferenceName: 'LincolnProductUpdates',
            },
            {
                preferenceCode: 'EmailPreference',
                preferenceType: 'Y',
                preferenceStartDate: '2020-06-13T00:23:32.000Z',
                preferenceName: 'Email Preference',
            },
        ],
        profileAttributes: [
            {
                sourceCode: '100392',
                sourceDescription: 'OWNER WEB',
                registrationDate: '2018-05-01T14:04:05.570Z',
                noOfLogin: 169,
                lastLoginDate: '2020-07-06T15:00:42.937Z',
                lastUpdateDate: '2020-07-06T15:00:42.937Z',
                registeredSource: 'W',
                createdDate: '2018-05-01T14:04:05.680Z',
            },
        ],
        profileStoreName: 'SCA-CAP',
        sendMarketingEmails: 1,
        state: 'mi',
        termsAccepted: 1,
        termsVersion: '1.0',
        timeZone: 'US_EASTERN',
        uomDistance: 1,
        uomPressure: 'PSI',
        uomSpeed: 'MPH',
        userGuid: '998C0E3D-2CC1-4608-94AF-BAFD45AEDF78',
        userId: 'dhruvanshu@gmail.com',
        userIdentity: {
            identificationCountry: 'ARG',
            identificationSubType: 'DNI',
            identificationType: 'National Identifiication',
            identificationValue: '1234567',
        },
        zip: '48335',
        communicationChannel: {
            email: true,
            messageCenter: true,
            push: true,
            sms: false,
        },
    },
    vehicles: [
        {
            bodyStyle: 'Passenger Car',
            cylinders: '4',
            drivetrain: 'FWD',
            ctaLabel: 'test',
            drivingConditionId: -1,
            engineDisp: '2.0 L',
            estimatedMileage: '0',
            fuelType: 'Electric And Gas Hybrid',
            lifeStyleXML: '',
            make: 'Ford',
            mileageDate: '01/01/0001 00:00:00',
            modelName: 'C-MAX Energi',
            modelYear: '2016',
            nickName: 'Test',
            ownerindicator: 'N',
            primaryIndicator: '',
            registrationDate: '2020-03-10T22:43:52.013Z',
            series: 'Premium Sel',
            syncVehicleIndicator: '',
            transmission: '',
            vehicleType: '2016 C-MAX Energi',
            vhrNotificationDate: '01/01/0001 00:00:00',
            vhrReadyDate: '01/01/0001 00:00:00',
            vin: '1FADP5CU2GL112337',
            preferredDealer: '',
        },
        {
            bodyStyle: 'Passenger Car',
            cylinders: '4',
            drivetrain: 'FWD',
            ctaLabel: 'test',
            drivingConditionId: -1,
            engineDisp: '2.0 L',
            estimatedMileage: '0',
            fuelType: 'Gas',
            lifeStyleXML: '',
            make: 'Ford',
            mileageDate: '01/01/0001 00:00:00',
            modelName: 'Edge',
            modelYear: '2019',
            nickName: 'Test2',
            ownerindicator: 'N',
            primaryIndicator: '',
            registrationDate: '2019-07-30T22:43:52.013Z',
            series: 'Premium Sel',
            syncVehicleIndicator: '',
            transmission: '',
            vehicleType: '2019 Edge',
            vhrNotificationDate: '01/01/0001 00:00:00',
            vhrReadyDate: '01/01/0001 00:00:00',
            vin: '2FMGK3G9XKBC51314',
            preferredDealer: '553LEO',
        },
        {
            bodyStyle: 'Passenger Car',
            cylinders: '4',
            drivetrain: 'FWD',
            ctaLabel: 'test',
            drivingConditionId: -1,
            engineDisp: '2.0 L',
            estimatedMileage: '0',
            fuelType: 'Gas',
            lifeStyleXML: '',
            make: 'Ford',
            mileageDate: '01/01/0001 00:00:00',
            modelName: 'Edge',
            modelYear: '2019',
            nickName: 'Test2',
            ownerindicator: 'N',
            primaryIndicator: '',
            registrationDate: '2019-07-30T22:43:52.013Z',
            series: 'Premium Sel',
            syncVehicleIndicator: '',
            transmission: '',
            vehicleType: '2019 Edge',
            vhrNotificationDate: '01/01/0001 00:00:00',
            vhrReadyDate: '01/01/0001 00:00:00',
            vin: '2FMGK3G9XKBC51314',
            preferredDealer: '',
        },
    ],
};

export const mockData = {
    httpStatus: 200,
    lastRequested: '2020-07-06T15:52:25.539+0000',
    value: profileWithVehicleResponse,
    requestStatus: 'CURRENT',
    status: 200,
    version: '1.0.0',
};

export const mockUserProfileData = {
    profile: {
        address1: '16800 executive dr',
        address2: '',
        address3: null,
        address4: null,
        appreciationId: null,
        memberId: '1434819351',
        birthYearMonth: null,
        city: 'dearborn',
        consumerType: 'Personal',
        country: 'USA',
        email: 'FORD.FNV2.02@GMAIL.COM',
        firstName: 'Ford',
        gender: null,
        language: 'en-US',
        lastName: 'FNV2.02',
        maritalStatus: null,
        middleName: null,
        mobilePhoneNumber: null,
        phoneNumber: '1234567890',
        phoneNumberExtension: null,
        privacyVersion: null,
        profileStoreName: 'SCA-CAP',
        secondLastName: null,
        state: 'MI',
        userDisplayName: null,
        userId: 'ford.fnv2.02@gmail.com',
        userIdentity: null,
        verifiedPhoneNumber: null,
        zip: '48188',
        uomPressure: 'kpa',
    },
};

export const mockUserProfileDataWithMobileNumber = {
    profile: {
        address1: '16800 executive dr',
        address2: '',
        address3: null,
        address4: null,
        appreciationId: null,
        memberId: '1434819351',
        birthYearMonth: null,
        city: 'dearborn',
        consumerType: 'Personal',
        country: 'USA',
        email: 'FORD.FNV2.02@GMAIL.COM',
        firstName: 'Ford',
        gender: null,
        language: 'en-US',
        lastName: 'FNV2.02',
        maritalStatus: null,
        middleName: null,
        mobilePhoneNumber: '1234567890',
        phoneNumber: '1234567890',
        phoneNumberExtension: null,
        privacyVersion: null,
        profileStoreName: 'SCA-CAP',
        secondLastName: null,
        state: 'MI',
        userDisplayName: null,
        userId: 'ford.fnv2.02@gmail.com',
        userIdentity: null,
        verifiedPhoneNumber: null,
        zip: '48188',
    },
};

export const mockUserProfileDataWithPrivacyPreferences = {
    profile: {
        address1: '16800 executive dr',
        address2: '',
        address3: null,
        address4: null,
        appreciationId: null,
        memberId: '1434819351',
        birthYearMonth: null,
        city: 'dearborn',
        consumerType: 'Personal',
        country: 'USA',
        email: 'FORD.FNV2.02@GMAIL.COM',
        firstName: 'Ford',
        gender: null,
        language: 'en-US',
        lastName: 'FNV2.02',
        maritalStatus: null,
        middleName: null,
        mobilePhoneNumber: null,
        phoneNumber: '1234567890',
        phoneNumberExtension: null,
        privacyVersion: null,
        privacyPreferences: [
            {
                preferenceCode: '1',
                preferenceEndDate: null,
                preferenceName: 'EmailPermission',
                preferenceStartDate: null,
                preferenceType: null,
            },
            {
                preferenceCode: '1',
                preferenceEndDate: null,
                preferenceName: 'Profiling',
                preferenceStartDate: null,
                preferenceType: null,
            },
            {
                preferenceCode: '0',
                preferenceEndDate: null,
                preferenceName: 'MailPermission',
                preferenceStartDate: null,
                preferenceType: null,
            },
            {
                preferenceCode: '0',
                preferenceEndDate: null,
                preferenceName: 'InAppMessagingPermission',
                preferenceStartDate: null,
                preferenceType: null,
            },
        ],
        profileStoreName: 'SCA-CAP',
        secondLastName: null,
        state: 'MI',
        userDisplayName: null,
        userId: 'ford.fnv2.02@gmail.com',
        userIdentity: null,
        verifiedPhoneNumber: null,
        zip: '48188',
    },
};
export const addVehicleResponse = {
    status: 200,
};

class ProfileService {
    public request = () => Promise.resolve(profileWithVehicleResponse);
    public addVehicle = () => Promise.resolve(addVehicleResponse);

    public persistVehicleSelection = () => jest.fn();
    public getUserPreferences = (): any => jest.fn();
}

export default ProfileService;
