export interface OrderStatusOption {
    name: string;
    options: string[];
}

export interface StatusMapKeys {
    shipToHome: OrderStatusOption[];
    pickUpOrder: OrderStatusOption[];
    installLines: OrderStatusOption[];
}

export const NVC_ORDER_STATUS_MAP: StatusMapKeys = {
    shipToHome: [
        { name: 'shipped', options: ['completed'] },
        { name: 'preparingToShip', options: ['included in shipment'] },
        { name: 'returnCreated', options: ['return created'] },
        { name: 'processing', options: ['scheduled', 'released', 'received'] },
        { name: 'backordered', options: ['backordered'] },
        { name: 'placed', options: ['created'] },
        { name: 'returned', options: ['return completed'] },
        { name: 'cancelled', options: ['cancelled'] },
    ],
    pickUpOrder: [
        { name: 'readyForPickup', options: ['included in shipment'] },
        { name: 'pickedUp', options: ['picked up'] },
        { name: 'returnCreated', options: ['return created'] },
        { name: 'inTransitToDealer', options: ['shipment in transit'] },
        {
            name: 'processing',
            options: [
                'scheduled',
                'released',
                'received',
                'warehouse processing',
            ],
        },
        { name: 'placed', options: ['created'] },
        { name: 'returned', options: ['return completed'] },
        { name: 'cancelled', options: ['cancelled'] },
    ],
    installLines: [
        { name: 'orderDelivered', options: ['order delivered'] },
        { name: 'created', options: ['created'] },
    ],
};

export const NVC_GREEN_STATUSES: string[] = [
    'preparing to ship',
    'shipped',
    'returned',
    'in transit to dealer',
    'ready for pickup',
    'picked up',
    'order delivered',
    'completed',
    'included in shipment',
    'return completed',
    'shipment in transit',
    'picked up',
];

export const NVC_RED_STATUSES: string[] = [
    'not eligible for return',
    'backordered',
    'cancelled',
];
