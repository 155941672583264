import HttpService from '@services/http-service/http-service';
import { DSL_API_PATH, DSL_BASE_URL } from '@constants';
import httpAdapter from 'axios/lib/adapters/http';
import { HttpsProxyAgent } from 'https-proxy-agent';

export interface Item {
    itemId: string;
    itemShortDesc: string;
}

interface Items {
    ItemID: string;
    ItemShortDesc: string;
}

interface OrderLines {
    OrderLine: OrderLine[];
    TotalNumberOfRecords: string;
}

export interface OrderHistoryOrder {
    Status: string;
    OrderNo: string;
    OrderDate: string;
    OrderHeaderKey: string;
    OriginalTotalAmount: string;
    OrderLines: OrderLines;
}

export interface OmsOrderHistoryResponse {
    totalOrders: string;
    totalNumberOfPages: string;
    pageSize: string;
    pageNumber: string;
    isLastPage: string;
    orders: OrderHistoryOrder[];
}

export interface OmsOrderDetailsResponse {
    orderNo: string;
    orderDate: string;
    orderStatus: string;
    orderLine: OrderLineDetails[];
    customerEmailId: string;
    personInfoBillTo: PersonInfoBillTo;
    personInfoShipTo: PersonInfoShipTo;
    overallTotals: OverallTotals;
    totalNumberOfRecords: string;
    orderHeaderKey: string;
}

export interface OverallTotals {
    grandCharges: string;
    grandTax: string;
    grandTotal: string;
    hdrCharges: string;
    lineSubTotal: string;
    grandDiscount: string;
}

export interface PriceInfo {
    Currency: string;
    TotalAmount: string;
}

export interface PersonInfoBillTo {
    firstName: string;
    lastName: string;
    addressLine1: string;
    addressLine2: string;
    city: string;
    state: string;
    zipCode: string;
    country: string;
    mobilePhone: string;
    otherPhone: string;
}

export interface PersonInfoShipTo {
    firstName: string;
    lastName: string;
    addressLine1: string;
    addressLine2: string;
    city: string;
    state: string;
    zipCode: string;
    country: string;
    mobilePhone: string;
    otherPhone: string;
}

export interface OrderLine {
    Status: string;
    Item: Items;
}

export interface OrderLineDetails {
    status: string;
    item: Item;
    lineOverallTotals: LineOverallTotals;
    originalOrderedQty: string;
    isStorefrontReturnable: string;
    orderLineKey: string;
    deliveryMethod: string;
}

export interface Extn {
    ExtnOrderProcessedDate: string;
}

export interface LineOverallTotals {
    charges: string;
    discount: string;
    extendedPrice: string;
    lineTotal: string;
    lineTotalWithoutTax: string;
    tax: string;
    unitPrice: string;
}

export interface PrimaryInformation {
    ReturnWindow: string;
}

const proxyAgent = new HttpsProxyAgent({
    host: 'internet.ford.com',
    port: 83,
});

export default class OmsService {
    private httpService: any;
    private url: string = DSL_BASE_URL + DSL_API_PATH.OMS_ORDER_HISTORY;

    constructor(httpService: HttpService) {
        this.httpService = httpService;
    }

    public async getOrderHistory(
        customerEmailId: string,
        pageNumber: string,
        pageSize: string
    ): Promise<OmsOrderHistoryResponse> {
        let response: any;
        try {
            response = await this.httpService.get(this.url, false, {
                headers: {
                    customerEMailID: customerEmailId,
                },
                adapter: httpAdapter,
                proxy: false,
                httpAgent: proxyAgent,

                params: {
                    enterpriseCode: 'FordUS',
                    entryType: 'ACCESSORIES',
                    pageNumber: pageNumber,
                    pageSize: pageSize,
                },
            });
        } catch (error) {
            return null;
        }
        return response?.data;
    }

    public async getOrderDetails(
        customerEmailId: string,
        orderNumber: string
    ): Promise<OmsOrderDetailsResponse> {
        const url = DSL_BASE_URL + DSL_API_PATH.OMS_ORDER_DETAILS;

        let response: any;
        try {
            response = await this.httpService.get(url, false, {
                headers: {
                    customerEMailID: customerEmailId,
                    orderNumber: orderNumber,
                },
                adapter: httpAdapter,
                proxy: false,
                httpAgent: proxyAgent,

                params: {
                    enterpriseCode: 'FordUS',
                },
            });
        } catch (error) {
            return null;
        }
        return response?.data;
    }
}
