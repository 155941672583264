import { DSL_API_PATH, DSL_BASE_URL } from '@constants';
import HttpService from '@services/http-service/http-service';
import { VehicleAttributes } from '@models/vehicle-attributes';
import AppConfigurationService from '@services/app-configuration-service/app-configuration-service';
import { OrderedVehiclesItem } from '@models/orders-and-reservations';
import { Buffer } from 'buffer';

export default class VehicleImageService {
    public async getVehicleImage(
        vehicleAttributes: VehicleAttributes | OrderedVehiclesItem,
        page: string,
        countryCode: string
    ): Promise<string | null> {
        if (vehicleAttributes == undefined) {
            return null;
        }

        if (!vehicleAttributes.vin || Number(vehicleAttributes.year) < 2013)
            return null;
        const appConfig = new AppConfigurationService();
        const dslUrl = appConfig.getAppConfiguration().dslUrl
            ? appConfig.getAppConfiguration().dslUrl
            : DSL_BASE_URL;
        const imageId = Buffer.from(vehicleAttributes?.vin).toString('base64');
        const serviceUrlDefault = `${
            dslUrl + DSL_API_PATH.VEHICLE_IMAGE_SERVICE
        }?imageId=${imageId}&year=${
            vehicleAttributes.year
        }&countryCode=${countryCode}`;
        const angleOne: string = serviceUrlDefault + '&angle=1';
        const angleTwo: string = serviceUrlDefault + '&angle=2';
        const angleThree: string = serviceUrlDefault + '&angle=3';
        const angleFour: string = serviceUrlDefault + '&angle=4';
        const angleFive: string = serviceUrlDefault + '&angle=5';

        return (await this.imageAvailable(angleFour))
            ? angleFour
            : (await this.imageAvailable(angleFive))
            ? angleFive
            : (await this.imageAvailable(angleThree))
            ? angleThree
            : (await this.imageAvailable(angleTwo))
            ? angleTwo
            : (await this.imageAvailable(angleOne))
            ? angleOne
            : null;
    }

    private async imageAvailable(serviceUrl: string): Promise<boolean> {
        try {
            const response = HttpService.get(serviceUrl, true);
            await response;
        } catch (error) {
            return Promise.resolve(false);
        }
        return Promise.resolve(true);
    }
}
