import React, {
    createContext,
    useCallback,
    useContext,
    useEffect,
    useState,
} from 'react';

export enum NotificationType {
    Success = 'success',
    Error = 'error',
    Info = 'info',
    SpecialAlert = 'default',
    None = '',
}

export type NotificationContextType = {
    notificationType: NotificationType;
    notificationFlag: boolean;
    setNotificationContext?: (
        type: NotificationType,
        fromDashboard?: boolean
    ) => void;
    isFromDashboard: boolean;
    setNotificationFlag?: (flag: boolean) => void;
};

const NotificationContext = createContext({
    notificationType: NotificationType.None,
    notificationFlag: false,
    isFromDashboard: false,
    setNotificationContext: (type, fromDashboard) =>
        console.warn('using default values in context', type, fromDashboard),
    setNotificationFlag: (flag) => console.warn('using default flag', flag),
});

const NotificationProvider = ({ children }) => {
    const [notificationType, setNotificationType] = useState(
        NotificationType.None
    );
    const [notificationFlag, setNotificationFlag] = useState(false);
    const [isFromDashboard, setIsFromDashboard] = useState<boolean>(false);
    const setNotificationContext = useCallback(
        (updates, fromDashboard) => {
            setNotificationType(updates);
            setNotificationFlag(!notificationFlag);
            setIsFromDashboard(fromDashboard);
        },
        [notificationType, setNotificationType, setNotificationFlag]
    );
    const getContext = useCallback(
        () => ({
            notificationType,
            setNotificationContext,
            notificationFlag,
            setNotificationFlag,
            isFromDashboard,
        }),
        [
            notificationType,
            setNotificationContext,
            notificationFlag,
            setNotificationFlag,
            isFromDashboard,
        ]
    );

    useEffect(() => {
        setNotificationContext(notificationType, isFromDashboard);
    }, [notificationFlag]);

    return (
        <NotificationContext.Provider value={getContext()}>
            {children}
        </NotificationContext.Provider>
    );
};

export const useNotificationContext = () => useContext(NotificationContext);

export default NotificationProvider;
