import React, { useEffect, useState } from 'react';
import { useExperienceContent } from '@hooks/use-server-content';
import { HeaderFragment } from '@models/experiencefragments/header';
import { Link } from '@common/index';
import { ImgLanguageToggle } from '../language-toggle/language-toggle';
import { Login } from './login/login';
import { HamburgerCollapse } from 'react-animated-burgers';
import SidebarMenu from './sidebar-menu/sidebar-menu';
import './header.scss';
import './lincoln-na-header/lincoln-na-header.scss';

interface Props {
    isAuthenticated: boolean;
}
export const Header = (props: Props) => {
    const [menuOpen, setMenuOpen] = useState(false);
    const [activeDesktopSubnav, setActiveDesktopSubnav] = useState('');
    const [experienceContent] = useExperienceContent<HeaderFragment>(
        'common',
        'header-footer',
        'header'
    );
    const toggleMenu = () => {
        const bodyElem = document.querySelector('body') as HTMLBodyElement;
        bodyElem.classList.toggle('open');
        setMenuOpen(!menuOpen);
    };

    const handleClick = () => {
        const body = document.querySelector('body') as HTMLBodyElement;

        if (activeDesktopSubnav || menuOpen) {
            const containerElem = document.querySelector(
                '.show-subnav'
            ) as HTMLBodyElement;
            containerElem?.classList?.toggle('visible-xs');

            body.classList.add('open');
        } else {
            body.classList.remove('open');
        }
    };

    useEffect(() => {
        handleClick();
    }, [activeDesktopSubnav, menuOpen]);

    return (
        <header className="header">
            {experienceContent && (
                <>
                    <div className="visible-in-mobile">
                        <HamburgerCollapse
                            className={`mobile menu ${
                                menuOpen ? 'menu-right-aligned' : ''
                            } `}
                            isActive={menuOpen}
                            toggleButton={toggleMenu}
                            barColor="#00095B"
                            buttonWidth={24}
                            data-testid="menu-toggle"
                            aria-expanded={menuOpen}
                        />
                        <SidebarMenu
                            isOpen={menuOpen}
                            className="mobile"
                            toggleMenu={toggleMenu}
                            experienceContent={experienceContent}
                        />
                    </div>
                    <a
                        tabIndex={0}
                        href={experienceContent?.logo.url}
                        target={
                            experienceContent?.logo.targetBlank
                                ? '_blank'
                                : '_self'
                        }
                        className="logo-link"
                        aria-label={experienceContent?.logo.ariaLabel}
                        rel="noopener noreferrer"
                    >
                        <span
                            style={{
                                backgroundImage: `url(${process.env.REACT_APP_AEM_BASE_URL}${experienceContent?.logo.logoPath})`,
                            }}
                            className={'ford-logo'}
                        />
                    </a>
                    {experienceContent['main-nav-left']?.map(
                        (navsub, index) => {
                            if (!navsub.categories && navsub.noFlyout) {
                                return (
                                    <nav aria-label={navsub.title} key={index}>
                                        <Link
                                            href={navsub.url}
                                            className="fmc-text-button"
                                            rel="noopener noreferrer"
                                            aria-label={navsub.ariaLabel}
                                        >
                                            {navsub.title}
                                        </Link>
                                    </nav>
                                );
                            } else if (navsub.categories && !navsub.noFlyout) {
                                return (
                                    <nav aria-label={navsub.title} key={index}>
                                        <button
                                            key={index}
                                            className={`fmc-text-button ${
                                                activeDesktopSubnav ===
                                                navsub.title
                                                    ? 'active'
                                                    : ''
                                            }`}
                                            onClick={() => {
                                                setActiveDesktopSubnav(
                                                    navsub.title
                                                );
                                                handleClick();
                                            }}
                                        >
                                            <span className="main-item">
                                                <Link
                                                    href={navsub.url}
                                                    aria-label={
                                                        navsub.ariaLabel
                                                    }
                                                >
                                                    {navsub.title}
                                                </Link>
                                            </span>
                                        </button>

                                        <div
                                            id={`subNav${index}`}
                                            className={`desktop-subnav ${
                                                activeDesktopSubnav ===
                                                navsub.title
                                                    ? 'show-subnav'
                                                    : 'hide-nav'
                                            }`}
                                        >
                                            <div
                                                className="background-overlay"
                                                onClick={() => {
                                                    setActiveDesktopSubnav('');
                                                    handleClick();
                                                }}
                                            />
                                            <ul className="main-nav-item sidebar-subnav">
                                                {navsub?.categories &&
                                                    navsub.categories.map(
                                                        (subnav, index) => {
                                                            return (
                                                                <li
                                                                    key={index}
                                                                    className="subnav-title-container"
                                                                >
                                                                    <span
                                                                        className={
                                                                            'fmc-type--heading5 fds-color__text--primary fmc-mb-4'
                                                                        }
                                                                    >
                                                                        {
                                                                            subnav.categoryTitle
                                                                        }
                                                                    </span>

                                                                    <ul>
                                                                        {subnav?.categoryItems &&
                                                                            subnav.categoryItems.map(
                                                                                (
                                                                                    sideNav,
                                                                                    inx
                                                                                ) => {
                                                                                    return (
                                                                                        <li
                                                                                            key={
                                                                                                inx
                                                                                            }
                                                                                            className="fmc-pb-2"
                                                                                        >
                                                                                            <Link
                                                                                                target={
                                                                                                    sideNav.targetBlank
                                                                                                        ? '_blank'
                                                                                                        : ''
                                                                                                }
                                                                                                href={
                                                                                                    sideNav.url
                                                                                                }
                                                                                                aria-label={
                                                                                                    sideNav.ariaLabel
                                                                                                }
                                                                                                className="fmc-type--body1 fds-color__text--primary"
                                                                                            >
                                                                                                {
                                                                                                    sideNav.title
                                                                                                }
                                                                                            </Link>
                                                                                        </li>
                                                                                    );
                                                                                }
                                                                            )}
                                                                    </ul>
                                                                </li>
                                                            );
                                                        }
                                                    )}
                                            </ul>
                                        </div>
                                    </nav>
                                );
                            }
                        }
                    )}
                    <span className="right-align desktop">
                        {experienceContent['main-nav-right'].utilityLinks &&
                            experienceContent[
                                'main-nav-right'
                            ]?.utilityLinks?.map((nav, index) => {
                                return (
                                    <Link
                                        key={index}
                                        className="locate-dealer"
                                        href={nav.url}
                                        aria-label={nav.title}
                                    >
                                        <img
                                            src={
                                                process.env
                                                    .REACT_APP_AEM_BASE_URL +
                                                nav.icon
                                            }
                                            alt={nav.ariaLabel}
                                        />
                                        <span>{nav.title}</span>
                                    </Link>
                                );
                            })}
                    </span>

                    <Login
                        isNavMenuOpen={!!activeDesktopSubnav || menuOpen}
                        content={experienceContent['main-nav-right']?.signIn}
                    />

                    {experienceContent['main-nav-right'].language
                        .languageItems !== null &&
                        experienceContent['main-nav-right'].language
                            .languageItems?.length > 1 && (
                            <span className="desktop language-toggle">
                                <ImgLanguageToggle
                                    tabIndex={0}
                                    languageText={
                                        experienceContent &&
                                        experienceContent['main-nav-right']
                                            ?.language?.languageItems
                                    }
                                    dataTestId="language-toggle"
                                />
                            </span>
                        )}
                    <div className="mobile-fill" />
                </>
            )}
        </header>
    );
};
