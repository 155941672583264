import ServerSideService from '../server-side-service/server-side-service';

interface SubscriptionData {
    environment?: string;
    renderElementSelector: string;
    ssoToken: string;
    refreshToken: string;
    applicationId: string;
    countryCode: string;
    language: string;
    vehicle?: {
        vin: string;
        make: string;
        modelYear: string;
        modelName: string;
        nickName: string;
        imageURL: string;
    };
    sspid?: string;
}

export default class ConnectedServicesService {
    private win: any;

    constructor() {
        if (ServerSideService.isClientSide()) {
            this.win = window;
        }
    }

    public connectedServices = (subscriptionData: SubscriptionData): void => {
        this.win?.renderSubscriptionsApp(subscriptionData);
    };
}
