import React, { Fragment, useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import '../communication-preferences-view/communication-preferences-view.scss';
import { ActivityIndicator } from '@common/activity-indicator/activity-indicator';
import { useCommunicationPreferencesContent } from '../communication-preferences-view/hooks/use-communication-preference-content';
import UserEmailChannelPreferenceService from '@services/email-channel-preference-service/email-channel-preference-service';
import ErrorPage from '@sections/profile-error/error-page';
import seoService from '@services/seo-service/seo-service';
import ServerContext from '@contexts/serverContext';
import { useAppInformationContent } from '@sections/breadcrumbs/hook/use-breadcrumb-content';
import AppConfigurationService from '@services/app-configuration-service/app-configuration-service';
import EmailCommunicationPreferencesSection from '@sections/email-communication-preference/email-communication-preferences';
import HttpService from '@services/http-service/http-service';
import {
    NotificationType,
    useNotificationContext,
} from '@contexts/notificationContext';
import { SearchBar } from '@/components/sections';
import { Notification } from '@sections/account-portal/components/notification-message/notification';
import { useNotificationBannerContent } from '@/components/sections/notification-banner/hooks/use-notification-banner-content';
import EmailCommunicationPreferencesFormSection from '@/components/sections/email-communication-preference/email-communication-preferences-form';
import { LinkButton } from '@/components/common/modal/link-button/link-button';

type ModalType = 'success' | 'confirmation' | '';

const className = 'communication-preference';

const EmailChannelPreferenceView = () => {
    const appConfig = new AppConfigurationService();
    const { setNotificationContext } = useNotificationContext();
    const countryCode = appConfig.get3LetterCountryCode().toUpperCase();
    const serverContext = useContext(ServerContext);
    const appInformationContent = useAppInformationContent();
    const location = useLocation();
    const queryStrings = new URLSearchParams(location.search);
    const cksid = queryStrings.get('cksid');
    const cks = queryStrings.get('cks');
    const consumerId = cksid !== null ? cksid : cks;
    const email = queryStrings.get('email');
    const [isEdit, setIsEdit] = useState(true);

    const communicationPreferenceLabels = useCommunicationPreferencesContent();
    const service = new UserEmailChannelPreferenceService(HttpService);
    const [userPreferences, setUserPreferences] = useState<any>();
    const [values, setValues] = useState([
        {
            name: 'email-channel-preference',
            value: 'email-channel-preference',
            isChecked: false,
            displayName: communicationPreferenceLabels?.emailOptionHeader,
        },
    ]);

    const [marketingEmailSubscribed, setMarketingEmailSubscribed] =
        useState<boolean>(false);

    const [notificationStatus, setNotificationStatus] =
        useState<NotificationType>(null);
    const notificationBannerContent = useNotificationBannerContent();
    const [bannerCopy, setBannerCopy] = useState<string>('');

    seoService.set(
        {
            pageTitle: appInformationContent?.communicationPreferencesPageTitle,
            applicationName: appInformationContent?.applicationName,
            pageDescription:
                appInformationContent?.communicationPreferencesPageDescription,
        },
        serverContext
    );

    const [modalState, setModalState] = useState<boolean>(false);

    const [modalType, setModalType] = useState<ModalType>('');

    const getEmailPreferences = (data) =>
        data?.marketingConsumer?.privacyPreference?.filter(
            (preference) => preference?.permPrefType === 'EmailPermission'
        );

    // handle user input change
    const handleChange = (updatedValues) => {
        setValues(updatedValues);
    };

    // handle communication preference save
    const handleSave = async () => {
        const userPreference = values[0].isChecked;
        const isSuccessModal = userPreference ? 'success' : 'confirmation';
        setModalType(isSuccessModal);
        if (userPreference) {
            const response = await service.updateEmailChannelPreference({
                consumerId,
                countryCode,
                email,
                emailPermission: userPreference,
            });
            if (response) {
                setModalState(true);
            }
        } else {
            setModalState(true);
        }
    };

    // handle modal close
    const handleClose = () => {
        setModalState(false);
    };

    const handleDisplayForm = (
        flag: boolean,
        bannerType: string,
        bannerText: string,
        marketingEmailSubscribed: boolean
    ) => {
        setIsEdit(flag);
        setNotificationStatus(
            bannerType === 'Success'
                ? NotificationType.Success
                : NotificationType.Error
        );
        setBannerCopy(
            bannerText === 'subscribe'
                ? notificationBannerContent.notificationSubscribeSuccessText
                : bannerText === 'unsubscribe'
                ? notificationBannerContent.notificationUnsubscribeSuccessText
                : bannerText === 'error'
                ? notificationBannerContent.notificationFailureText
                : ''
        );
        setMarketingEmailSubscribed(marketingEmailSubscribed);
    };

    // handle unsubscribe user confirmation
    const handleConfirmationModalConfirmation = async () => {
        handleClose();
        const response = await service.updateEmailChannelPreference({
            consumerId,
            countryCode,
            email,
            emailPermission: values[0].isChecked,
        });
        if (response) {
            setModalState(true);
            setModalType('success');
        }
    };

    // method will call on changes in emailOptionHeader and sendMarketingEmails from profile service
    useEffect(() => {
        const updateValues: any[] = [...values];
        updateValues[0].displayName =
            communicationPreferenceLabels?.emailOptionHeader;
        setValues(updateValues);
    }, [communicationPreferenceLabels?.emailOptionHeader]);

    useEffect(() => {
        setNotificationContext(NotificationType.None, false);
        async function getECP() {
            try {
                const response = (await service.getEmailChannelPreferences(
                    consumerId,
                    countryCode
                )) as any;
                if (response && Object.keys(response).length > 0) {
                    const updateCheckboxValue = [...values];
                    const currentPreferences = getEmailPreferences(response);
                    updateCheckboxValue[0].isChecked =
                        currentPreferences[0]?.indicator === 'Y';
                    setMarketingEmailSubscribed(
                        currentPreferences[0]?.indicator === 'N'
                    );
                    setValues(updateCheckboxValue);

                    setUserPreferences(response);
                }
            } catch (err) {
                setUserPreferences(err);
            }
        }
        getECP();
    }, []);

    return (
        <Fragment>
            {!userPreferences ? (
                <ActivityIndicator className={'full-height'} />
            ) : (
                <>
                    {userPreferences && userPreferences.marketingConsumer ? (
                        <div
                            className={`${className}${
                                communicationPreferenceLabels.editButton
                                    ? '-container-edit'
                                    : '-container'
                            } email-channel-preferences-container
                            `}
                            data-test-id="email-channel-preference-view"
                        >
                            {!communicationPreferenceLabels.editButton && (
                                <EmailCommunicationPreferencesSection
                                    modalState={modalState}
                                    communicationPreferenceContent={
                                        communicationPreferenceLabels
                                    }
                                    modalType={modalType}
                                    handleClose={handleClose}
                                    handleConfirmationModalConfirmation={
                                        handleConfirmationModalConfirmation
                                    }
                                    className={className}
                                    values={values}
                                    handleSave={handleSave}
                                    handleChange={handleChange}
                                />
                            )}
                            {communicationPreferenceLabels.editButton && (
                                <>
                                    <div className="communication-preferences-form-container">
                                        {notificationStatus && (
                                            <div
                                                className={`communication-preferences-view-notification-container`}
                                                data-testid={`communication-preferences-view-notification-container`}
                                            >
                                                <Notification
                                                    status={notificationStatus}
                                                    mainCopy={bannerCopy}
                                                    hideBorder={true}
                                                    hideAfterTimeout={true}
                                                    onHideNotification={() => {
                                                        setNotificationStatus(
                                                            null
                                                        );
                                                    }}
                                                />
                                            </div>
                                        )}
                                        <h1 className="fmc-type--heading6 fds-color__text--primary account-view-section__title-unauth fmc-mt-4-xs fmc-pt-1-xs">
                                            {
                                                communicationPreferenceLabels.pageHeader
                                            }
                                        </h1>
                                        <p className="account-view-section-content-unauth">
                                            {
                                                communicationPreferenceLabels.pageDescription
                                            }
                                        </p>
                                        <div className="account-view-section">
                                            <h2 className="fmc-type--heading6 fds-color__text--primary account-view-section__subtitle fmc-mt-4-xs fmc-pt-1-xs">
                                                {
                                                    communicationPreferenceLabels.preferencesSubHeader
                                                }
                                            </h2>

                                            <div className="edit-button-container">
                                                {!isEdit && (
                                                    <LinkButton
                                                        ariaControls={
                                                            'personal-information-form'
                                                        }
                                                        ariaExpanded={false}
                                                        label={
                                                            communicationPreferenceLabels.editButton
                                                        }
                                                        ariaLabel={
                                                            communicationPreferenceLabels.editButton
                                                        }
                                                        dataTestId="user-info-edit-btn"
                                                        class={`edit-link`}
                                                        onClick={() => {
                                                            setIsEdit(true);
                                                        }}
                                                        id="edit-link"
                                                        ariaHidden={false}
                                                        tabIndex={-1}
                                                    />
                                                )}
                                            </div>
                                        </div>
                                        {!isEdit ||
                                        appConfig.brand === 'ford' ? (
                                            <hr
                                                className="hr-line mB0"
                                                aria-hidden={true}
                                            />
                                        ) : null}
                                        {isEdit ? (
                                            <EmailCommunicationPreferencesFormSection
                                                displayForm={handleDisplayForm}
                                                defaultSelection={
                                                    marketingEmailSubscribed
                                                }
                                                isUnauthPage={true}
                                                consumerId={consumerId}
                                                countryCode={countryCode}
                                                email={email}
                                            />
                                        ) : (
                                            <div className="email-communication-container">
                                                <div className="email-communication-label">
                                                    {
                                                        communicationPreferenceLabels.emailOptionHeader
                                                    }
                                                </div>
                                                <div className="email-communication-status">
                                                    {marketingEmailSubscribed
                                                        ? communicationPreferenceLabels.yesSubscribe
                                                        : communicationPreferenceLabels.noUnSubscribe}
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                    <hr
                                        className="hr-line"
                                        aria-hidden={true}
                                    />
                                </>
                            )}
                            {communicationPreferenceLabels.editButton && (
                                <SearchBar />
                            )}
                        </div>
                    ) : (
                        <ErrorPage
                            title={
                                communicationPreferenceLabels?.apiErrorMessageForECPTitle
                            }
                            description={
                                communicationPreferenceLabels?.apiErrorMessageForECPDescription
                            }
                        />
                    )}
                </>
            )}
        </Fragment>
    );
};
export default EmailChannelPreferenceView;
