import {
    AccountDashboardView,
    CreateReturnView,
    OrderDetailsView,
    PageNotFoundView,
    WalletView,
} from '@/views';
import AccountSettingsView from '@views/account-settings-view/account-settings-view';
import EmailChannelPreferenceView from '@views/email-channel-preferences-view/email-channel-preferences-view';
import ChangeEmailView from '@views/change-email-view/change-email-view';
import { ConnectedServicesView } from '@views/connected-services-view/connected-services-view';
import MarketingOptionsView from '@views/marketing-options-view/marketing-options-view';
import CommunicationPreferencesView from '@views/communication-preferences-view/communication-preferences-view';
import EuPostRegistrationRedirect from '@sections/eu-post-registration-redirect/eu-post-registration-redirect';
import { GlobalAccountRoute } from './routesList';
import React from 'react';
import DeleteAccountView from '@views/delete-account-view/delete-account-view';
import SignInCredentialsView from '@views/sign-in-credentials-view/sign-in-credentials-view';
import AppConfigurationService from '@services/app-configuration-service/app-configuration-service';
import CountrySelectorView from '@views/country-selector-view/country-selector-view';
import PreferredDealerView from '@views/preferred-dealer-view/preferred-dealer-view';
import VehicleOrderTrackingView from '@views/vehicle-order-tracking-view/vehicle-order-tracking-view';
import VehicleOrderTrackingStatusView from '@views/vehicle-order-tracking-status-view/vehicle-order-tracking-status-view';
import OrderHistoryView from '@/views/order-history-view/order-history-view';
import GuestOrderView from '@views/guest-order-view/guest-order-view';
import ReturnConfirmationView from '@views/return-confirmation-view/return-confirmation-view';

const currentAppConfiguration =
    new AppConfigurationService().getAppConfiguration();
const appConfigRoot = currentAppConfiguration.root;

export const DEFAULT_ROUTES: Array<GlobalAccountRoute> = [
    {
        path: '/',
        exact: true,
        alias: 'GlobalAccountHomePage',
        component: <AccountDashboardView />,
    },
    {
        path: `${appConfigRoot}`,
        exact: true,
        alias: 'GlobalAccountHomePage',
        component: <AccountDashboardView />,
    },
    {
        path: '/account-dashboard',
        exact: true,
        alias: 'AccountDashboardView',
        component: <AccountDashboardView />,
    },
    {
        path: '/account-settings',
        exact: true,
        alias: 'AccountSettingsView',
        component: <AccountSettingsView />,
    },
    {
        path: '/email-preference',
        exact: true,
        alias: 'EmailChannelPreferenceView',
        component: <EmailChannelPreferenceView />,
    },
    {
        path: '/change-email',
        exact: true,
        alias: 'ChangeEmailView',
        component: <ChangeEmailView />,
    },
    {
        path: '/delete-account',
        exact: true,
        alias: 'DeleteAccountView',
        component: <DeleteAccountView />,
    },
    {
        path: '/wallet',
        exact: true,
        alias: 'WalletView',
        component: <WalletView />,
    },
    {
        path: '/connected-services',
        exact: true,
        alias: 'ConnectedServicesView',
        component: <ConnectedServicesView />,
    },
    {
        path: '/marketing-options',
        exact: true,
        alias: 'MarketingOptionsView',
        component: <MarketingOptionsView />,
    },
    {
        path: '/communication-preference',
        exact: true,
        alias: 'CommunicationPreferenceView',
        component: <CommunicationPreferencesView />,
    },
    {
        path: '/eu-post-registration',
        exact: true,
        alias: 'EuPostRegistrationRedirect',
        component: <EuPostRegistrationRedirect />,
    },
    {
        path: '/signin-credentials',
        exact: true,
        alias: 'SignInCredentialsView',
        component: <SignInCredentialsView />,
    },
    {
        path: '/country-selector',
        exact: true,
        alias: 'CountrySelectorView',
        component: <CountrySelectorView />,
    },
    {
        path: '/preferred-dealer',
        exact: true,
        alias: 'PreferredDealerView',
        component: <PreferredDealerView />,
    },
    {
        path: '/order-details/:orderNo',
        exact: true,
        alias: 'OrderDetailsView',
        component: <OrderDetailsView />,
    },
    {
        path: '/order-details',
        exact: false,
        alias: 'OrderDetailsViewWithoutOrderNumber',
        component: <OrderDetailsView />,
    },
    {
        path: '/order-history',
        exact: true,
        alias: 'OrderHistoryView',
        component: <OrderHistoryView />,
    },
    {
        path: '/create-return',
        exact: true,
        alias: 'CreateReturnView',
        component: <CreateReturnView />,
    },
    {
        path: '/complete-return',
        exact: true,
        alias: 'ReturnConfirmationView',
        component: <ReturnConfirmationView />,
    },
    {
        path: '/vehicle-order-tracking',
        exact: true,
        alias: 'VehicleOrderTrackingView',
        component: <VehicleOrderTrackingView />,
    },
    {
        path: '/vehicle-order-tracking/status',
        exact: true,
        alias: 'VehicleOrderTrackingStatusView',
        component: <VehicleOrderTrackingStatusView />,
    },
    {
        path: '/guest-order',
        exact: true,
        alias: 'GuestOrderView',
        component: <GuestOrderView />,
    },
    {
        path: '*',
        exact: true,
        alias: 'PageNotFoundView',
        component: <PageNotFoundView />,
    },
];
