import React, { useEffect, useRef } from 'react';
import { useCookies } from 'react-cookie';
import {
    COOKIE_CONFIGURATION,
    DEFAULT_GDPR_COOKIE_VALUE,
    GDPR_COOKIE,
} from '@constants';
import GdprCookieConfigurationService from './services/gdpr-cookie-configuration-service';
import PrimaryButton from '@common/primary-button/primary-button';
import { useGdprBannerContent } from '@sections/gdpr-banner/hooks/use-gdpr-banner-content';
import './gdpr-banner.scss';
import ServiceHandler from '@/services/service-handler';
import AppConfigurationService from '@/services/app-configuration-service/app-configuration-service';

export const GdprBanner = () => {
    const { currentDomain } = ServiceHandler.AppConfigurationService;
    const bannerRef = useRef(null);
    const gdprBannerContent = useGdprBannerContent();
    const appConfig = new AppConfigurationService();
    const isEu: boolean = appConfig.getAppConfiguration().fmaRegion === 'eu';

    const [, setCookie] = useCookies([
        'guxfoe-cookiedisclaimer',
        'cookie-configuration',
    ]);
    const getCookieValue = (): string => {
        const gdprValue = localStorage.getItem('cookie-configuration');
        return JSON.stringify(gdprValue);
    };
    const cookieDomain = currentDomain.substring(
        currentDomain.indexOf('.'),
        currentDomain.length
    );
    const cookie_options = {
        path: '/',
        domain: cookieDomain,
    };
    const bannerDisplay = (show: boolean) => {
        const gdprBanner = document.querySelector(
            '.gdpr-visibility-on'
        ) as HTMLDivElement;
        if (gdprBanner)
            show
                ? gdprBanner.classList.remove('hide-me')
                : gdprBanner.classList.add('hide-me');
    };
    const handleCookie = (decision?: string): void => {
        const timestampNow = Date.now();
        const timestampExpire = new Date();
        timestampExpire.setDate(new Date().getDate() + 30);
        setCookie(GDPR_COOKIE, timestampNow, cookie_options);
        let cookieConfiguration: string;

        if (decision === 'accept') {
            cookieConfiguration = JSON.stringify({
                expire: timestampExpire.getTime(),
                timestamp: timestampNow,
                Domain: currentDomain,
                value: {
                    functionality: true,
                    performance: true,
                    'strictly necessary': true,
                    targeting: true,
                },
            });
        } else {
            cookieConfiguration = JSON.stringify({
                expire: timestampExpire.getTime(),
                timestamp: timestampNow,
                Domain: currentDomain,
                value: {
                    functionality: false,
                    performance: false,
                    'strictly necessary': true,
                    targeting: false,
                },
            });
        }

        localStorage.setItem(COOKIE_CONFIGURATION, cookieConfiguration);
        setCookie(COOKIE_CONFIGURATION, cookieConfiguration, cookie_options);
        GdprCookieConfigurationService.setAnalyticsCookieConfiguration();
        bannerDisplay(false);
        document.body.classList.remove('gdpr-open');
    };
    const keyDownHandler = (e) => {
        const focusableElements =
            bannerRef.current.querySelectorAll('button, [href]');
        const firstFocusableElement = focusableElements[0]; // get first element to be focused inside modal
        const lastFocusableElement =
            focusableElements[focusableElements.length - 1]; // get last element to be focused inside modal

        const isTabPressed = (e) => e.key === 'Tab' || e.keyCode === 9;

        if (!isTabPressed || e.key === ' ') {
            return;
        }

        if (isTabPressed) {
            if (
                !e.shiftKey &&
                document.activeElement === lastFocusableElement
            ) {
                e.preventDefault();
                firstFocusableElement.focus();
            }
            if (
                e.shiftKey &&
                document.activeElement === firstFocusableElement
            ) {
                e.preventDefault();
                lastFocusableElement.focus(); // add focus for the last focusable element
            }
        }
    };

    const bannerOpenIfNoCookie = () => {
        if (
            !localStorage.getItem('cookie-configuration') ||
            getCookieValue() === DEFAULT_GDPR_COOKIE_VALUE
        ) {
            bannerDisplay(true);
            document.body.classList.add('gdpr-open');
        } else {
            document.body.classList.remove('gdpr-open');
        }
    };

    useEffect(() => {
        if (isEu) {
            setTimeout(() => {
                bannerOpenIfNoCookie();
            }, 2500);
        } else {
            bannerOpenIfNoCookie();
        }
    }, []);

    useEffect(() => {
        document.addEventListener('keydown', keyDownHandler, false);

        return () => {
            document.removeEventListener('keydown', keyDownHandler, false);
        };
    }, []);

    return (
        <div>
            {gdprBannerContent && (
                <div
                    className="gdpr-visibility-on hide-me"
                    data-testid="gdpr-banner"
                >
                    <div
                        className="overlay"
                        onClick={() => bannerDisplay(false)}
                    />

                    <div className="gdpr-container" ref={bannerRef}>
                        <div className="gdpr-brand-logo">
                            <img
                                className="logo"
                                src={
                                    process.env.REACT_APP_AEM_BASE_URL +
                                    gdprBannerContent.fordLogoImagePath
                                }
                                alt=""
                            />
                        </div>
                        <div className="gdpr-banner-content">
                            <div className="gdpr-banner-text">
                                <div
                                    className="gdpr-banner__paragraph--one"
                                    dangerouslySetInnerHTML={{
                                        __html: gdprBannerContent.paragraphOne,
                                    }}
                                />
                            </div>
                            <div className="gdpr-buttons__container">
                                <PrimaryButton
                                    testId="accept-cookies-button"
                                    className="fmc-button--no-shadow"
                                    ariaLabel={
                                        gdprBannerContent.acceptCookiesButtonAriaLabel
                                    }
                                    onClick={() => handleCookie('accept')}
                                >
                                    {gdprBannerContent.acceptCookiesButtonLabel}
                                </PrimaryButton>
                                <PrimaryButton
                                    testId="decline-cookies-button"
                                    className="fmc-button--outlined"
                                    ariaLabel={
                                        gdprBannerContent.declineCookiesButtonAriaLabel
                                    }
                                    onClick={() => handleCookie()}
                                >
                                    {
                                        gdprBannerContent.declineCookiesButtonLabel
                                    }
                                </PrimaryButton>
                            </div>
                            <div className="gdpr-banner-text">
                                <div
                                    className="gdpr-banner__paragraph--two"
                                    dangerouslySetInnerHTML={{
                                        __html: gdprBannerContent.paragraphTwo,
                                    }}
                                ></div>
                            </div>
                            <div className="gdpr-banner-text">
                                <div
                                    className="gdpr-banner__paragraph--three"
                                    dangerouslySetInnerHTML={{
                                        __html: gdprBannerContent.paragraphThree,
                                    }}
                                ></div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};
