import HttpService from '@services/http-service/http-service';
import { BRAND, DSL_API_PATH, DSL_BASE_URL } from '@constants';
import AppConfigurationService from '@services/app-configuration-service/app-configuration-service';

export interface NvoGuideResponse {
    guideUrl: string;
}

export default class NvoService {
    private httpService: any;
    private url: string = DSL_BASE_URL + DSL_API_PATH.NVO_GUIDE;
    private appConfig = new AppConfigurationService();

    constructor(httpService: HttpService) {
        this.httpService = httpService;
    }

    public async getNVOGuide(vin: string): Promise<NvoGuideResponse> {
        let response: any;
        const languageRegionCode = this.appConfig
            .getLanguageRegionCode()
            .replace('-', '_');
        try {
            response = await this.httpService.get(this.url, false, {
                headers: {
                    vin: vin,
                },
                params: {
                    lang: languageRegionCode,
                    brandCode: BRAND[this.appConfig.getBrand()].SHORT_NAME,
                },
            });
        } catch (error) {
            return null;
        }

        return response?.data;
    }
}
