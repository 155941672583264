import React, { useEffect, useState } from 'react';

import ScrollUtil from '@utils/scroll-to-top-util/scroll-to-top-util';
import { ActivityIndicator } from '@/components/common/activity-indicator/activity-indicator';
import { SearchBar } from '@/components/sections';
import ErrorPage from '@/components/sections/profile-error/error-page';
import './order-details-view.scss';
import AuthenticationService from '@/services/authentication-service/authentication-service';
import { CacheService } from '@/services/cache-service/cache-service';
import ProfileService from '@/services/profile-service/profile-service';
import { Breadcrumbs } from '@/components/sections/breadcrumbs/breadcrumbs';
import AppConfigurationService from '@/services/app-configuration-service/app-configuration-service';
import OrderDetailsComponent from './components/order-details-component';
import { ProfileWithVehiclesResponse } from '@/models/profile-with-vehicles';
import OmsService, {
    OmsOrderDetailsResponse,
} from '@/services/oms-service/oms-service';
import HttpService from '@/services/http-service/http-service';
import { useOrderDetailsContent } from './hooks/use-order-details-content';
import { useOrderHistoryContent } from '../order-history-view/hooks/use-order-history-content';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { findPathByAlias } from '@routes/routesList';
import { useOrderStatusContent } from '@views/order-details-view/hooks/use-nvc-order-status-content';

const className = 'order-details';

const OrderDetailsView = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const [showErrorPage, setShowErrorPage] = useState(false);
    const authenticationService = new AuthenticationService();
    const cacheService = new CacheService();
    const profileService = new ProfileService();
    const omsService = new OmsService(HttpService);
    const orderDetailsContent = useOrderDetailsContent();
    const orderHistoryContent = useOrderHistoryContent();
    const orderStatusContent = useOrderStatusContent();
    const appConfig = new AppConfigurationService();
    const [ordersFinishedLoading, setOrdersFinishedLoading] = useState(false);
    const [profileData, setProfileData] =
        useState<ProfileWithVehiclesResponse>(null);
    const [orderDetailsData, setOrderDetailsData] =
        useState<OmsOrderDetailsResponse>(null);
    const emailFromRouterState = location.state?.emailAddress;
    const { orderNo } = useParams<{ orderNo: string }>();

    const generateOrderNumberAndPutInStorage = (): void => {
        const pathname = location.pathname;
        const splitPathname = pathname.split('/');

        if (splitPathname[splitPathname.length - 1].startsWith('FAE:')) {
            sessionStorage.setItem(
                'nvcOrderDetailsOrderNumber',
                splitPathname[splitPathname.length - 1]
            );
        }
    };

    useEffect(() => {
        new ScrollUtil().scrollPageToTop();
    }, []);

    const checkAuthenticationStatus = () => {
        cacheService.evictProfileServiceCache();
        authenticationService
            .onIsAuthenticated()
            .then((isAuthenticated: boolean) => {
                if (isAuthenticated) {
                    profileService
                        .requestLite()
                        .then((profile) => {
                            if (profile) {
                                setProfileData(profile);
                            }
                        })
                        .catch(() => {
                            setShowErrorPage(true);
                            cacheService.evictProfileServiceCache();
                        });
                } else {
                    const emailAddress = location.state?.emailAddress;

                    generateOrderNumberAndPutInStorage();

                    !emailAddress &&
                        navigate(findPathByAlias('GuestOrderView'));
                }
            });
    };

    useEffect(() => {
        const orderData = async () => {
            if (profileData?.profile?.email || emailFromRouterState) {
                omsService
                    .getOrderDetails(
                        profileData?.profile?.email || emailFromRouterState,
                        orderNo
                    )
                    .then((orderDetails) => {
                        setOrderDetailsData(orderDetails);
                    })
                    .catch((error) => {
                        setShowErrorPage(true);
                    })
                    .finally(() => {
                        setOrdersFinishedLoading(true);
                    });
            }
        };
        orderData();
    }, [profileData, showErrorPage, emailFromRouterState]);

    useEffect(() => {
        checkAuthenticationStatus();
    }, []);

    const renderContent = () => {
        if (showErrorPage) {
            return <ErrorPage />;
        } else if (
            orderDetailsContent &&
            orderStatusContent &&
            orderDetailsData &&
            (profileData || emailFromRouterState)
        ) {
            return (
                <div
                    className={`${className}-container`}
                    data-test-id={`${className}-view`}
                >
                    {profileData && (
                        <Breadcrumbs
                            currentPage={
                                orderDetailsContent.orderDetailsbreadcrumbTitle
                            }
                            previousPage={orderHistoryContent.breadcrumbTitle}
                            previousLink={findPathByAlias('OrderHistoryView')}
                            crumbSeparator={
                                appConfig.brand === 'ford' ? '/' : '>'
                            }
                        ></Breadcrumbs>
                    )}
                    <OrderDetailsComponent
                        orderDetailsContent={orderDetailsContent}
                        orderDetailsData={orderDetailsData}
                        orderStatusContent={orderStatusContent}
                        ordersFinishedLoading={ordersFinishedLoading}
                        authenticated={profileData}
                    />
                    <hr className="hr-line" aria-hidden={true} />
                    <SearchBar />
                </div>
            );
        } else {
            return <ActivityIndicator className={'full-height'} />;
        }
    };

    return <>{renderContent()}</>;
};

export default OrderDetailsView;
