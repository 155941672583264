import { DSL_API_PATH, DSL_BASE_URL } from '@constants';
import HttpService from '@services/http-service/http-service';

interface EyfGeneralFeature {
    key: string;
}

export interface EyfChecklistResponse {
    brand: string;
    checkedFeatures: EyfGeneralFeature[];
    country: string;
    customerNotes: string;
    language: string;
    message: string;
    uncheckedFeatures: EyfGeneralFeature[];
    userGuid: string;
    userName: string;
    vehicleLine: string;
    vin: string;
    year: number;
}

export interface EyfChecklistErrorResponse {
    code: string;
    message: string;
    status: string;
    statusCode: string;
    statusContext: string;
}

export default class EyfChecklistService {
    private httpService: any;

    constructor(httpService: HttpService) {
        this.httpService = httpService;
    }

    public async getFeaturesChecklist(
        vin: string
    ): Promise<EyfChecklistResponse | EyfChecklistErrorResponse> {
        const config = {
            headers: {
                ...this.httpService.getConsumerKeyAndAuthTokenRequestHeaders(),
                vin,
            },
        };
        const url: string = DSL_BASE_URL + DSL_API_PATH.EYF_CHECKLIST;

        try {
            const response = await this.httpService.get(url, false, config);

            if (response.status === 200) {
                return response.data as EyfChecklistResponse;
            }
        } catch (e) {
            return e.response as EyfChecklistErrorResponse;
        }
    }
}
