export interface GuestOrderViewFieldError {
    status: boolean;
    message: string;
}
export interface GuestOrderViewErrors {
    [key: string]: GuestOrderViewFieldError;
}

export const guestOrderViewErrors: GuestOrderViewErrors = {
    orderNumber: {
        status: false,
        message: '',
    },
    emailAddress: {
        status: false,
        message: '',
    },
};
