import React, { useEffect, useState } from 'react';
import { useAnalytics } from '@hooks/use-analytics';
import { CacheService } from '@services/cache-service/cache-service';
import ScrollUtil from '@utils/scroll-to-top-util/scroll-to-top-util';
import {
    EU_COUNTRIES_NAME,
    EU_COUNTRIES_WITH_THREE_DIGIT_COUNTRY_CODES,
    formatPhoneNumberByCountry,
    getFieldsConfig,
} from '@utils/values-by-country/value-by-country';
import { ExperienceFragmentModel } from '@services/content-service/content-service.interface';

import {
    errors,
    inputValidations as validationData,
} from './personal-information-fields-info';
import {
    NotificationType,
    useNotificationContext,
} from '@contexts/notificationContext';
import Name from './name-section/name-section';
import { useTitleContent } from './hooks/use-title-content';
import { useTerritoryContent } from './hooks/use-country-content';
import Validator from '@utils/validator/validator';
import UserAccountService from '@services/user-account-service/user-account-service';
import { IMG_COUNTRIES } from '@constants';
import { ActivityIndicator } from '@common/activity-indicator/activity-indicator';
import { PrimaryButton, SecondaryButton } from '@common/index';
import { LinkButton } from '@common/modal/link-button/link-button';
import { AccountEditProps, Errors, ProfileError } from './personal-information';
import Address from './address/address';
import EmailSection from './email-section/email-section';
import NationalID from './national-id/national-id';
import ProfileService from '@services/profile-service/profile-service';
import PhoneNumber from './phone-number/phone-number';
import addIcon from '@assets/add-address-icon2.svg';
import PhoneNumberEu from './phone-number/phone-number-eu';
import { DynamicDropdownOptions } from '@common/dynamic-dropdown/dynamic-dropdown';

export interface DropDownFieldContent extends ExperienceFragmentModel {
    dropdownFieldLabel: string;
    options: DropDownOptions[];
}

export interface DropDownOptions {
    name: string;
    value: string;
}

const PersonalInformationForm = (props: AccountEditProps) => {
    const [fireAnalytics] = useAnalytics(['ownerAccountPersonalInfo']);
    const {
        user,
        appConfig,
        accountSettingsLabels: sectionLabels,
        accountSettingsFormsLabels,
        additionalAddress,
        updateSuccess,
        countryCode,
        userIdentity,
        selectedMobileCountry,
        selectedPrimaryCountry,
        setSelectedMobileCountry,
        setSelectedPrimaryCountry,
        dynamicDropdownContent,
        setNotificationStatus,
    } = props;
    const cacheService = new CacheService();
    const scrollUtil = new ScrollUtil();
    const brand = appConfig.brand;
    const isFord: boolean = brand === 'ford';
    const isLincoln: boolean = brand === 'lincoln';
    const [instantMobileCountry, setInstantMobileCountry] =
        useState<DynamicDropdownOptions>(selectedMobileCountry);
    const [instantPrimaryCountry, setInstantPrimaryCountry] =
        useState<DynamicDropdownOptions>(selectedPrimaryCountry);

    const handleCancel = () => {
        props.handleCancel('view');
        props.setIsUpdateAndCancel(true);
        scrollUtil.scrollPageToTop();
    };

    const country = appConfig.get3LetterCountryCode();
    const isEu = EU_COUNTRIES_NAME.map((euCountry) => euCountry.value).includes(
        country
    );
    const experienceFragmentTitles = useTitleContent(countryCode);
    const experienceFragmentTerritory = useTerritoryContent(countryCode);
    const fieldsConfig: any = isEu
        ? getFieldsConfig('eur')
        : getFieldsConfig(country);

    const getAddress2FromProfileResponse = (additionalAddressList: any[]) => {
        if (Array.isArray(additionalAddressList)) {
            return additionalAddressList.find(
                (addressObject) =>
                    addressObject.id?.toLowerCase() === 'address2'
            );
        }
        return null;
    };
    const userAdditionalAddress =
        getAddress2FromProfileResponse(additionalAddress);

    const isNewAddress = Boolean(!userAdditionalAddress);

    const userDefaultData = {
        firstName: user.firstName,
        lastName: user.lastName,
        secondLastName: user.secondLastName,
        middleName: user.middleName,
        address1Line1: user.address1,
        address1Line2: user.address2,
        // interior and suburb field only for mexico
        address1InteriorNumber: user?.address3,
        address1SuburbField: user?.address4,
        address1City: user.city,
        address1State: user.state,
        address1Zip: user.zip,
        address2Line1: userAdditionalAddress?.addressLine1,
        address2Line2: userAdditionalAddress?.addressLine2,
        // interior and suburb field only for mexico
        address2InteriorNumber: userAdditionalAddress?.address3,
        address2SuburbField: userAdditionalAddress?.address4,
        address2City: userAdditionalAddress?.city,
        address2State: userAdditionalAddress?.stateOrProvince,
        address2Zip: userAdditionalAddress?.postalCode,
        mobilePhoneNumber: formatPhoneNumberByCountry(
            countryCode,
            user.mobilePhoneNumber,
            instantMobileCountry?.phoneNumberCountryCode
        ),
        confirmMobilePhoneNumber: formatPhoneNumberByCountry(
            countryCode,
            user.phoneNumber
        ),
        nationalIdType: user.userIdentity?.identificationSubType,
        nationalIdNumber: user.userIdentity?.identificationValue,
        userIdentity: {
            identificationCountry: user.country,
            identificationSubType: user.userIdentity?.identificationSubType,
            identificationType: user.userIdentity?.identificationType,
            identificationValue: user.userIdentity?.identificationValue,
        },
        phoneNumber: formatPhoneNumberByCountry(
            countryCode,
            user.phoneNumber,
            instantPrimaryCountry?.phoneNumberCountryCode
        ),
        email: user.email,
        confirmEmail: user.email,
        companyName: user.companyName,
        title: user.title,
        country: user.country || appConfig.get3LetterCountryCode(),
    };
    const generateUserDefaultData = () => {
        Object.keys(userDefaultData).forEach((key) => {
            if (!userDefaultData[key]) {
                userDefaultData[key] = '';
            }
        });
        return userDefaultData;
    };

    const [userData, handleUserData] = useState(generateUserDefaultData());
    const [error, handleErrors] = useState<Errors>(errors);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const { setNotificationContext } = useNotificationContext();
    const currentCountry = appConfig.get3LetterCountryCode();
    const [renderAddressTwo, handleAddressTwo] = useState<boolean>(false);
    // content fragments  - get field labels
    const displayAdditionalAddressButton =
        fieldsConfig?.additionalAddress && !renderAddressTwo && isNewAddress;
    const displayAdditionalAddressFields =
        fieldsConfig?.additionalAddress && (renderAddressTwo || !isNewAddress);
    const showNationalId = fieldsConfig?.nationalId;

    const [addressHasValue] = useState<boolean>(
        !!userData.address1Line1 ||
            !!userData.address1City ||
            !!userData.address1State ||
            !!userData.address1Zip
    );

    const profileService = new ProfileService();
    const accountService = new UserAccountService();

    const inputValidations = validationData(
        accountSettingsFormsLabels,
        experienceFragmentTerritory,
        addressHasValue,
        instantPrimaryCountry,
        instantMobileCountry
    );

    // validation messages
    const validationMessages = {
        nonEmpty: accountSettingsFormsLabels?.validationEnter,
        pattern: accountSettingsFormsLabels?.validPhoneNumber,
        dontMatch: accountSettingsFormsLabels?.validationPhoneNumberMatch,
        charLimit: accountSettingsFormsLabels?.validCharacterLength,
    };
    const [focus, setFocus] = useState({
        title: false,
        firstname: false,
        middlename: false,
        lastname: false,
        address1Line1: false,
        address1Line2: false,
        nationalIdNumber: false,
        nationalIdType: false,
        city: false,
        email: false,
        confirmEmail: false,
        phone: false,
        mobile: false,
        confirmMobile: false,
        countryCode: false,
        companyName: false,
        zip: false,
        secondLastName: false,
        interiorField: false,
        suburbField: false,
    });
    // method to validate email and confirm email
    const validateEmail = (fieldName: string, fieldValue: string) => {
        const fieldErrors = { ...error };
        const emailNotMatch = {
            status: true,
            message: accountSettingsFormsLabels?.validationEmailsMatch,
        };
        const emailMatch = {
            status: false,
            message: '',
        };
        const requiredField = {
            status: true,
            message: `${validationMessages.nonEmpty} ${accountSettingsFormsLabels.confirmEmailField}`,
        };
        if (fieldName === 'confirmEmail') {
            if (!fieldValue) {
                fieldErrors[fieldName] = requiredField;
            } else if (
                fieldValue.toLowerCase() !== userData.email.toLowerCase()
            ) {
                fieldErrors[fieldName] = emailNotMatch;
            } else fieldErrors[fieldName] = emailMatch;
        }
        if (fieldName === 'email') {
            fieldValue.toLowerCase() === userData.confirmEmail.toLowerCase() &&
                (fieldErrors['confirmEmail'] = emailMatch);
            fieldValue.toLowerCase() !== userData.confirmEmail.toLowerCase() &&
                (fieldErrors['confirmEmail'] = emailNotMatch);
        }
        return fieldErrors;
    };
    // method to validate Mobile Phone Number and Confirm Phone Number
    const validatePhoneNumber = (fieldName: string, fieldValue: string) => {
        const fieldErrors = { ...error };
        const phoneNumberDoesNotMatch = {
            status: true,
            message: validationMessages.dontMatch,
        };
        const phoneNumberMatch = {
            status: false,
            message: '',
        };

        if (fieldName === 'confirmMobilePhoneNumber') {
            fieldErrors[fieldName] =
                fieldValue !== userData.mobilePhoneNumber
                    ? phoneNumberDoesNotMatch
                    : phoneNumberMatch;
        }
        if (
            fieldName === 'confirmMobilePhoneNumber' &&
            IMG_COUNTRIES.includes(countryCode)
        ) {
            fieldErrors[fieldName] =
                fieldValue !== userData.phoneNumber
                    ? phoneNumberDoesNotMatch
                    : phoneNumberMatch;
        }

        if (
            fieldName === 'phoneNumber' &&
            accountSettingsFormsLabels.confirmMobilePhoneField &&
            IMG_COUNTRIES.includes(countryCode)
        ) {
            fieldErrors['confirmMobilePhoneNumber'] =
                fieldValue !== userData.confirmMobilePhoneNumber
                    ? phoneNumberDoesNotMatch
                    : phoneNumberMatch;
        }

        if (fieldName === 'mobilePhoneNumber' && !userData.phoneNumber) {
            if (fieldValue === userData.confirmMobilePhoneNumber)
                fieldErrors['confirmMobilePhoneNumber'] = phoneNumberMatch;

            if (
                userData.confirmMobilePhoneNumber &&
                fieldValue !== userData.confirmMobilePhoneNumber
            )
                fieldErrors['confirmMobilePhoneNumber'] =
                    phoneNumberDoesNotMatch;
        }
        return fieldErrors;
    };

    const checkAddressTwoFields = () => {
        return (
            userData?.address2Line1 ||
            userData?.address2City ||
            userData?.address2State ||
            userData?.address2Zip
        );
    };

    // validate all input fields on click of update button
    const validateAllFields = () => {
        const fieldErrors = { ...error };
        const validate = { ...inputValidations };
        const isAddressHasValue =
            userData?.address1Zip ||
            userData?.address1Line1 ||
            userData?.address1City ||
            userData?.address1State;

        const isAddressTwoHasValue = checkAddressTwoFields();
        let addressFields = [
            'address1Line1',
            'address1Line2',
            'address1City',
            'address1State',
            'address1Zip',
        ];
        let addressTwoFields = [
            'address2Line1',
            'address2Line2',
            'address2City',
            'address2State',
            'address2Zip',
        ];

        if (isEu) {
            addressFields = addressFields.filter(
                (field) => field !== 'address1State'
            );
            addressTwoFields = addressTwoFields.filter(
                (field) => field !== 'address2State'
            );
        }

        /**
         * By default all address fields are optional
         * if user has already filled address then user shouldn't able to remove those address but they can change (i.e not empty)
         * user does not have address and user filled any one of the address fields (addressline1, city, state, zip) then all address fields are mandatory
         */
        if (props.user?.address1 || isAddressHasValue) {
            addressFields.forEach((field) => {
                if (field !== 'address1Line2') {
                    validate[field]?.validationRules.push('validateNonEmpty');
                }
            });
        } else {
            addressFields.forEach((field) => {
                if (field !== 'address1Line2') {
                    const ruleIndex =
                        validate[field]?.validationRules.indexOf(
                            'validateNonEmpty'
                        );
                    if (ruleIndex) {
                        validate[field]?.validationRules.splice(ruleIndex);
                    }
                }
            });
        }

        if (isAddressTwoHasValue || userAdditionalAddress) {
            addressTwoFields.forEach((field) => {
                if (field !== 'address2Line2') {
                    validate[field]?.validationRules.push('validateNonEmpty');
                }
            });
        }

        Object.entries(validate).forEach((field: any) => {
            const {
                validationRules: rules,
                validationPattern: pattern,
                validationCharLimit: charLimit,
                label,
            } = field[1];
            const [fieldName] = field;

            fieldErrors[fieldName] = Validator.checkValidations(
                rules,
                userData[fieldName],
                label,
                pattern,
                validationMessages,
                true,
                charLimit
            );
        });

        // for EU region and sweden state is not applicable so resetting validation state.
        if (isEu || country === 'swe' || !experienceFragmentTerritory) {
            fieldErrors['address1State'] = { status: false, message: '' };
            fieldErrors['address2State'] = { status: false, message: '' };
        }

        //if it is not IMG countries
        if (!IMG_COUNTRIES.includes(countryCode)) {
            fieldErrors['confirmMobilePhoneNumber'] = {
                status: false,
                message: '',
            };
        }

        if (
            IMG_COUNTRIES.includes(countryCode) &&
            userData.phoneNumber !== userData.confirmMobilePhoneNumber
        ) {
            fieldErrors['confirmMobilePhoneNumber'] = {
                status: true,
                message: validationMessages.dontMatch,
            };
        }
        if (!accountSettingsFormsLabels.lastNameField) {
            fieldErrors['lastName'] = { status: false, message: '' };
        }
        if (showNationalId) {
            fieldErrors['nationalIdNumber'] = { status: false, message: '' };
        }
        // for non mex countries
        if (country !== 'mex') {
            fieldErrors['address1SuburbField'] = {
                status: false,
                message: '',
            };
            fieldErrors['address2SuburbField'] = {
                status: false,
                message: '',
            };
            fieldErrors['address1InteriorNumber'] = {
                status: false,
                message: '',
            };
            fieldErrors['address2InteriorNumber'] = {
                status: false,
                message: '',
            };
        }

        if (!renderAddressTwo) {
            fieldErrors['address2SuburbField'] = {
                status: false,
                message: '',
            };
        }

        handleErrors(fieldErrors);
        return { ...fieldErrors };
    };

    const setFocusToField = (type: string, value: boolean) => {
        const focusedElements = { ...focus };
        focusedElements[type] = value;
        setFocus(focusedElements);
    };

    const handleScrollToErrorField = (errorFields: Errors) => {
        // Focus the error field
        errorFields.every((field: string) => {
            scrollUtil.scrollAndFocusElement(field);
            return false;
        });
    };

    const isFormValid = (fieldErrors: Errors) => {
        const validateForm = Object.keys(fieldErrors).filter((field) => {
            if (fieldErrors[field].status) return field;
        });
        const isValidForm = validateForm.length === 0;
        if (!isValidForm) {
            handleScrollToErrorField(validateForm);
        }
        return isValidForm;
    };

    const getFormattedPhoneNumber = (number) => {
        const firstFourCharactersPrimary = number?.slice(0, 4);
        const isNumberHasCountryCode = number.charAt(0) == '+';
        const isHavingThreeLetterCountryCode =
            EU_COUNTRIES_WITH_THREE_DIGIT_COUNTRY_CODES.includes(
                firstFourCharactersPrimary
            );
        const isEuAndHasNumber = number && isEu;
        if (
            number &&
            IMG_COUNTRIES.includes(countryCode) &&
            number.charAt(0) == '0'
        ) {
            return number.substring(1);
        } else if (isEuAndHasNumber && isHavingThreeLetterCountryCode) {
            return number[4] == '0'
                ? number.slice(0, 4) + number.slice(5)
                : number;
        } else if (isEuAndHasNumber && isNumberHasCountryCode) {
            return number[3] == '0'
                ? number.slice(0, 3) + number.slice(4)
                : number;
        } else {
            return number;
        }
    };

    const getPayload = () => {
        const userUpdatedDetails = { ...userData };
        const {
            address1Line1: address1,
            address1Line2: address2,
            address1InteriorNumber: address3,
            address1SuburbField: address4,
            address1City: city,
            address1State: state,
            address1Zip: zip,
            address2Line1,
            address2Line2,
            address2City,
            address2State,
            address2Zip,
            address2InteriorNumber,
            address2SuburbField,
            email,
            firstName,
            lastName,
            middleName,
            title,
            companyName,
            country,
            secondLastName,
        } = userUpdatedDetails;
        let { mobilePhoneNumber: alternatePhoneNumber, phoneNumber } =
            userUpdatedDetails;

        // remove leading zero from the phone number - leading zero is only for display purpose and we are not sending to the service #US3290046
        alternatePhoneNumber = getFormattedPhoneNumber(alternatePhoneNumber);
        phoneNumber = getFormattedPhoneNumber(phoneNumber);

        const payload: any = {
            address1: address1 ? address1.trim() : null,
            address2: address2 ? address2.trim() : null,
            address3: address3 ? address3.trim() : null,
            address4: address4 ? address4.trim() : null,
            city: city ? city.trim() : null,
            email,
            firstName,
            lastName,
            middleName,
            title: title || '',
            phoneNumber,
            mobilePhoneNumber: alternatePhoneNumber,
            state: state || null,
            zip: zip || null,
        };
        const additionalAddressPayload: any = {
            addressRequestList: [
                {
                    addressLine1: address2Line1 ? address2Line1.trim() : null,
                    addressLine2: address2Line2 ? address2Line2.trim() : null,
                    addressLine3: address2InteriorNumber
                        ? address2InteriorNumber.trim()
                        : null,
                    addressLine4: address2SuburbField
                        ? address2SuburbField.trim()
                        : null,
                    addressId: 'address2',
                    city: address2City ? address2City.trim() : null,
                    stateOrProvince: address2State,
                    postalCode: address2Zip || null,
                    newAddress: isNewAddress,
                    country: country ? country.toUpperCase() : null,
                },
            ],
            updateProfileRequest: {},
        };
        if (!fieldsConfig?.state) {
            delete payload.state;
        } else {
            // Get all territory values
            const territoryOptions = experienceFragmentTerritory?.options?.map(
                (option) => option.value
            );
            // if state value available and value not includes in the current state/territiory options then set first option as value
            // this is to avoid backend error - when the user has different countries state value and its not included in the current state/territiory options
            if (payload.state && !territoryOptions.includes(payload.state)) {
                payload.state = territoryOptions[0];
            }
            if (!payload.state) {
                payload.state = territoryOptions[0];
            }
        }

        if (currentCountry === 'mex') {
            payload.secondLastName = secondLastName;
        }

        if (!fieldsConfig?.country || currentCountry === 'swe') {
            payload.country = currentCountry.toUpperCase();
            additionalAddressPayload.addressRequestList[0].country =
                currentCountry.toUpperCase();
        } else {
            payload.country = currentCountry.toUpperCase();
        }

        if (fieldsConfig?.title && title) {
            payload.title = title;
        } else if (fieldsConfig?.title) {
            payload.title = experienceFragmentTitles?.options[0]?.titleValue;
        } else {
            delete payload.title;
        }

        if (fieldsConfig?.companyName) {
            payload.companyName = companyName;
        }
        return { payload, additionalAddressPayload };
    };

    const handleUpdate = async (mode: string, isAddressUpdated: boolean) => {
        setIsLoading(true);
        const { payload } = getPayload();
        props.setMode(mode);
        props.setUserData(payload);
        let profile, additionalAddressResponse;
        if (isAddressUpdated) {
            [profile, additionalAddressResponse] = await Promise.all([
                profileService.request().catch((err) => {
                    props.handleProfileServiceError(err);
                }),
                accountService.getAdditionalAddress().catch((err) => {
                    props.handleProfileServiceError(err);
                }),
            ]);
            props.handleAdditionalAddressResponse(additionalAddressResponse);
        } else {
            try {
                profile = await profileService.request();
            } catch (err) {
                props.handleProfileServiceError(err);
            }
        }
        setIsLoading(false);
        if (profile) {
            props.setUserData(payload);
            setIsLoading(false);
            props.setProfileError({
                status: false,
                message: '',
            } as ProfileError);
        } else {
            props.setUserData(null);
        }
    };

    const handleSubmit = async (): Promise<void> => {
        setNotificationStatus(null);
        fireAnalytics(['updatePersonalInfoOnclickEvent']);
        const accountService = new UserAccountService();
        const fieldErrors = validateAllFields();
        if (isFormValid(fieldErrors)) {
            const { payload, additionalAddressPayload } = getPayload();
            updateSuccess(
                payload,
                additionalAddressPayload.additionalAddressList
            );
            let response, additionalAddressResponse;
            setIsLoading(true);
            // if additional address not enabled and values not filled then address update api shouldn't call
            const isAddressTwoHasValues = checkAddressTwoFields();
            if (displayAdditionalAddressFields && isAddressTwoHasValues) {
                [response, additionalAddressResponse] = await Promise.all([
                    accountService.updateUserPersonalInfo(payload),
                    accountService.updateUserAdditionalAddress(
                        additionalAddressPayload
                    ),
                ]);
                const isProfileUpdated =
                    response?.status === '200' &&
                    response?.httpStatus === '200';
                const isAddressUpdated =
                    additionalAddressResponse?.updateAddressBookResponseList &&
                    additionalAddressResponse
                        ?.updateAddressBookResponseList[0] &&
                    additionalAddressResponse?.updateAddressBookResponseList[0]
                        ?.status === 'SUCCESS';
                // Navigate user to account settings page if calls succeed
                if (isProfileUpdated && isAddressUpdated) {
                    setIsLoading(false);
                    cacheService.evictProfileServiceCache();
                    if (isFord) {
                        setNotificationStatus(NotificationType.Success);
                    } else {
                        setNotificationContext(NotificationType.Success, false);
                    }
                    handleUpdate('view', displayAdditionalAddressFields);
                } else {
                    if (isFord) {
                        setNotificationStatus(NotificationType.Error);
                    } else {
                        setNotificationContext(NotificationType.Error, false);
                    }
                }
            } else {
                response = await accountService.updateUserPersonalInfo(payload);
                setIsLoading(false);
                const isProfileUpdated =
                    response?.status === '200' &&
                    response?.httpStatus === '200';
                isProfileUpdated &&
                    isEu &&
                    setSelectedMobileCountry(instantMobileCountry);
                isProfileUpdated &&
                    isEu &&
                    setSelectedPrimaryCountry(instantPrimaryCountry);
                if (isFord) {
                    isProfileUpdated
                        ? setNotificationStatus(NotificationType.Success)
                        : setNotificationStatus(NotificationType.Error);
                } else if (isLincoln) {
                    isProfileUpdated
                        ? setNotificationContext(
                              NotificationType.Success,
                              false
                          )
                        : setNotificationContext(NotificationType.Error, false);
                }
                // Navigate user to account settings page if calls succeed
                if (isProfileUpdated) {
                    cacheService.evictProfileServiceCache();
                    handleUpdate('view', false);
                }
            }
            if (response.status !== '200' || response?.error !== null) {
                setIsLoading(false);
                if (isFord) {
                    setNotificationStatus(NotificationType.Error);
                } else {
                    setNotificationContext(NotificationType.Error, false);
                }
            }
        }
        props.setIsUpdateAndCancel(true);
    };

    const transformDropdownOptions = (options) => {
        const dropdownOptions = [];
        if (options === null || options === undefined) {
            return [];
        }
        for (const option of options) {
            const eachOption = {
                value: option.value,
                displayName: option.name,
            };
            dropdownOptions.push(eachOption);
        }
        return dropdownOptions;
    };

    const handleChange = (e) => {
        const updateUserData = { ...userData };
        let fieldErrors: Errors;
        const { name: fieldName } = e.target;
        let { value: fieldValue } = e.target;
        const options = transformDropdownOptions(
            experienceFragmentTerritory?.options
        );
        if (fieldValue === options[0]?.displayName) {
            fieldValue = '';
        }

        updateUserData[fieldName] = fieldValue;

        handleUserData(updateUserData);
        // validate confirm email
        fieldErrors = validateEmail(fieldName, fieldValue);
        let displayFieldLabelWithErrorMessage = true;
        const isPhoneNumberField =
            fieldName === 'phoneNumber' ||
            fieldName === 'mobilePhoneNumber' ||
            fieldName === 'confirmMobilePhoneNumber';
        // validate Confirm Mobile Phone Number field
        if (isPhoneNumberField) {
            displayFieldLabelWithErrorMessage =
                !accountSettingsFormsLabels?.customValidPhoneNumberValidationMessage;
            validationMessages.pattern =
                accountSettingsFormsLabels?.customValidPhoneNumberValidationMessage ||
                accountSettingsFormsLabels?.validPhoneNumber;
            fieldErrors = validatePhoneNumber(fieldName, fieldValue);
        }
        // validate field based on rules defined
        const dataAttributes = { ...e.currentTarget.dataset };

        if (Object.keys(dataAttributes).length > 0) {
            const validationRules = dataAttributes.validationRules
                .split(',')
                .filter(Boolean);
            const fieldLabel = dataAttributes.label;
            const pattern = dataAttributes.pattern;
            const charLimit = dataAttributes.validationCharLimit;

            if (validationRules.length > 0) {
                fieldErrors[fieldName] = Validator.checkValidations(
                    validationRules,
                    fieldValue,
                    fieldLabel,
                    pattern,
                    validationMessages,
                    displayFieldLabelWithErrorMessage,
                    charLimit
                );
            } else {
                // excluding phone number and email since it has dependent with confirm field
                const isEmailField = ['email', 'confirmEmail'].includes(
                    fieldName
                );
                if (!isEmailField && !isPhoneNumberField) {
                    fieldErrors[fieldName] = {};
                }
            }

            handleErrors(fieldErrors);
        }
    };

    useEffect(() => {
        if (!fieldsConfig.country) {
            user.country = currentCountry?.toUpperCase();
        }
        if (!fieldsConfig.state) {
            delete user.state;
        }
    }, []);

    useEffect(() => {
        handleUserData((user) => {
            user.phoneNumber =
                formatPhoneNumberByCountry(
                    countryCode,
                    user.phoneNumber,
                    instantPrimaryCountry?.phoneNumberCountryCode
                ) || '';
            return user;
        });
    }, [instantPrimaryCountry, setInstantPrimaryCountry]);

    useEffect(() => {
        handleUserData((user) => {
            user.mobilePhoneNumber =
                formatPhoneNumberByCountry(
                    countryCode,
                    user.mobilePhoneNumber,
                    instantMobileCountry?.phoneNumberCountryCode
                ) || '';
            return user;
        });
    }, [instantMobileCountry, setInstantMobileCountry]);

    return (
        <div
            className={`user-account-edit-container fmc-mb-5-xs fmc-mb-0 ${
                isLincoln ? 'fmc-px-3-xs fmc-px-3-sm fmc-px-9-md fmc-px-11' : ''
            } fmc-pt-7 fmc-pb-9`}
            data-testid="personal-information-form"
            id="personal-information-form"
        >
            {isLoading && (
                <ActivityIndicator
                    className={'fds-activity-indicator__center'}
                />
            )}
            <form className={`${isLincoln ? 'fmc-px-1' : ''}`}>
                <Name
                    contentFragments={sectionLabels}
                    userData={userData}
                    focus={focus}
                    setFocusToField={setFocusToField}
                    personalInfoLabels={accountSettingsFormsLabels}
                    inputValidations={inputValidations}
                    error={error}
                    handleChange={handleChange}
                    countryCode={countryCode}
                />
                {/* Show National ID section if label is authored */}
                {sectionLabels.nationalIdName && (
                    <NationalID
                        sectionHeader={sectionLabels.nationalIdName}
                        personalInfoLabels={accountSettingsFormsLabels}
                        focus={focus}
                        setFocusToField={setFocusToField}
                        inputValidations={inputValidations}
                        error={error}
                        handleChange={handleChange}
                        countryCode={countryCode}
                        userIdentity={userIdentity}
                    />
                )}

                <fieldset className="input-fieldset">
                    <h3 className="fmc-type--heading6 fmc-mb-5">
                        {sectionLabels?.addressOne}
                    </h3>
                    <Address
                        appConfig={appConfig}
                        userData={userData}
                        user={props?.user}
                        focus={focus}
                        setFocusToField={setFocusToField}
                        personalInfoLabels={accountSettingsFormsLabels}
                        inputValidations={inputValidations}
                        error={error}
                        handleChange={handleChange}
                        addressNumber={1}
                        experienceFragmentTerritory={
                            experienceFragmentTerritory
                        }
                        fieldsConfig={fieldsConfig}
                        transformDropdownOptions={transformDropdownOptions}
                    />
                </fieldset>
                {displayAdditionalAddressButton ? (
                    <SecondaryButton
                        onClick={() => {
                            handleAddressTwo(true);
                            document.getElementById('address2Line1')?.focus();
                        }}
                        className="card-button-new"
                        buttonType={
                            isLincoln
                                ? 'lincoln-add-address'
                                : 'ford-add-address'
                        }
                    >
                        <span className="add-address-text fmc-text-button">
                            {accountSettingsFormsLabels?.btnAddAddress}
                            <img
                                alt=""
                                src={addIcon}
                                className="add-address-icon-img"
                            />
                        </span>
                    </SecondaryButton>
                ) : null}
                {displayAdditionalAddressFields ? (
                    <fieldset className="input-fieldset">
                        <h3 className="fmc-type--heading6 fmc-mb-5">
                            {sectionLabels?.addressTwo}
                        </h3>
                        <Address
                            appConfig={appConfig}
                            userData={userData}
                            user={props?.user}
                            focus={focus}
                            setFocusToField={setFocusToField}
                            personalInfoLabels={accountSettingsFormsLabels}
                            inputValidations={inputValidations}
                            error={error}
                            handleChange={handleChange}
                            addressNumber={2}
                            experienceFragmentTerritory={
                                experienceFragmentTerritory
                            }
                            fieldsConfig={fieldsConfig}
                            transformDropdownOptions={transformDropdownOptions}
                        />
                    </fieldset>
                ) : null}
                {accountSettingsFormsLabels?.emailField && (
                    <fieldset className="input-fieldset">
                        <h3 className="fmc-type--heading6 fmc-mb-5">
                            {sectionLabels?.email}
                        </h3>
                        <EmailSection
                            userData={userData}
                            focus={focus}
                            setFocusToField={setFocusToField}
                            personalInfoLabels={accountSettingsFormsLabels}
                            inputValidations={inputValidations}
                            error={error}
                            handleChange={handleChange}
                        />
                    </fieldset>
                )}
                {/* Phone Number Section */}
                {sectionLabels?.phoneNumber && (
                    <fieldset className="input-fieldset">
                        <h3 className="fmc-type--heading6 fmc-mb-5">
                            {sectionLabels?.phoneNumber}
                        </h3>
                        {isEu ? (
                            <PhoneNumberEu
                                userData={userData}
                                focus={focus}
                                setFocusToField={setFocusToField}
                                accountSettingsFormsLabels={
                                    accountSettingsFormsLabels
                                }
                                inputValidations={inputValidations}
                                error={error}
                                handleChange={handleChange}
                                countryCode={countryCode}
                                selectedPrimaryCountry={instantPrimaryCountry}
                                selectedMobileCountry={instantMobileCountry}
                                setSelectedMobileCountry={
                                    setInstantMobileCountry
                                }
                                setSelectedPrimaryCountry={
                                    setInstantPrimaryCountry
                                }
                                dynamicDropdownContent={dynamicDropdownContent}
                            />
                        ) : (
                            <PhoneNumber
                                userData={userData}
                                focus={focus}
                                setFocusToField={setFocusToField}
                                accountSettingsFormsLabels={
                                    accountSettingsFormsLabels
                                }
                                inputValidations={inputValidations}
                                error={error}
                                handleChange={handleChange}
                                countryCode={countryCode}
                            />
                        )}
                    </fieldset>
                )}
                {isLincoln && (
                    <div className="btn-container">
                        <PrimaryButton
                            onClick={(e) => {
                                e.preventDefault();
                                handleSubmit();
                            }}
                            testId="updatePersonalInfo"
                        >
                            {accountSettingsFormsLabels?.btnUpdate}
                        </PrimaryButton>
                        <span className="mrgn-lt40">&nbsp;</span>
                        <LinkButton
                            label={accountSettingsFormsLabels?.btnCancel}
                            onClick={handleCancel}
                            dataTestId="personal-information-form-cancel-button"
                        />
                    </div>
                )}
                {appConfig.brand === 'ford' && (
                    <div className="btn-container">
                        <LinkButton
                            label={accountSettingsFormsLabels?.btnCancel}
                            onClick={handleCancel}
                            dataTestId="personal-information-form-cancel-button"
                            class="user-edit-cancel-btn"
                        />
                        <PrimaryButton
                            onClick={(e) => {
                                e.preventDefault();
                                handleSubmit();
                            }}
                            testId="updatePersonalInfo"
                        >
                            {accountSettingsFormsLabels?.btnUpdate}
                        </PrimaryButton>
                    </div>
                )}
            </form>
        </div>
    );
};
export default PersonalInformationForm;
