import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
    accessoriesPartClassNameList,
    CVOT_AUTHENTICATED_ORDER_STORAGE_KEY,
    DAY_MONTH_YEAR_REGIONS,
    DIG_PROD_URL,
    DIG_QA_URL,
    exteriorPartClassNameList,
    interiorPartClassNameList,
    LinkTargetValues,
    modelPartClassNameList,
    packagesPartClassNameList,
} from '@constants';
import {
    useVehicleOrderTrackingContent,
    VehicleOrderTrackingContent,
} from '@views/vehicle-order-tracking-view/hooks/use-vehicle-order-tracking-content';
import { findPathByAlias } from '@routes/routesList';
import ScrollUtil from '@utils/scroll-to-top-util/scroll-to-top-util';
import { ActivityIndicator } from '@common/activity-indicator/activity-indicator';
import AppConfigurationService from '@services/app-configuration-service/app-configuration-service';
import Tracker from '@views/vehicle-order-tracking-status-view/components/tracker/tracker';
import CustomerOrderTrackingService, {
    CustomerOrderTrackingData,
    Part,
    PartClass,
    VehicleInfo,
} from '@services/customer-order-tracking-service/customer-order-tracking-service';
import { SecondaryButton } from '@common/index';
import { PartsList } from '@views/vehicle-order-tracking-status-view/components/parts-list/parts-list';
import Accordion from '@common/accordion/accordion';
import VehicleImageCarousel from '@views/vehicle-order-tracking-status-view/components/vehicle-image-carousel/vehicle-image-carousel';
import View360ImageService from '@services/view-360-image-service/view-360-image-service';
import WindowStickerService, {
    WindowStickerResponse,
} from '@services/window-sticker-service/window-sticker-service';
import HttpService from '@services/http-service/http-service';
import WindowStickerButton from '@views/vehicle-order-tracking-status-view/components/window-sticker-button/window-sticker-button';

import './vehicle-order-tracking-status-view.scss';
import TertiaryButton from '@common/tertiary-button/tertiary-button';
import AuthenticationService from '@services/authentication-service/authentication-service';
import NvoService from '@services/nvo-service/nvo-service';
import { useAnalytics } from '@/hooks/use-analytics';
import { Breadcrumbs } from '@/components/sections/breadcrumbs/breadcrumbs';

const view360IconFord = './icons/view-360-image-ford.svg';
const view360IconLincoln = './icons/view-360-image-lincoln.svg';
const closeIcon = './icons/close.svg';

interface CategorizedParts {
    title: string;
    parts: Part[];
}

interface AuthenticatedOrderInfo {
    vin: string;
    customerOrderNumber: string;
}

const VehicleOrderTrackingStatusView = () => {
    const [fireAnalytics] = useAnalytics();
    const appConfig = new AppConfigurationService();
    const isFord = appConfig.brand === 'ford';
    const navigate = useNavigate();
    const vehicleOrderTrackingContent: VehicleOrderTrackingContent =
        useVehicleOrderTrackingContent();
    const httpService = HttpService;
    const view360ImageService = new View360ImageService();
    const windowStickerService = new WindowStickerService(httpService);
    const authenticationService = new AuthenticationService();
    const customerOrderTrackingService = new CustomerOrderTrackingService(
        httpService
    );
    const nvoService = new NvoService(httpService);
    const [orderTrackingResponse, setOrderTrackingResponse] =
        useState<CustomerOrderTrackingData>(null);
    const [stepNumber, setStepNumber] = useState<number>(null);
    const [categorizedParts, setCategorizedParts] = useState<
        CategorizedParts[]
    >([]);
    const [show360Viewer, setShow360Viewer] = useState<boolean>(false);
    const [view360IsLoading, setView360IsLoading] = useState<boolean>(false);
    const [image360Available, setImage360Available] = useState<boolean>(false);
    const view360RefContainer = useRef<HTMLDivElement>();
    const [windowStickerUrl, setWindowStickerUrl] = useState<string>(null);
    const [hasCheckedForWindowSticker, setHasCheckedForWindowSticker] =
        useState<boolean>(false);
    const [vehicleGuideUrl, setVehicleGuideUrl] = useState<string>(null);
    const cvotOrderStatus =
        orderTrackingResponse?.orderInfo.lastCompletedStatus.primaryStatus;
    useAnalytics('ownerCvotStatus', '', { cvotOrderStatus });
    const isCanada = appConfig.get2LetterCountryCode() === 'ca';

    const groupPartsByCategory = (
        partClassArray: PartClass[]
    ): CategorizedParts[] => {
        const categorizedPartsArray: CategorizedParts[] = [];

        partClassArray?.forEach((partClass) => {
            let title = '';

            if (modelPartClassNameList.includes(partClass?.name)) {
                title =
                    vehicleOrderTrackingContent.partsAccordionModelInfoTitle;
            } else if (exteriorPartClassNameList.includes(partClass?.name)) {
                title = vehicleOrderTrackingContent.partsAccordionExteriorTitle;
            } else if (interiorPartClassNameList.includes(partClass?.name)) {
                title = vehicleOrderTrackingContent.partsAccordionInteriorTitle;
            } else if (accessoriesPartClassNameList.includes(partClass?.name)) {
                title =
                    vehicleOrderTrackingContent.partsAccordionAccessoriesTitle;
            } else if (packagesPartClassNameList.includes(partClass?.name)) {
                title = vehicleOrderTrackingContent.partsAccordionPackagesTitle;
            }

            if (!title) return;

            const visibleParts = partClass?.part.filter(
                (part) => part?.isVisible?.toLowerCase() !== 'false'
            );

            if (visibleParts.length > 0) {
                const existingGroup = categorizedPartsArray.find(
                    (group) => group.title === title
                );

                if (existingGroup) {
                    existingGroup.parts.push(...visibleParts);
                } else {
                    categorizedPartsArray.push({
                        title,
                        parts: visibleParts,
                    });
                }
            }
        });

        return sortCategorizedPartsByPredeterminedOrder(categorizedPartsArray);
    };

    const sortCategorizedPartsByPredeterminedOrder = (
        categorizedPartsArray: CategorizedParts[]
    ): CategorizedParts[] => {
        const order = [
            'Accessories',
            'Packages',
            'Interior',
            'Model Information',
            'Exterior',
        ];

        return categorizedPartsArray.sort(
            (a, b) => order.indexOf(a.title) - order.indexOf(b.title)
        );
    };

    const getWindowSticker = async (
        vin: string,
        paCode: string,
        isCanada: boolean
    ) => {
        let windowStickerResponse: WindowStickerResponse;

        try {
            windowStickerResponse = await windowStickerService.getWindowSticker(
                vin,
                paCode,
                isCanada
            );

            if (
                windowStickerResponse &&
                windowStickerResponse.windowStickerUrl &&
                !windowStickerResponse.windowStickerUrl.includes('404.pdf')
            ) {
                setWindowStickerUrl(windowStickerResponse.windowStickerUrl);
            }
        } catch (error) {
            console.error('Error getting window sticker: ', error);
            return null;
        } finally {
            setHasCheckedForWindowSticker(true);
        }
    };

    const emailAddressHref = `mailto:${orderTrackingResponse?.dealerInfo.orderingDealer.email}`;
    const showDeliveryDetails: boolean =
        !!vehicleOrderTrackingContent?.deliveryDetailsSectionHeader &&
        !!orderTrackingResponse?.dealerInfo.orderingDealer.name;

    const redirectIfBrandMismatch = (
        orderInfo: CustomerOrderTrackingData
    ): void => {
        if (
            (orderInfo.vehicleInfo.make.toLowerCase() === 'ford' &&
                appConfig.brand.toLowerCase() === 'lincoln') ||
            (orderInfo.vehicleInfo.make.toLowerCase() === 'lincoln' &&
                appConfig.brand.toLowerCase() === 'ford')
        ) {
            navigate(findPathByAlias('VehicleOrderTrackingView'));
        } else {
            setOrderTrackingResponse(orderInfo);
        }
    };

    const convertStatusToStepNumber = (status: string): number => {
        let stepNumber = 1;

        if (
            status === 'In Order Processing' &&
            !!orderTrackingResponse?.orderInfo.currentBuildWeek
        ) {
            stepNumber = 2;
        }
        if (status === 'In Production') {
            stepNumber = 3;
        }
        if (status === 'Awaiting Shipment') {
            stepNumber = 4;
        }
        if (orderTrackingResponse?.orderInfo.shippedDate) {
            stepNumber = 5;
        }
        if (status === 'Delivered') {
            stepNumber = 6;
        }

        return stepNumber;
    };

    const generateVehicleImage = (
        perspective: string,
        angle: number,
        size = 'HD-FULL'
    ): string => {
        const { make, model, year, imageToken }: VehicleInfo =
            orderTrackingResponse.vehicleInfo;
        const isProd: boolean = process.env.REACT_APP_ACTIVE_PROFILE === 'prod';

        return `${
            isProd ? DIG_PROD_URL : DIG_QA_URL
        }/${make}/${model}/${year}/${size}/${imageToken}/${perspective}/${angle}/vehicle.png`;
    };

    const formatDateForLocale = (date: string): string => {
        const datePortion: string = date.split(' ')[0];
        const year: string = datePortion.split('-')[0];
        const month: string = datePortion.split('-')[1];
        const day: string = datePortion.split('-')[2];

        if (
            DAY_MONTH_YEAR_REGIONS.includes(appConfig.currentLanguageRegionCode)
        ) {
            return datePortion ? `${day}/${month}/${year}` : '';
        }

        return datePortion ? `${month}/${day}/${year}` : '';
    };

    const show360ViewModal = () => {
        const imageURL = generateVehicleImage('INT', 3, 'DEFAULT');
        setView360IsLoading(true);
        view360ImageService.initialize360ViewerOnRef(
            view360RefContainer,
            imageURL,
            setView360IsLoading
        );
        setShow360Viewer(true);
    };

    const getOrderInfoFromStorage = () => {
        const orderInfoFromStorage = sessionStorage.getItem('cvot');
        return orderInfoFromStorage ? JSON.parse(orderInfoFromStorage) : null;
    };

    const getOrderInfoFromAuthenticatedExperience = async () => {
        const isAuthenticated = await authenticationService.onIsAuthenticated();
        const orderInfoFromAuthenticatedExperience = localStorage.getItem(
            CVOT_AUTHENTICATED_ORDER_STORAGE_KEY
        );

        if (orderInfoFromAuthenticatedExperience && isAuthenticated) {
            const { vin, customerOrderNumber }: AuthenticatedOrderInfo =
                JSON.parse(orderInfoFromAuthenticatedExperience);
            const orderResponse =
                await customerOrderTrackingService.getOrderTrackingStatusV2(
                    vin,
                    customerOrderNumber,
                    isCanada
                );
            return orderResponse?.customerOrder;
        }

        return null;
    };

    const redirectToVehicleOrderTrackingView = () => {
        navigate(findPathByAlias('VehicleOrderTrackingView'), {
            replace: true,
        });
    };

    const retrieveOrderData = async () => {
        const orderInfo: CustomerOrderTrackingData =
            (await getOrderInfoFromAuthenticatedExperience()) ||
            getOrderInfoFromStorage();

        if (!orderInfo?.orderInfo) {
            redirectToVehicleOrderTrackingView();
            return;
        }

        redirectIfBrandMismatch(orderInfo);
    };

    useEffect(() => {
        new ScrollUtil().scrollPageToTop();
        retrieveOrderData();
    }, []);

    useEffect(() => {
        if (orderTrackingResponse && vehicleOrderTrackingContent) {
            setCategorizedParts(
                groupPartsByCategory(
                    orderTrackingResponse.vehicleInfo?.partsInfo?.partClass
                )
            );
        }
    }, [orderTrackingResponse]);

    useEffect(() => {
        if (orderTrackingResponse?.vehicleInfo?.vin) {
            nvoService
                .getNVOGuide(orderTrackingResponse.vehicleInfo.vin)
                .then((response) => {
                    setVehicleGuideUrl(response?.guideUrl);
                });
        }
    }, [orderTrackingResponse?.vehicleInfo?.vin]);

    useEffect(() => {
        if (orderTrackingResponse) {
            setStepNumber(
                convertStatusToStepNumber(
                    orderTrackingResponse?.orderInfo.lastCompletedStatus
                        .primaryStatus
                )
            );

            view360ImageService
                .checkIfImageExists(generateVehicleImage('INT', 3, 'DEFAULT'))
                .then((response) => {
                    setImage360Available(response);
                });

            (async () =>
                await getWindowSticker(
                    orderTrackingResponse.vehicleInfo.vin,
                    orderTrackingResponse.dealerInfo.orderingDealer
                        .dealerPACode,
                    isCanada
                ))();
        }
    }, [orderTrackingResponse]);

    return (
        <>
            {view360IsLoading && <ActivityIndicator className="full-height" />}
            {show360Viewer && !view360IsLoading ? (
                <div
                    className="view-360-container"
                    data-testid="view-360-container"
                    ref={view360RefContainer}
                >
                    <button
                        className="view-360-close-button"
                        data-testid="view-360-close-button"
                        onClick={() => setShow360Viewer(false)}
                    >
                        <img
                            src={closeIcon}
                            alt="Close 360 image viewer icon"
                            className="view-360-close-button__icon"
                        />
                    </button>
                </div>
            ) : null}

            {vehicleOrderTrackingContent?.breadcrumbStatusTitle &&
            vehicleOrderTrackingContent?.breadcrumbTrackingTitle ? (
                <>
                    <Breadcrumbs
                        currentPage={
                            vehicleOrderTrackingContent?.breadcrumbStatusTitle
                        }
                        previousPage={
                            vehicleOrderTrackingContent?.breadcrumbTrackingTitle
                        }
                        previousLink={findPathByAlias(
                            'VehicleOrderTrackingView'
                        )}
                        crumbSeparator={appConfig.brand === 'ford' ? '/' : '>'}
                        hideBaseBreadcrumb={true}
                    ></Breadcrumbs>
                </>
            ) : null}

            {vehicleOrderTrackingContent && orderTrackingResponse?.orderInfo ? (
                <div
                    className="vehicle-order-tracking__status-view"
                    data-testid="vehicle-order-tracking-status-view"
                >
                    <div className="vehicle-order-tracking__status-view--content-container">
                        <h1 className="vehicle-order-tracking__status-view--title">
                            {vehicleOrderTrackingContent.statusPageGreetingText}
                        </h1>

                        <h2 className="vehicle-order-tracking__status-view--subtitle">
                            <span>
                                {vehicleOrderTrackingContent.statusPageYourText}
                            </span>{' '}
                            <span className="ymm">
                                {orderTrackingResponse.vehicleInfo.year}{' '}
                                {orderTrackingResponse.vehicleInfo.model}{' '}
                                {orderTrackingResponse.vehicleInfo.trim}
                            </span>{' '}
                            <span className="status-sentence">
                                {
                                    vehicleOrderTrackingContent[
                                        `orderTrackerStatus${stepNumber}Sentence`
                                    ]
                                }
                            </span>
                            {stepNumber === 5 && (
                                <span>
                                    {' '}
                                    {
                                        orderTrackingResponse.dealerInfo
                                            .orderingDealer.name
                                    }
                                </span>
                            )}
                        </h2>

                        <div className="vehicle-order-tracking__status-view--status-container">
                            <h3 className="vehicle-order-tracking__status-view--status">
                                {
                                    vehicleOrderTrackingContent[
                                        `orderTrackerStatus${stepNumber}Text`
                                    ]
                                }
                            </h3>
                            <p className="vehicle-order-tracking__status-view--status-subcopy">
                                {
                                    vehicleOrderTrackingContent[
                                        `orderTrackerStatus${stepNumber}Subcopy`
                                    ]
                                }
                            </p>
                        </div>
                        <div className="vehicle-order-tracking__status-view--accessories-button-container">
                            <SecondaryButton
                                aria-label={
                                    vehicleOrderTrackingContent.accessoriesLinkAriaLabel
                                }
                                className={`${
                                    isFord ? 'fmc-text-button' : ''
                                } accessories-button`}
                                dataTestId="ford-accessories-button"
                                link={
                                    vehicleOrderTrackingContent.accessoriesLinkHref
                                }
                                internal={false}
                                linkTarget={LinkTargetValues.BLANK}
                                isLincolnPermanentUnderline
                                onClick={() => {
                                    fireAnalytics(
                                        'cvotAccessoriesOnclickEvent'
                                    );
                                }}
                            >
                                {
                                    vehicleOrderTrackingContent.accessoriesLinkLabelText
                                }
                            </SecondaryButton>
                        </div>

                        {orderTrackingResponse?.orderInfo ? (
                            <Tracker
                                currentStep={stepNumber}
                                formatDateForLocale={formatDateForLocale}
                                orderTrackingData={orderTrackingResponse}
                                vehicleOrderTrackingContent={
                                    vehicleOrderTrackingContent
                                }
                            />
                        ) : (
                            <ActivityIndicator className="full-height" />
                        )}

                        <div className="vehicle-details-and-image__container">
                            <div className="vehicle-details__container">
                                <h4 className="vehicle-details__section-header">
                                    {
                                        vehicleOrderTrackingContent.vehicleDetailsSectionHeader
                                    }
                                </h4>

                                <div className="vehicle-details__container-row">
                                    <h5 className="vehicle-details__category-label">
                                        {
                                            vehicleOrderTrackingContent.orderedOnText
                                        }
                                    </h5>
                                    <p className="vehicle-details__category--value">
                                        {formatDateForLocale(
                                            orderTrackingResponse.orderInfo
                                                .receiptDate
                                        )}
                                    </p>
                                </div>
                                <div className="vehicle-details__container-row">
                                    <h5 className="vehicle-details__category--label">
                                        {
                                            vehicleOrderTrackingContent.orderNumberText
                                        }
                                    </h5>
                                    <p
                                        className="vehicle-details__category--value"
                                        data-testid="vehicle-details__order-number"
                                    >
                                        {
                                            orderTrackingResponse.orderInfo
                                                .orderNumber
                                        }
                                    </p>
                                </div>
                                <div className="vehicle-details__container-row">
                                    <h5 className="vehicle-details__category-label">
                                        {vehicleOrderTrackingContent.vinText}
                                    </h5>
                                    <p className="vehicle-details__category--value">
                                        {orderTrackingResponse.vehicleInfo.vin}
                                    </p>
                                </div>
                                {stepNumber === 4 || stepNumber === 5 ? (
                                    <div className="vehicle-details__container-row">
                                        <h5 className="vehicle-details__category-label">
                                            {
                                                vehicleOrderTrackingContent.estimatedDeliveryText
                                            }
                                        </h5>
                                        <p className="vehicle-details__category--value">
                                            {formatDateForLocale(
                                                orderTrackingResponse.orderInfo
                                                    .lastCompletedStatus.eta
                                                    .etaStartDate
                                            )}{' '}
                                            -{' '}
                                            {formatDateForLocale(
                                                orderTrackingResponse.orderInfo
                                                    .lastCompletedStatus.eta
                                                    .etaEndDate
                                            )}
                                        </p>
                                    </div>
                                ) : null}
                                <div className="vehicle-details__container-row">
                                    <h5 className="vehicle-details__category-label">
                                        {
                                            vehicleOrderTrackingContent.lastUpdatedText
                                        }
                                    </h5>
                                    <p className="vehicle-details__category--value">
                                        {formatDateForLocale(
                                            orderTrackingResponse.orderInfo
                                                .lastCompletedStatus
                                                .statusDateTime
                                        )}
                                    </p>
                                </div>
                                {hasCheckedForWindowSticker &&
                                    windowStickerUrl &&
                                    vehicleOrderTrackingContent.windowStickerButtonLabelText && (
                                        <div className="vehicle-details__container-row">
                                            <WindowStickerButton
                                                isLincoln={!isFord}
                                                windowStickerButtonLabelText={
                                                    vehicleOrderTrackingContent.windowStickerButtonLabelText
                                                }
                                                windowStickerUrl={
                                                    windowStickerUrl
                                                }
                                                windowStickerAriaLabel={
                                                    vehicleOrderTrackingContent.windowStickerButtonAriaLabel
                                                }
                                                windowStickerTarget={
                                                    LinkTargetValues.BLANK
                                                }
                                                onClick={() =>
                                                    fireAnalytics(
                                                        'cvotWindowStickerOnclickEvent'
                                                    )
                                                }
                                            />
                                        </div>
                                    )}
                                <div className="ownership-links-container">
                                    {vehicleGuideUrl &&
                                    vehicleOrderTrackingContent.vehicleGuideButtonLabelText ? (
                                        <div className="vehicle-guide-button__container">
                                            {isFord ? (
                                                <TertiaryButton
                                                    labelText={
                                                        vehicleOrderTrackingContent.vehicleGuideButtonLabelText
                                                    }
                                                    link={vehicleGuideUrl}
                                                    linkTarget={
                                                        LinkTargetValues.BLANK
                                                    }
                                                    ariaLabel={
                                                        vehicleOrderTrackingContent.vehicleGuideButtonAriaLabel
                                                    }
                                                    internal={false}
                                                    dataTestId="vehicle-guide-button"
                                                    chevronDirection={'right'}
                                                />
                                            ) : (
                                                <SecondaryButton
                                                    aria-label={
                                                        vehicleOrderTrackingContent.vehicleGuideButtonAriaLabel
                                                    }
                                                    className={`${
                                                        isFord
                                                            ? 'fmc-text-button'
                                                            : ''
                                                    }`}
                                                    dataTestId="vehicle-guide-button"
                                                    link={vehicleGuideUrl}
                                                    internal={false}
                                                    linkTarget={
                                                        LinkTargetValues.BLANK
                                                    }
                                                    isLincolnPermanentUnderline
                                                >
                                                    {
                                                        vehicleOrderTrackingContent.vehicleGuideButtonLabelText
                                                    }
                                                </SecondaryButton>
                                            )}
                                        </div>
                                    ) : null}
                                    {vehicleOrderTrackingContent.ownerRegistrationButtonLabelText &&
                                    vehicleOrderTrackingContent.ownerRegistrationButtonHref ? (
                                        <div className="owner-registration-button__container">
                                            {isFord ? (
                                                <TertiaryButton
                                                    labelText={
                                                        vehicleOrderTrackingContent.ownerRegistrationButtonLabelText
                                                    }
                                                    link={
                                                        vehicleOrderTrackingContent.ownerRegistrationButtonHref
                                                    }
                                                    linkTarget={
                                                        LinkTargetValues.BLANK
                                                    }
                                                    ariaLabel={
                                                        vehicleOrderTrackingContent.ownerRegistrationButtonAriaLabel
                                                    }
                                                    internal={false}
                                                    dataTestId="owner-registration-button"
                                                    chevronDirection={'right'}
                                                    onClick={() =>
                                                        fireAnalytics(
                                                            'cvotStatusSupportHomeOnclickEvent'
                                                        )
                                                    }
                                                />
                                            ) : (
                                                <SecondaryButton
                                                    aria-label={
                                                        vehicleOrderTrackingContent.ownerRegistrationButtonAriaLabel
                                                    }
                                                    className={`${
                                                        isFord
                                                            ? 'fmc-text-button'
                                                            : ''
                                                    }`}
                                                    dataTestId="owner-registration-button"
                                                    link={
                                                        vehicleOrderTrackingContent.ownerRegistrationButtonHref
                                                    }
                                                    internal={false}
                                                    linkTarget={
                                                        LinkTargetValues.BLANK
                                                    }
                                                    isLincolnPermanentUnderline
                                                    onClick={() =>
                                                        fireAnalytics(
                                                            'cvotStatusSupportHomeOnclickEvent'
                                                        )
                                                    }
                                                >
                                                    {
                                                        vehicleOrderTrackingContent.ownerRegistrationButtonLabelText
                                                    }
                                                </SecondaryButton>
                                            )}
                                        </div>
                                    ) : null}
                                </div>
                            </div>

                            {showDeliveryDetails && (
                                <div className="delivery-details__container">
                                    <h4 className="delivery-details__section-header">
                                        {
                                            vehicleOrderTrackingContent.deliveryDetailsSectionHeader
                                        }
                                    </h4>
                                    <div className="delivery-details__content">
                                        {orderTrackingResponse.dealerInfo
                                            .orderingDealer.name && (
                                            <p className="delivery-details__content--name">
                                                {
                                                    orderTrackingResponse
                                                        .dealerInfo
                                                        .orderingDealer.name
                                                }
                                            </p>
                                        )}
                                        {orderTrackingResponse.dealerInfo
                                            .orderingDealer.address.street1 && (
                                            <p className="delivery-details__content--address">
                                                {
                                                    orderTrackingResponse
                                                        .dealerInfo
                                                        .orderingDealer.address
                                                        .street1
                                                }
                                            </p>
                                        )}
                                        {orderTrackingResponse.dealerInfo
                                            .orderingDealer.address.street2 && (
                                            <p className="delivery-details__content--address">
                                                {
                                                    orderTrackingResponse
                                                        .dealerInfo
                                                        .orderingDealer.address
                                                        .street2
                                                }
                                            </p>
                                        )}
                                        {orderTrackingResponse.dealerInfo
                                            .orderingDealer.address.city &&
                                        orderTrackingResponse.dealerInfo
                                            .orderingDealer.address.state &&
                                        orderTrackingResponse.dealerInfo
                                            .orderingDealer.address
                                            .postalCode ? (
                                            <p className="delivery-details__content--address">
                                                <span>
                                                    {
                                                        orderTrackingResponse
                                                            .dealerInfo
                                                            .orderingDealer
                                                            .address.city
                                                    }
                                                </span>
                                                ,{' '}
                                                <span>
                                                    {
                                                        orderTrackingResponse
                                                            .dealerInfo
                                                            .orderingDealer
                                                            .address.state
                                                    }
                                                </span>{' '}
                                                <span>
                                                    {
                                                        orderTrackingResponse
                                                            .dealerInfo
                                                            .orderingDealer
                                                            .address.postalCode
                                                    }
                                                </span>
                                            </p>
                                        ) : null}
                                        {orderTrackingResponse.dealerInfo
                                            .orderingDealer.url && (
                                            <a
                                                href={
                                                    orderTrackingResponse
                                                        .dealerInfo
                                                        .orderingDealer.url
                                                }
                                                target="_blank"
                                                className="delivery-details__content--website"
                                                onClick={() =>
                                                    fireAnalytics(
                                                        'cvotDealerHomeOnclickEvent'
                                                    )
                                                }
                                            >
                                                {
                                                    orderTrackingResponse
                                                        .dealerInfo
                                                        .orderingDealer.url
                                                }
                                            </a>
                                        )}
                                        {orderTrackingResponse.dealerInfo
                                            .orderingDealer.email && (
                                            <a
                                                href={emailAddressHref}
                                                className="delivery-details__content--email"
                                                onClick={() =>
                                                    fireAnalytics(
                                                        'cvotStatusEmailOnclickEvent'
                                                    )
                                                }
                                            >
                                                {
                                                    orderTrackingResponse
                                                        .dealerInfo
                                                        .orderingDealer.email
                                                }
                                            </a>
                                        )}
                                        {orderTrackingResponse.dealerInfo
                                            .orderingDealer.phone && (
                                            <a
                                                href={`tel:${orderTrackingResponse.dealerInfo.orderingDealer.phone}`}
                                                className="delivery-details__content--phone"
                                                onClick={() =>
                                                    fireAnalytics(
                                                        'cvotFdCallOnclickEvent'
                                                    )
                                                }
                                            >
                                                {
                                                    orderTrackingResponse
                                                        .dealerInfo
                                                        .orderingDealer.phone
                                                }
                                            </a>
                                        )}
                                    </div>
                                </div>
                            )}

                            <div className="vehicle-image__container">
                                {image360Available && (
                                    <button
                                        className="view-360-button"
                                        data-testid="view-360-button"
                                        onClick={() => {
                                            fireAnalytics(
                                                'cvotStatusInteriorOnclickEvent'
                                            );
                                            show360ViewModal();
                                        }}
                                    >
                                        <img
                                            className="view-360-icon"
                                            src={
                                                isFord
                                                    ? view360IconFord
                                                    : view360IconLincoln
                                            }
                                            alt="View 360 image icon"
                                        />
                                    </button>
                                )}

                                <VehicleImageCarousel
                                    images={[
                                        generateVehicleImage('EXT', 1),
                                        generateVehicleImage('EXT', 2),
                                        generateVehicleImage('EXT', 3),
                                        generateVehicleImage('EXT', 4),
                                        generateVehicleImage('EXT', 5),
                                        generateVehicleImage('INT', 1),
                                        generateVehicleImage('INT', 2),
                                    ]}
                                />
                            </div>
                        </div>

                        <div
                            className="parts-accordion-container"
                            data-testid="parts-accordion-container"
                        >
                            <Accordion
                                items={categorizedParts?.map((item) => ({
                                    title: item.title,
                                    content: (
                                        <PartsList
                                            parts={item.parts}
                                            vehicleOrderTrackingContent={
                                                vehicleOrderTrackingContent
                                            }
                                        />
                                    ),
                                }))}
                                analyticFiring={'cvotAccordionOnclickEvent'}
                            />
                        </div>
                    </div>
                </div>
            ) : (
                <ActivityIndicator className="full-height" />
            )}
        </>
    );
};

export default VehicleOrderTrackingStatusView;
