import { useContent } from '@hooks/use-server-content';

export interface AddVehicleContent {
    hide: boolean;
    addVehicleButton: string;
    addVehicleLabel: string;
    addVehicleDescription: string;
    vin: string;
    nickname: string;
    btnUpdate: string;
    btnCancel: string;
    incorrectVin: string;
    reEnter: string;
    vinToolTipInfo: string;
    whereDoIFindMyVin: string;
    vinErrorMessage: string;
    vinWfoValidationErrorMessage: string;
    registrationNumberErrorMessage: string;
    vinAndRegistrationPatternRules: string;
    existingVinMessage: string;
    vinValidationEnter: string;
    vinValidationWFO: string;
    vehicleNotFoundErrorMessage: string;
    vinAlphanumericCombinationErrorMessage: string;
    specialCharacterRegistrationErrorMessage: string;
    getStartedAriaLabel?: string;
    addVehicleMaxLimitErrorMessage: string;
    addVehicleMaxLimitConfirmButton: string;
    vinMismatchConfirmButton: string;
    vinMismatchCancelButton: string;
    vinMismatchMessage: string;
    vinMismatchConfirmLink: string;
}
export const useAddVehicleContent = (): AddVehicleContent | null => {
    const [content, getValueByName] = useContent('common', 'add-vehicle');
    const contentFragment: any = {};
    if (content) {
        content?.elements.forEach((element) => {
            contentFragment[`${element.name}`] = getValueByName(
                `${element.name}`
            );
        });
    }

    return contentFragment as AddVehicleContent;
};
