import { useContent } from '@hooks/use-server-content';

export interface GuestLoginContent {
    headerText: string;
    subheaderText: string;
    orderNumberInputLabelText: string;
    orderNumberInputAriaLabel: string;
    orderNumberInputValidationPattern: string;
    orderNumberInputErrorMessageInvalid: string;
    emailAddressInputLabelText: string;
    emailAddressInputAriaLabel: string;
    emailAddressInputValidationPattern: string;
    emailAddressInputErrorMessageInvalid: string;
    submitDetailsButtonLabelText: string;
    submitDetailsButtonAriaLabel: string;
    cantFindOrderNumberText: string;
    contactCustomerServiceLinkText: string;
    contactCustomerServiceLinkHref: string;
    contactCustomerServiceLinkAriaLabel: string;
}

export const useGuestLoginContent = (): GuestLoginContent | null => {
    const [content, getValueByName] = useContent('common', 'nvc-guest-login');
    const contentFragment: any = {};

    if (content) {
        content?.elements.forEach((element) => {
            contentFragment[`${element.name}`] = getValueByName(
                `${element.name}`
            );
        });
    }

    if (!Object.keys(contentFragment).length) return null;

    return contentFragment as GuestLoginContent;
};
