import React from 'react';
import { Link } from 'react-router-dom';
import AppConfigurationService from '@services/app-configuration-service/app-configuration-service';
import { BetaWrapper } from '@utils/beta-wrapper/beta-wrapper';
import { useAppInformationContent } from './hook/use-breadcrumb-content';
import './breadcrumbs.scss';
import { SYNDICATED_HEADER_COUNTRIES } from '@/constants';
import { findPathByAlias } from '@/routes/routesList';

interface Props {
    currentPage: string;
    crumbSeparator: '/' | '>';
    previousPage?: string;
    previousLink?: string;
    secondPreviousPage?: string;
    secondPreviousLink?: string;
    hideBaseBreadcrumb?: boolean;
}

export const Breadcrumbs = (props: Props) => {
    const appInformationContent = useAppInformationContent();
    const isLandingPage = props.currentPage === 'Landing';
    const { currentRegionCode } = new AppConfigurationService();
    const isSyndicatedHeader =
        SYNDICATED_HEADER_COUNTRIES.includes(currentRegionCode);

    const HomepageBreadcrumb = () => {
        if (appInformationContent) {
            return (
                <BetaWrapper
                    text={appInformationContent.breadcrumbStartingValue}
                />
            );
        } else {
            return <></>;
        }
    };

    const DynamicBreadcrumbs = () => {
        return appInformationContent ? (
            <>
                {!props.hideBaseBreadcrumb ? (
                    <>
                        <Link
                            to={findPathByAlias('GlobalAccountHomePage')}
                            className="breadcrumb-link"
                        >
                            <BetaWrapper
                                text={
                                    appInformationContent.breadcrumbStartingValue
                                }
                            />
                        </Link>
                        <span className="back-slash">
                            {props.crumbSeparator}
                        </span>
                    </>
                ) : null}
                {props.secondPreviousPage && (
                    <>
                        <Link
                            to={props.secondPreviousLink}
                            className="breadcrumb-link"
                        >
                            <BetaWrapper text={props.secondPreviousPage} />
                        </Link>
                        <span className="back-slash">
                            {props.crumbSeparator}
                        </span>
                    </>
                )}
                {props.previousPage && (
                    <>
                        <Link
                            to={props.previousLink}
                            className="breadcrumb-link"
                        >
                            <BetaWrapper text={props.previousPage} />
                        </Link>
                        <span className="back-slash">
                            {props.crumbSeparator}
                        </span>
                    </>
                )}
                <span className="current-page">{props.currentPage}</span>
            </>
        ) : (
            <></>
        );
    };

    return (
        <div
            className={`breadcrumbs ${
                isSyndicatedHeader ? 'syndicated-header' : ''
            }`}
            data-testid="breadcrumbs-component"
        >
            <div className="breadcrumbs-content">
                {isLandingPage ? (
                    <HomepageBreadcrumb />
                ) : (
                    <DynamicBreadcrumbs />
                )}
            </div>
        </div>
    );
};
