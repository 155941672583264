import React, { useState } from 'react';
import { animated, useSpring } from 'react-spring';
import { HeaderFragment, Category } from '@models/experiencefragments/header';
import { Chevron, Link } from '@common/index';
import { ImgLanguageToggle } from '../../language-toggle/language-toggle';
import { SubnavMobile } from '../subnav/subnav';
import './sidebar-menu.scss';

interface SidebarMenuProps {
    isOpen: boolean;
    className: string;
    toggleMenu: React.MouseEventHandler<HTMLElement>;
    experienceContent: HeaderFragment;
}

const SidebarMenu = (props: SidebarMenuProps) => {
    const { isOpen, className } = props;
    const [activeSubNavCategory, setActiveSubNavCategory] = useState<
        Category[]
    >([]);
    const [activeSubnav, setActiveSubnav] = useState('');
    const animationProps = useSpring({
        left: isOpen ? '0%' : '-100%',
    });

    const openSubnav = (newSubnav: string, drawersContent: Category[]) => {
        return <SubnavMobile title={newSubnav} drawers={drawersContent} />;
    };

    function chooseClassForNavMenuButton(navToCheckAgainst: string) {
        if (activeSubnav === '') {
            return '';
        }

        return activeSubnav === navToCheckAgainst
            ? 'selected-sidebar-menu-item'
            : 'unselected-sidebar-menu-item';
    }

    return (
        <>
            {props.experienceContent && (
                <animated.div
                    id="sidebar-menu"
                    className={`sidebar-container ${className} ${
                        activeSubnav ? 'grey-border' : ''
                    }`}
                    data-testid="sidebar-menu"
                    style={animationProps}
                >
                    <div
                        className={`sidebar-menu-container  ${
                            activeSubnav ? 'sidebar-minimized' : ''
                        }`}
                    >
                        <ul tabIndex={isOpen ? 0 : -1}>
                            {props.experienceContent['main-nav-left'] &&
                                props.experienceContent['main-nav-left']?.map(
                                    (navsub, index) => {
                                        if (
                                            !navsub.categories &&
                                            navsub.noFlyout
                                        ) {
                                            return (
                                                <li
                                                    className={chooseClassForNavMenuButton(
                                                        navsub.title
                                                    )}
                                                >
                                                    <nav
                                                        aria-label={
                                                            navsub.title
                                                        }
                                                        key={index}
                                                    >
                                                        <Link
                                                            href={navsub.url}
                                                            target={
                                                                navsub.targetBlank
                                                                    ? '_blank'
                                                                    : '_self'
                                                            }
                                                            className="sidebar-menu-item"
                                                            aria-label={
                                                                navsub.ariaLabel
                                                            }
                                                        >
                                                            <img
                                                                src={
                                                                    process.env
                                                                        .REACT_APP_AEM_BASE_URL +
                                                                    navsub.iconPath
                                                                }
                                                                alt={
                                                                    navsub.ariaLabel
                                                                }
                                                                className="sidebar-menu-item-icon"
                                                            />
                                                            <span
                                                                className={`fmc-type--body1 fds-color__text--primary`}
                                                            >
                                                                {navsub.title}
                                                            </span>
                                                            <Chevron direction="right" />
                                                        </Link>
                                                    </nav>
                                                </li>
                                            );
                                        } else if (
                                            navsub.categories &&
                                            !navsub.noFlyout
                                        ) {
                                            return (
                                                <li
                                                    className={chooseClassForNavMenuButton(
                                                        navsub.title
                                                    )}
                                                >
                                                    <nav
                                                        aria-label={
                                                            navsub.title
                                                        }
                                                        key={index}
                                                    >
                                                        <button
                                                            onClick={() => {
                                                                if (
                                                                    activeSubnav ===
                                                                    navsub.title
                                                                ) {
                                                                    setActiveSubnav(
                                                                        ''
                                                                    );
                                                                } else {
                                                                    setActiveSubnav(
                                                                        navsub.title
                                                                    );
                                                                }

                                                                setActiveSubNavCategory(
                                                                    navsub.categories
                                                                );
                                                            }}
                                                            className="sidebar-menu-item"
                                                            aria-label={
                                                                navsub.ariaLabel
                                                            }
                                                        >
                                                            <span
                                                                className={`fmc-type--body1 fds-color__text--primary`}
                                                            >
                                                                {navsub.title}
                                                            </span>
                                                            <Chevron
                                                                selected={
                                                                    activeSubnav ===
                                                                    navsub.title
                                                                }
                                                                direction="down"
                                                            />
                                                        </button>
                                                    </nav>
                                                    {activeSubnav ===
                                                        navsub.title &&
                                                        openSubnav(
                                                            activeSubnav,
                                                            activeSubNavCategory
                                                        )}
                                                </li>
                                            );
                                        }
                                    }
                                )}

                            {props.experienceContent['main-nav-right'].language
                                .languageItems !== null &&
                                props.experienceContent['main-nav-right']
                                    .language.languageItems.length > 1 && (
                                    <li>
                                        <div className="mobile-language-container">
                                            <ImgLanguageToggle
                                                tabIndex={0}
                                                languageText={
                                                    props.experienceContent &&
                                                    props.experienceContent[
                                                        'main-nav-right'
                                                    ]?.language?.languageItems
                                                }
                                                dataTestId="language-toggle"
                                            />
                                        </div>
                                    </li>
                                )}
                        </ul>
                    </div>
                </animated.div>
            )}
        </>
    );
};

export default SidebarMenu;
