import React from 'react';
import './feature-banner.scss';
import { useFeatureBannerContent } from './hooks/use-feature-banner-content';
import { useWindowSize } from '@hooks/use-window-size';

interface Props {
    threeLetterCountryCode: string;
    isLincoln: boolean;
}
export const FeatureBanner = (props: Props) => {
    const featureBannerContent = useFeatureBannerContent();
    const isCanada = props.threeLetterCountryCode === 'can';
    const size = useWindowSize();
    const isMobile: boolean = size.width < 992;

    return (
        <>
            {featureBannerContent &&
                (props.isLincoln ? (
                    <section
                        className="feature-content"
                        data-testid="feature-content-id"
                    >
                        <div className="feature-content-body">
                            <div className="feature-content-title">
                                {featureBannerContent?.heading}
                            </div>

                            <div className="feature-content-description">
                                <span className="fmc-type--body1 ">
                                    {featureBannerContent?.bodyCopy}
                                </span>
                            </div>
                            <div className="feature-content-link-container">
                                <a
                                    href={featureBannerContent?.ctaLink}
                                    className="fmc-button fmc-button--outlined"
                                    data-dark="true"
                                >
                                    <span className="fmc-type--body1">
                                        {featureBannerContent?.ctaText}
                                    </span>
                                </a>
                            </div>
                        </div>
                        <div
                            className={`bannerImage ${
                                isCanada ? 'canada-banner' : ''
                            }`}
                            style={{
                                backgroundImage: `url(${
                                    process.env.REACT_APP_AEM_BASE_URL +
                                    (isMobile &&
                                    featureBannerContent?.imageHeroMobile
                                        ? featureBannerContent?.imageHeroMobile
                                        : featureBannerContent?.imageHero)
                                })`,
                            }}
                        ></div>
                    </section>
                ) : (
                    <section
                        className="feature-content"
                        data-testid="feature-content-id"
                    >
                        <div
                            className={`bannerImage ${
                                isCanada ? 'canada-banner' : ''
                            }`}
                            style={{
                                backgroundImage: `url(${
                                    process.env.REACT_APP_AEM_BASE_URL +
                                    (isMobile &&
                                    featureBannerContent?.imageHeroMobile
                                        ? featureBannerContent?.imageHeroMobile
                                        : featureBannerContent?.imageHero)
                                })`,
                            }}
                        ></div>
                        <div className="feature-content-body">
                            <div className="feature-content-title">
                                {featureBannerContent?.heading}
                            </div>

                            <div
                                className="feature-content-description"
                                dangerouslySetInnerHTML={{
                                    __html: featureBannerContent?.bodyCopy,
                                }}
                            ></div>
                            <div className="feature-content-link-container">
                                <a
                                    href={featureBannerContent?.ctaLink}
                                    className="feature-content-button"
                                    data-dark="true"
                                >
                                    <span className="feature-content-button-text">
                                        {featureBannerContent?.ctaText}
                                    </span>
                                </a>
                            </div>
                        </div>
                    </section>
                ))}
        </>
    );
};
