import { KEYBOARD_KEYS } from '@/constants';
import React, {
    Dispatch,
    SetStateAction,
    useEffect,
    useRef,
    useState,
} from 'react';

interface Props {
    DropdownTemplateContent: any;
    dropdownTemplateLabel?: string;
    field: string;
    selectedItem: string;
    setSelectedItem: Dispatch<SetStateAction<string>>;
}

const DropdownTemplate = (props: Props) => {
    const comboboxRef = useRef(null);

    const [value, setValue] = useState(props.selectedItem || '');

    const [dropdownIsOpen, setIsDropdownOpen] = useState<boolean>();

    const chooseItem = (item) => {
        setValue(item);
        props.setSelectedItem(item);
        setIsDropdownOpen(false);
    };

    const handleClickOutsideCombobox = (e) => {
        if (comboboxRef?.current && !comboboxRef.current?.contains(e.target)) {
            setIsDropdownOpen(false);
        }
    };

    const keydownHandler = (e) => {
        const focusableElements = comboboxRef?.current?.querySelectorAll(
            'input, [role="option"]'
        );
        const firstFocusableElement = focusableElements[0];
        const lastFocusableElement =
            focusableElements[focusableElements.length - 1];

        if (e.shiftKey && e.key === KEYBOARD_KEYS.TAB) {
            if (document?.activeElement === firstFocusableElement) {
                setIsDropdownOpen(false);
            }
        } else if (e.key === KEYBOARD_KEYS.TAB) {
            if (document?.activeElement === lastFocusableElement) {
                setIsDropdownOpen(false);
            }
        }
    };

    useEffect(() => {
        comboboxRef?.current?.addEventListener('keydown', keydownHandler, true);

        return () => {
            comboboxRef?.current?.removeEventListener(
                'keydown',
                keydownHandler,
                true
            );
        };
    }, []);

    useEffect(() => {
        document?.addEventListener('click', handleClickOutsideCombobox, true);

        return () => {
            document?.removeEventListener(
                'click',
                handleClickOutsideCombobox,
                true
            );
        };
    }, []);

    return (
        <div
            ref={comboboxRef}
            className="dynamic-dropdown-container"
            data-testid="dynamic-dropdown-container"
        >
            <label
                className="dynamic-dropdown-field-label"
                htmlFor={`country-code-${props.field}`}
                data-testid="dynamic-dropdown-field-label"
                aria-label={
                    props.dropdownTemplateLabel || 'dropdown-template-label'
                }
            >
                {props.dropdownTemplateLabel || ''}
            </label>

            <div className="dynamic-dropdown-input-group">
                <input
                    id={`country-code-${props.field}`}
                    className={`fmc-input ${
                        !dropdownIsOpen ? 'invisible-text' : ''
                    }`}
                    type="text"
                    readOnly={true}
                    placeholder={
                        !props.selectedItem ? props.dropdownTemplateLabel : ''
                    }
                    data-testid="dynamic-dropdown-input"
                    list=""
                    name="country-list"
                    role="combobox"
                    aria-autocomplete="list"
                    aria-expanded={dropdownIsOpen ? 'true' : 'false'}
                    aria-controls="country-list"
                    value={value}
                    onChange={(e) => setValue(e.target.value)}
                    onFocus={() => {
                        setIsDropdownOpen(true);
                    }}
                    onKeyDown={(e) => {
                        if (e.shiftKey && e.key === KEYBOARD_KEYS.TAB) {
                            setIsDropdownOpen(false);
                        }
                    }}
                />

                <button
                    className={`menu-toggle ${dropdownIsOpen ? 'open' : ''}`}
                    type="button"
                    aria-label="Toggle Menu"
                    tabIndex={-1}
                    onClick={() => setIsDropdownOpen(!dropdownIsOpen)}
                    onKeyDown={(e) => {
                        if (e.key === KEYBOARD_KEYS.ENTER || e.key === ' ') {
                            setIsDropdownOpen(!dropdownIsOpen);
                        }
                    }}
                >
                    <span
                        className="fds-icon fds-icon--32 fds-font--ford-icons__chevron-down"
                        aria-hidden={true}
                    ></span>
                    <span aria-hidden={true}>_</span>
                </button>

                {!dropdownIsOpen && props.selectedItem ? (
                    <div
                        tabIndex={-1}
                        className="selected-country"
                        data-testid="selected-country"
                        onClick={() => setIsDropdownOpen(true)}
                    >
                        <p className="selected-country-text">
                            {props.selectedItem}
                        </p>
                    </div>
                ) : null}
            </div>

            {dropdownIsOpen && value !== null && (
                <div className="dynamic-dropdown-results">
                    <ul
                        className="country-list"
                        id="country-list"
                        role="listbox"
                        aria-label="List of Countries"
                    >
                        {props.DropdownTemplateContent.map((item, index) => {
                            return (
                                <li
                                    key={index}
                                    tabIndex={0}
                                    role="option"
                                    className="country-option"
                                    data-testid="country-option"
                                    onClick={() => {
                                        chooseItem(item);
                                    }}
                                    onKeyDown={(e) => {
                                        if (
                                            e.key === KEYBOARD_KEYS.ENTER ||
                                            e.key === ' '
                                        ) {
                                            chooseItem(item);
                                        }
                                    }}
                                >
                                    {item}
                                </li>
                            );
                        })}
                    </ul>
                </div>
            )}
        </div>
    );
};

export default DropdownTemplate;
