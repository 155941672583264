import * as React from 'react';
import { Category } from '@models/experiencefragments/header';
import { Link } from '@common/index';
import { PageLink } from '../page-link.interface';
import './subnav.scss';

type Props = {
    title: string;
    drawers: Category[];
};

export const SubnavMobile = (props: Props) => {
    const makeLinks = (links: PageLink[], showLinks: boolean, id: string) => (
        <ul
            data-testid={id}
            className={`links-container ${showLinks ? '' : 'hide-me'}`}
        >
            {links.map((link, linkIndex) => (
                <li key={linkIndex} className="subnav-link">
                    <Link
                        target={link.openInTab ? '_blank' : ''}
                        href={link.url}
                        aria-label={link.title}
                        className="fds-color__text--primary"
                    >
                        {link.title}
                    </Link>
                </li>
            ))}
        </ul>
    );

    function makeMobileDrawer(drawer: Category, index: number) {
        return (
            <div key={index} className="subnav-drawer">
                <h3 className={'fmc-type--heading6 fds-color__text--primary'}>
                    {drawer.categoryTitle}
                </h3>
                <ul>
                    {makeLinks(
                        drawer.categoryItems,
                        true,
                        `drawer-links-${index}`
                    )}
                </ul>
            </div>
        );
    }

    return (
        <div className={`sidebar-subnav ${props.title ? '' : 'hide-me'}`}>
            <div className="subnav-drawers">
                {props.drawers.map(makeMobileDrawer)}
            </div>
        </div>
    );
};
