import { useContent } from '@hooks/use-server-content';

interface FeatureBannerProps {
    imageHero: string;
    imageHeroMobile: string;
    imageAlt: string;
    heading: string;
    bodyCopy: string;
    ctaText: string;
    ctaAriaLabel: string;
    ctaLink: string;
    ctaTarget: string;
}

export const useFeatureBannerContent = (): FeatureBannerProps | null => {
    const [content, getValueByName] = useContent('common', 'feature-banner');
    let contentFragment: any = {};
    if (content) {
        content?.elements.forEach((element) => {
            contentFragment[`${element.name}`] = getValueByName(
                `${element.name}`
            );
        });
    }
    if (contentFragment) {
        if (contentFragment.hide) {
            contentFragment = null;
        }
    }
    return contentFragment as FeatureBannerProps;
};
