import { DSL_API_PATH, DSL_BASE_URL } from '@constants';
import Axios, { AxiosResponse } from 'axios';
import httpAdapter from 'axios/lib/adapters/http';
import { HttpsProxyAgent } from 'https-proxy-agent';
import {
    ProfileWithVehiclesResponse,
    VehicleDetail,
} from '@models/profile-with-vehicles';
import HttpService from '../http-service/http-service';
import ServiceHandler from '../service-handler';
import AppConfigurationService from '../app-configuration-service/app-configuration-service';
import AuthenticationService from '../authentication-service/authentication-service';

const proxyAgent = new HttpsProxyAgent({
    host: 'internet.ford.com',
    port: 83,
});

const axios = Axios.create({
    adapter: httpAdapter,
    proxy: false,
    httpAgent: proxyAgent,
});

const STORAGE_KEY_USER_PREFERENCES = 'userPreferences';

export default class ProfileService {
    public redirectToFMA = () => {
        const authenticationService = new AuthenticationService();
        console.error(`User Timeout`);
        authenticationService.login();
    };

    public async request(): Promise<ProfileWithVehiclesResponse | void> {
        const appConfig = new AppConfigurationService();
        const dslUrl = appConfig.getAppConfiguration().dslUrl
            ? appConfig.getAppConfiguration().dslUrl
            : DSL_BASE_URL;
        const url = dslUrl + DSL_API_PATH.PROFILE_WITH_VEHICLES;

        return HttpService.get(
            `${url}?locale=${appConfig.currentLanguageRegionCode}&country=${appConfig.current3LetterCountryCapitalizedCode}`,
            true,
            {
                headers: HttpService.getConsumerKeyAndAuthTokenRequestHeaders(),
                adapter: httpAdapter,
                proxy: false,
                httpAgent: proxyAgent,
            },
            180000
        )
            .then(this.transformResponse)
            .catch((e) => e.response.status === 401 && this.redirectToFMA());
    }

    public async requestLite(
        cacheable = false
    ): Promise<ProfileWithVehiclesResponse | void> {
        const appConfig = new AppConfigurationService();
        const dslUrl = appConfig.getAppConfiguration().dslUrl
            ? appConfig.getAppConfiguration().dslUrl
            : DSL_BASE_URL;
        const url = dslUrl + DSL_API_PATH.PROFILE_LITE;

        return HttpService.get(
            `${url}?locale=${appConfig.currentLanguageRegionCode}&country=${appConfig.current3LetterCountryCapitalizedCode}`,
            cacheable,
            {
                headers: HttpService.getConsumerKeyAndAuthTokenRequestHeaders(),
                adapter: httpAdapter,
                proxy: false,
                httpAgent: proxyAgent,
            },
            180000
        )
            .then(this.transformResponse)
            .catch((e) => e.response.status === 401 && this.redirectToFMA());
    }

    private transformResponse(
        response: AxiosResponse<any>
    ): ProfileWithVehiclesResponse {
        const { brand } = new AppConfigurationService();
        const value = response.data.value as ProfileWithVehiclesResponse;

        if (value) {
            value.vehicles.forEach((vehicleDetail: VehicleDetail) => {
                if (vehicleDetail.make === 'F') {
                    vehicleDetail.make = 'Ford';
                } else if (vehicleDetail.make === 'L') {
                    vehicleDetail.make = 'Lincoln';
                } else if (vehicleDetail.make === 'M') {
                    vehicleDetail.make = 'Mercury';
                }
            });
            return {
                profile: value?.profile,
                vehicles: value?.vehicles.filter(
                    (vehicle) =>
                        vehicle?.make?.toLowerCase() === brand.toLowerCase()
                ),
            };
        } else {
            throw new Error('Invalid profile response');
        }
    }

    public async addVehicle(vin: string, nickname: string): Promise<any> {
        const { currentLanguageRegionCode } =
            ServiceHandler.AppConfigurationService;
        const appConfig = new AppConfigurationService();
        const dslUrl = appConfig.getAppConfiguration().dslUrl
            ? appConfig.getAppConfiguration().dslUrl
            : DSL_BASE_URL;
        const url = dslUrl + DSL_API_PATH.ADD_VEHICLE;

        return axios
            .post<any>(
                `${url}?nickname=${nickname}&locale=${currentLanguageRegionCode}`,
                null,
                {
                    headers: {
                        vin,
                        ...HttpService.getConsumerKeyAndAuthTokenRequestHeaders(),
                    },
                }
            )
            .then((response) => response.data)
            .catch((e) =>
                e.response.status === 401 ? this.redirectToFMA() : e.response
            );
    }

    public async deleteVehicle(vin: string): Promise<any> {
        const appConfig = new AppConfigurationService();
        const dslUrl = appConfig.getAppConfiguration().dslUrl
            ? appConfig.getAppConfiguration().dslUrl
            : DSL_BASE_URL;
        const url = dslUrl + DSL_API_PATH.ADD_VEHICLE;
        return axios
            .delete<any>(url, {
                headers: {
                    vin,
                    ...HttpService.getConsumerKeyAndAuthTokenRequestHeaders(),
                },
            })
            .then((response) => {
                return response.data;
            })
            .catch((e) => e.response.status === 401 && this.redirectToFMA());
    }

    public async updateVehicle(vin: string, nickName: string): Promise<any> {
        const appConfig = new AppConfigurationService();
        const dslUrl = appConfig.getAppConfiguration().dslUrl
            ? appConfig.getAppConfiguration().dslUrl
            : DSL_BASE_URL;
        const url = dslUrl + DSL_API_PATH.ADD_VEHICLE;

        return axios
            .put<any>(
                url,
                { nickName },
                {
                    headers: {
                        vin,
                        ...HttpService.getConsumerKeyAndAuthTokenRequestHeaders(),
                    },
                }
            )
            .then((response) => {
                return response.data;
            })
            .catch((e) => e.response.status === 401 && this.redirectToFMA());
    }

    public getUserPreferences(email: string | undefined): any | undefined {
        if (email) {
            const userPreferences = localStorage.getItem(
                STORAGE_KEY_USER_PREFERENCES
            );
            return userPreferences && JSON.parse(userPreferences)[email];
        }
    }
}
