import React, { useContext, useEffect, useState } from 'react';
import { EU_COUNTRIES, IMG_COUNTRIES } from '@constants';
import { ActivityIndicator } from '@common/activity-indicator/activity-indicator';
import { SearchBar, UnitsOfMeasurement } from '@sections/index';
import { useProfileData } from '@sections/account-portal/hooks/use-profile-data';
import { useAnalytics } from '@hooks/use-analytics';
import AppConfigurationService from '@services/app-configuration-service/app-configuration-service';
import ScrollUtil from '@utils/scroll-to-top-util/scroll-to-top-util';
import { AdditionalAddressResponse } from '@services/user-account-service/user-account-service';
import { CacheService } from '@services/cache-service/cache-service';
import ErrorPage from '@sections/profile-error/error-page';
import { PersonalInformation } from '@sections/personal-information/personal-information';
import ServerContext from '@contexts/serverContext';
import { useAppInformationContent } from '@sections/breadcrumbs/hook/use-breadcrumb-content';
import seoService from '@services/seo-service/seo-service';
import { MemberId } from '@sections/member-id/member-id';
import { useAccountSettingsFormsContent } from '@sections/personal-information/hooks/use-personal-information-content';
import { useReturnButtonContent } from '@sections/return-button/hook/use-return-button-content';
import { DeleteAccountButton } from '@sections/delete-account/delete-account-button';
import { useAccountSettingsContent } from './hooks/use-account-settings-content';
import { useUnitsOfMeasurementContent } from './hooks/use-units-of-measurement-content';
import { Notification } from '@sections/account-portal/components/notification-message/notification';

import './account-settings-view.scss';
import UnitsOfMeasurementService from '@services/units-of-measurement-service/units-of-measurement-service';
import HttpService from '@services/http-service/http-service';
import AuthenticationService from '@services/authentication-service/authentication-service';
import { findPathByAlias } from '@routes/routesList';
import {
    NotificationType,
    useNotificationContext,
} from '@contexts/notificationContext';
import { useNotificationBannerContent } from '@sections/notification-banner/hooks/use-notification-banner-content';
import ReturnButton from '@sections/return-button/return-button';

export interface Props {
    [key: string]: any;
}
export type Measurements = {
    distanceVal: number;
    pressureVal: string;
    temperatureVal: string;
    weightVal: string;
};

const AccountSettingsView = () => {
    useAnalytics(['ownerAccountSettings']);
    const { setNotificationContext } = useNotificationContext();
    const httpService: HttpService = HttpService;
    const authenticationService: AuthenticationService =
        new AuthenticationService();
    const [additionalAddress, setAdditionalAddress] = useState<any>({});
    const accountSettingLabels = useAccountSettingsContent();
    const accountSettingsFormsLabels = useAccountSettingsFormsContent();
    const notificationBannerContent = useNotificationBannerContent();
    const unitsOfMeasurementContent = useUnitsOfMeasurementContent();
    const unitsOfMeasurementService = new UnitsOfMeasurementService(
        httpService,
        authenticationService
    );
    const [mode, setMode] = useState<string>('view');
    const isEdit = mode === 'edit';
    const getUserProfileData = useProfileData();
    const [user, setUserData] = useState(getUserProfileData);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const appConfig = new AppConfigurationService();
    const isFord = appConfig.brand === 'ford';
    const isLincoln: boolean = appConfig.brand === 'lincoln';
    const cacheService = new CacheService();
    const currentAppConfiguration = appConfig.getAppConfiguration();
    const country = currentAppConfiguration.countryCode;
    const scrollUtil = new ScrollUtil();
    const defaultTemperatureUnit = country === 'us' ? 'F' : 'C';
    const defaultWeightUnit = country === 'us' ? 'LB' : 'KG';
    const [distance, setDistance] = useState(null);
    const [pressure, setPressure] = useState(null);
    const [temperature, setTemperature] = useState(null);
    const [weight, setWeight] = useState(null);
    const [updatedUser, setUpdatedUser] = useState(null);
    const [updatedAdditionalAddress, setUpdatedAdditionalAddress] =
        useState(null);
    const [profileServiceError, setProfileError] = useState({
        status: false,
        message: '',
    });
    const [notificationStatus, setNotificationStatus] =
        useState<NotificationType>(null);
    const returnButtonContent = useReturnButtonContent();
    const serverContext = useContext(ServerContext);
    const appInformationContent = useAppInformationContent();
    authenticationService.updateState(findPathByAlias('AccountSettingsView'));

    seoService.set(
        {
            pageTitle: appInformationContent?.accountSettingsPageTitle,
            applicationName: appInformationContent?.applicationName,
            pageDescription:
                appInformationContent?.accountSettingsPageDescription,
        },
        serverContext
    );

    const handleAdditionalAddressResponse = (response) => {
        if (
            response?.httpStatus === 200 &&
            response?.value?.profile?.additionalAddressList
        ) {
            setAdditionalAddress(
                response.value.profile
                    .additionalAddressList as AdditionalAddressResponse['value']['profile']['additionalAddressList']
            );
        } else setAdditionalAddress(null);
    };

    const handleProfileServiceError = (err: any) => {
        cacheService.evictProfileServiceCache();
        setProfileError({
            status: true,
            message: err.message,
        });
        return err;
    };

    const handleUnitOfMeasurementSuccess = (measurements: Measurements) => {
        const { distanceVal, pressureVal, temperatureVal, weightVal } =
            measurements;
        setDistance(distanceVal);
        setPressure(pressureVal);
        setTemperature(temperatureVal);
        setWeight(weightVal);
        scrollUtil.scrollPageToTop();
    };

    useEffect(() => {
        if (getUserProfileData?.message) {
            setProfileError({
                status: true,
                message: getUserProfileData.message,
            });
        } else {
            setUserData(getUserProfileData);
            setAdditionalAddress(
                getUserProfileData?.profile.additionalAddressList
            );
            if (getUserProfileData?.profile) {
                unitsOfMeasurementService.getMeasurements().then((response) => {
                    setDistance(response.uomDistance);
                    setTemperature(
                        response.uomTemperature || defaultTemperatureUnit
                    );
                    setWeight(response.uomWeight || defaultWeightUnit);
                });
                setPressure(getUserProfileData?.profile.uomPressure);
            }

            scrollUtil.scrollPageToTop();
            setProfileError({
                status: false,
                message: '',
            });
        }
    }, [getUserProfileData]);

    useEffect(() => {
        setNotificationContext(NotificationType.None, false);
    }, []);

    const isContentFragmentLoaded =
        returnButtonContent &&
        accountSettingLabels &&
        Object.keys(accountSettingLabels)?.length > 0;

    const isContentReady =
        !profileServiceError.status &&
        user &&
        user.profile &&
        isContentFragmentLoaded &&
        distance &&
        weight &&
        temperature;

    return isContentReady ? (
        <>
            <div
                className={
                    EU_COUNTRIES.includes(currentAppConfiguration.countryCode)
                        ? 'eu-view-account-settings account-view-container fmc-pb-11 fmc-px-11'
                        : 'account-view-container fmc-pb-11 fmc-px-11'
                }
                data-test-id="account-settings-view"
            >
                <ReturnButton returnButtonContent={returnButtonContent} />
                {notificationStatus && isFord ? (
                    <div
                        className="account-settings-notification-container"
                        data-testid="account-settings-notification-container"
                    >
                        <Notification
                            status={notificationStatus}
                            mainCopy={
                                notificationStatus === NotificationType.Success
                                    ? notificationBannerContent.notificationSuccessText
                                    : notificationBannerContent.notificationFailureText
                            }
                            hideBorder={true}
                            hideAfterTimeout={true}
                            onHideNotification={() => {
                                setNotificationStatus(null);
                            }}
                        />
                    </div>
                ) : null}
                {isLoading && (
                    <ActivityIndicator className="fds-activity-indicator__center" />
                )}
                <div className="user-img-desc">
                    <h1 className="fmc-type--heading5 fds-color__text--primary fds-weight--bold fds-align--center fmc-mb-3 fmc-pb-3">
                        {accountSettingLabels?.pageTitle}
                    </h1>
                    {!IMG_COUNTRIES.includes(
                        currentAppConfiguration.countryCode
                    ) &&
                        currentAppConfiguration.countryCode !== 'se' && (
                            <MemberId
                                className={
                                    'fmc-type--body1 user-member-id fmc-mb-3 fmc-pb-2 '
                                }
                                memberIdFieldLabel={
                                    accountSettingLabels?.memberId
                                }
                                memberIdNumber={user.profile.memberId.toString()}
                            />
                        )}

                    {isLincoln && (
                        <div className="fmc-type--body1 fds-color__text--gray3 fds-align--center user-edit-help-text">
                            {accountSettingLabels?.pageDescription}
                        </div>
                    )}
                </div>
                <div className="fds-layout-grid">
                    <div
                        className={`fds-layout-grid__inner ${
                            isLincoln ? '' : 'reskin-inner-grid'
                        }`}
                    >
                        <div
                            className={`fds-layout-grid__cell--span-4-xs ${
                                isLincoln
                                    ? 'fds-layout-grid__cell--span-8-sm fds-layout-grid__cell--span-6'
                                    : 'fds-layout-grid__cell--span-12'
                            }`}
                        >
                            <PersonalInformation
                                appConfig={appConfig}
                                handleCancel={setMode}
                                profileServiceError={profileServiceError}
                                setProfileError={setProfileError}
                                countryCode={country}
                                user={updatedUser || user.profile}
                                accountSettingsLabels={accountSettingLabels}
                                accountSettingsFormsLabels={
                                    accountSettingsFormsLabels
                                }
                                isEdit={isEdit}
                                setMode={setMode}
                                additionalAddress={
                                    updatedAdditionalAddress ||
                                    additionalAddress
                                }
                                updateSuccess={(
                                    newUserData,
                                    newAdditionalAddress
                                ) => {
                                    setUpdatedUser(newUserData);
                                    setUpdatedAdditionalAddress(
                                        newAdditionalAddress
                                    );
                                }}
                                setAdditionalAddress={setAdditionalAddress}
                                setUserData={setUserData}
                                handleAdditionalAddressResponse={
                                    handleAdditionalAddressResponse
                                }
                                setIsLoading={setIsLoading}
                                handleProfileServiceError={
                                    handleProfileServiceError
                                }
                                userIdentity={user?.profile?.userIdentity}
                                setNotificationStatus={setNotificationStatus}
                            />
                        </div>
                        <div
                            className={`fds-layout-grid__cell--span-4-xs ${
                                isLincoln
                                    ? 'fds-layout-grid__cell--span-8-sm fds-layout-grid__cell--span-6'
                                    : 'fds-layout-grid__cell--span-12'
                            }`}
                        >
                            {!accountSettingLabels.hideUnitsOfMeasurement && (
                                <UnitsOfMeasurement
                                    appConfig={appConfig}
                                    unitsOfMeasurementContent={
                                        unitsOfMeasurementContent
                                    }
                                    uomDistance={distance}
                                    uomPressure={pressure}
                                    uomTemperature={temperature}
                                    uomWeight={weight}
                                    updateSuccess={(
                                        measurements: Measurements
                                    ) => {
                                        handleUnitOfMeasurementSuccess(
                                            measurements
                                        );
                                    }}
                                    setNotificationStatus={
                                        setNotificationStatus
                                    }
                                />
                            )}
                            {accountSettingLabels.deleteMyAccountLabel && (
                                <DeleteAccountButton
                                    deleteMyAccountLabel={
                                        accountSettingLabels?.deleteMyAccountLabel
                                    }
                                />
                            )}
                        </div>
                    </div>
                </div>
            </div>
            <hr
                className="hr-line mB0 personal-settings-divider"
                aria-hidden={true}
            />
            <SearchBar />
            <hr
                className="hr-line mB0 personal-settings-divider"
                aria-hidden={true}
            />
        </>
    ) : profileServiceError.status ? (
        <ErrorPage />
    ) : (
        <ActivityIndicator className="full-height" />
    );
};

export default AccountSettingsView;
