import React, { useEffect, useState } from 'react';
import './order-details-item.scss';
import { OrderLineDetails } from '@services/oms-service/oms-service';
import { OrderDetailsContent } from '@views/order-details-view/hooks/use-order-details-content';
import { SecondaryButton } from '@common/index';
import { INSTALL_LINES_ID, LinkTargetValues } from '@constants';
import { OrderStatusContent } from '@views/order-details-view/hooks/use-nvc-order-status-content';
import {
    NVC_GREEN_STATUSES,
    NVC_ORDER_STATUS_MAP,
    NVC_RED_STATUSES,
    OrderStatusOption,
} from '@views/order-details-view/nvc-status-map';

export interface OrderDetailsItemProps {
    itemData: OrderLineDetails;
    orderDetailsContent: OrderDetailsContent;
    orderStatusContent: OrderStatusContent;
}

const installLinesIcon = './icons/installation-item--icon.svg';

const OrderDetailsItem = (props: OrderDetailsItemProps) => {
    const formattedProductName: string = props.itemData.item.itemShortDesc
        .toLowerCase()
        .split(' ')
        .join('-');
    const productPageUrl = props.itemData.item.itemShortDesc
        ? `https://www.ford.com/product/${formattedProductName}?partNumber=${props.itemData.item.itemId}`
        : 'https://accessories.ford.com';
    const red = '#f9e8ea';
    const green = '#e5f3e6';
    const gray = '#e6e8ea';
    const deliveryMethod: string = props.itemData.deliveryMethod;
    const orderStatus: string = props.itemData.status.toLowerCase();
    const [isInstallIcon, setIsInstallIcon] = useState<boolean>(false);
    const [imageSrc, setImageSrc] = useState<string>(
        `https://accessories.ford.com/s3/${props.itemData.item.itemId}_0.jpg`
    );

    const chooseStatusBackgroundColor = (status: string): string => {
        if (NVC_GREEN_STATUSES.includes(status)) {
            return green;
        } else if (NVC_RED_STATUSES.includes(status)) {
            return red;
        }

        return gray;
    };

    const generateImageSrc = (): void => {
        if (props.itemData.item.itemId === INSTALL_LINES_ID) {
            setIsInstallIcon(true);
            setImageSrc(installLinesIcon);
        }
    };

    const determineDisplayStatus = (status: string): string => {
        let displayStatus = '';
        let deliveryMethodTransformed: string;

        if (deliveryMethod === 'SHP') {
            deliveryMethodTransformed = 'shipToHome';
        } else if (deliveryMethod === 'PICK') {
            deliveryMethodTransformed = 'pickUpOrder';
        } else if (props.itemData.item.itemId === INSTALL_LINES_ID) {
            deliveryMethodTransformed = 'installLines';
        }

        NVC_ORDER_STATUS_MAP[deliveryMethodTransformed].forEach(
            (statusOption: OrderStatusOption) => {
                if (statusOption.options.includes(status)) {
                    displayStatus = props.orderStatusContent[statusOption.name];
                }
            }
        );

        return displayStatus;
    };

    useEffect(() => {
        generateImageSrc();
    }, []);

    return (
        <div
            className="order-details-item__container"
            data-testid="order-details-item__container"
        >
            <div
                className={`item__status`}
                data-testid="item-status"
                style={{
                    backgroundColor: chooseStatusBackgroundColor(orderStatus),
                }}
            >
                <p>{determineDisplayStatus(orderStatus)}</p>
            </div>

            {imageSrc && (
                <img
                    src={imageSrc}
                    className={`item__image ${
                        isInstallIcon ? 'install-icon-sizing' : ''
                    }`}
                    alt="item image"
                    data-testid="item-image"
                />
            )}

            <div className="item__info-container">
                <p className="item__title" data-testid="item-title">
                    {props.itemData.item.itemShortDesc.substring(0, 50) || '-'}
                </p>
                <p className="item__id" data-testid="item-id">
                    {props.itemData.item.itemId}
                </p>
                <p className="item__price" data-testid="item-price">
                    ${props.itemData.lineOverallTotals.unitPrice}
                </p>
                <p className="item__quantity" data-testid="item-quantity">
                    {props.orderDetailsContent.quantity}{' '}
                    {Math.floor(Number(props.itemData.originalOrderedQty))}
                </p>
            </div>
            <div className="item__buttons-container">
                {!isInstallIcon && (
                    <SecondaryButton
                        className="buy-again-button"
                        data-testid="buy-again-button"
                        linkTarget={LinkTargetValues.BLANK}
                        link={productPageUrl}
                        internal={false}
                    >
                        {props.orderDetailsContent.buyAgain}
                    </SecondaryButton>
                )}
            </div>
        </div>
    );
};

export default OrderDetailsItem;
