import React, { useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { ActivityIndicator } from '@common/activity-indicator/activity-indicator';
import { useProfileData } from '@sections/account-portal/hooks/use-profile-data';
import AppConfigurationService from '@services/app-configuration-service/app-configuration-service';
import AuthenticationService from '@services/authentication-service/authentication-service';
import ConnectedServicesService from '@services/connected-services-service/connected-services-service';
import ScrollUtil from '@utils/scroll-to-top-util/scroll-to-top-util';
import seoService from '@services/seo-service/seo-service';
import { useAppInformationContent } from '@sections/breadcrumbs/hook/use-breadcrumb-content';
import ServerContext from '@contexts/serverContext';
import { useReturnButtonContent } from '@sections/return-button/hook/use-return-button-content';
import { VehicleDetail } from '@models/profile-with-vehicles';
import './connected-services-view.scss';
import { useConnectedServicesErrorContent } from '@views/connected-services-view/hook/use-connected-services-error-content';
import ConnectedServicesErrorComponent from '@views/connected-services-view/connected-services-error-content/connected-services-error-component';
import { findPathByAlias } from '@routes/routesList';
import { buildCampaignIdQueryParameter } from '@utils/router-util/router-util';
import {
    NotificationType,
    useNotificationContext,
} from '@contexts/notificationContext';
import ReturnButton from '@sections/return-button/return-button';

interface SubscriptionData {
    environment?: string;
    renderElementSelector: string;
    ssoToken: string;
    refreshToken: string;
    applicationId: string;
    countryCode: string;
    language: string;
    vehicle?: {
        vin: string;
        make: string;
        modelYear: string;
        modelName: string;
        nickName: string;
        imageURL: string;
    };
    sspid?: string;
}

function renderSubscription(vehicle: VehicleDetail, sspid) {
    const authenticationService = new AuthenticationService();
    const appConfiguration = new AppConfigurationService();
    const connectedServicesService = new ConnectedServicesService();

    authenticationService.updateState(
        `${findPathByAlias(
            'ConnectedServicesView'
        )}${buildCampaignIdQueryParameter()}`
    );

    if (vehicle) {
        const subscriptionData: SubscriptionData = {
            environment: appConfiguration.getAppConfiguration().subscriptionEnv,
            renderElementSelector: `#subscriptions-app-root`,
            ssoToken: authenticationService.getCatBundle().access_token,
            refreshToken: authenticationService.getCatBundle().refresh_token,
            applicationId: appConfiguration.getAppConfiguration().appId,
            countryCode: appConfiguration.current3LetterCountryCapitalizedCode,
            language: appConfiguration.currentLanguageRegionCapitalizedCode,
            vehicle: {
                vin: vehicle.vin,
                make: vehicle.make,
                modelYear: vehicle.modelYear,
                modelName: vehicle.modelName,
                nickName: vehicle.nickName,
                imageURL:
                    'https://cdn.ford.com/images/mgl/kJPkQ/s1/ford-mustang-mach-e.jpg',
            },
        };
        connectedServicesService.connectedServices(subscriptionData);
    } else if (sspid) {
        const sspidData = {
            environment: appConfiguration.getAppConfiguration().subscriptionEnv,
            renderElementSelector: `#subscriptions-app-root`,
            ssoToken: authenticationService.getCatBundle().access_token,
            refreshToken: authenticationService.getCatBundle().refresh_token,
            applicationId: appConfiguration.getAppConfiguration().appId,
            countryCode: appConfiguration.current3LetterCountryCapitalizedCode,
            language: appConfiguration.currentLanguageRegionCapitalizedCode,
            sspid: sspid,
        };
        connectedServicesService.connectedServices(sspidData);
    }
}

export const ConnectedServicesView = () => {
    const vin =
        new URLSearchParams(useLocation().search).get('vin') || undefined;
    const sspid =
        new URLSearchParams(useLocation().search).get('sspid') || undefined;
    const { setNotificationContext } = useNotificationContext();
    const authenticationService = new AuthenticationService();
    const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);
    const [scriptLoaded, setLoaded] = useState(false);
    const returnButtonContent = useReturnButtonContent();
    const connectedServicesErrorContent = useConnectedServicesErrorContent();
    const scrollUtil = new ScrollUtil();
    const profileData = useProfileData();
    const appInformationContent = useAppInformationContent();
    const serverContext = useContext(ServerContext);
    const [vinNotFound, setVinNotFound] = useState<boolean>(false);
    const [vehicle, setVehicle] = useState<VehicleDetail>(null);
    const showConnectedServices: boolean = scriptLoaded && !vinNotFound;
    const showSpinner: boolean = !vinNotFound && !scriptLoaded;
    const showErrorPage: boolean =
        !scriptLoaded && connectedServicesErrorContent && vinNotFound;
    const showReturnButton: boolean = returnButtonContent && !vinNotFound;

    seoService.set(
        {
            pageTitle: appInformationContent?.connectedServicesPageTitle,
            applicationName: appInformationContent?.applicationName,
            pageDescription:
                appInformationContent?.connectedServicesPageDescription,
        },
        serverContext
    );

    useEffect(() => {
        authenticationService
            .onIsAuthenticated()
            .then((isAuthenticated: boolean) => {
                if (isAuthenticated) {
                    setIsAuthenticated(true);
                } else {
                    authenticationService.login();
                }
            });
        scrollUtil.scrollPageToTop();
    }, [authenticationService]);

    useEffect(() => {
        if (isAuthenticated && profileData) {
            const hasVinInAccount = profileData.vehicles.find(
                (vehicle) => vehicle.vin === vin
            );

            if (hasVinInAccount) {
                setVehicle(hasVinInAccount);
            } else {
                setVinNotFound(true);
            }
        }
    }, [profileData, isAuthenticated]);

    useEffect(() => {
        if (vehicle) {
            setLoaded(true);
            renderSubscription(vehicle, sspid);
        }
    }, [vehicle]);

    useEffect(() => {
        setNotificationContext(NotificationType.None, false);
    }, []);

    return (
        <>
            <div
                className="connected-services-view-container"
                data-testid="connected-services-view"
            >
                {showReturnButton && (
                    <div className="return-button" data-test-id="return-button">
                        <div className="secondary-button">
                            <ReturnButton
                                returnButtonContent={returnButtonContent}
                            />
                        </div>
                    </div>
                )}

                {showConnectedServices && <div id="subscriptions-app-root" />}

                {showErrorPage && (
                    <ConnectedServicesErrorComponent
                        connectedServicesErrorContent={
                            connectedServicesErrorContent
                        }
                    />
                )}

                {showSpinner && <ActivityIndicator className="full-height" />}
            </div>
        </>
    );
};
