import React, { useEffect, useState } from 'react';
import { OrderHistoryContent } from '../hooks/use-order-history-content';
import AppConfigurationService from '@/services/app-configuration-service/app-configuration-service';
import {
    OmsOrderHistoryResponse,
    OrderHistoryOrder,
} from '@services/oms-service/oms-service';
import { nvcPastOrderStatuses } from '@constants';
import OrderHistoryItem from '@views/order-history-view/components/order-history-item';
import { ActivityIndicator } from '@common/activity-indicator/activity-indicator';
import TertiaryButton from '@/components/common/tertiary-button/tertiary-button';

interface Props {
    orderHistoryContent: OrderHistoryContent;
    orderHistoryData: OmsOrderHistoryResponse;
    ordersFinishedLoading: boolean;
    isShowMore: boolean;
    onShowMore: () => void;
}

const className = 'order-history-component';

const OrderHistoryComponent = (props: Props) => {
    const {
        orderHistoryContent,
        orderHistoryData,
        ordersFinishedLoading,
        isShowMore,
        onShowMore,
    } = props;
    const appConfig: AppConfigurationService = new AppConfigurationService();
    const activeOrders: OrderHistoryOrder[] = [];
    const pastOrders: OrderHistoryOrder[] = [];
    const [displayedOrderCount, setDisplayedOrderCount] = useState(0);

    orderHistoryData?.orders.forEach((order) => {
        if (nvcPastOrderStatuses.includes(order.Status.toLowerCase())) {
            pastOrders.push(order);
        } else {
            activeOrders.push(order);
        }
    });

    useEffect(() => {
        const totalOrders = activeOrders.length + pastOrders.length;
        setDisplayedOrderCount(totalOrders);
    }, [isShowMore, activeOrders, pastOrders]);

    const showMoreOrders = () => {
        onShowMore();
    };

    return (
        <>
            <div
                className={`${className}-container`}
                data-test-id={`${className}-container`}
            >
                <h1
                    className={`${className}-title`}
                    data-testid={`${className}-title`}
                >
                    {orderHistoryContent.pageTitle}
                </h1>

                {ordersFinishedLoading && (
                    <p
                        className={`${className}-subtitle`}
                        data-testid={`${className}-subtitle`}
                    >
                        {orderHistoryContent.subtitleText}{' '}
                        {orderHistoryData?.totalOrders
                            ? orderHistoryData.totalOrders
                            : '0'}{' '}
                        {orderHistoryContent.ordersName}
                    </p>
                )}

                {orderHistoryData && (
                    <>
                        {activeOrders.map((order, index) => (
                            <OrderHistoryItem
                                key={order.OrderNo}
                                order={order}
                                index={index}
                                orderHistoryContent={orderHistoryContent}
                                appConfig={appConfig}
                                className={className}
                                isActive={true}
                                totalOrders={orderHistoryData.totalOrders}
                                alwaysIncludeBottomBorder={!pastOrders.length}
                            />
                        ))}

                        {activeOrders.length && pastOrders.length ? (
                            <hr
                                className="past-orders-separator"
                                data-testid="past-orders-separator"
                                aria-hidden={true}
                            />
                        ) : null}
                        {pastOrders.length ? (
                            <h2
                                className="past-orders-header"
                                data-testid="past-orders-header"
                            >
                                {orderHistoryContent.pastOrdersHeader}
                            </h2>
                        ) : null}

                        {pastOrders.map((order, index) => (
                            <OrderHistoryItem
                                key={order.OrderNo}
                                order={order}
                                index={index}
                                orderHistoryContent={orderHistoryContent}
                                appConfig={appConfig}
                                className={className}
                                isActive={false}
                                totalOrders={orderHistoryData.totalOrders}
                                alwaysIncludeBottomBorder={true}
                            />
                        ))}
                        <div
                            className={
                                isShowMore
                                    ? `${className}-footer-container-show-more`
                                    : `${className}-footer-container`
                            }
                        >
                            {isShowMore && (
                                <TertiaryButton
                                    labelText={
                                        orderHistoryContent.showMoreOrdersButtonLabelText
                                    }
                                    ariaLabel={
                                        orderHistoryContent.showMoreOrdersButtonAriaLabel
                                    }
                                    dataTestId={`${className}-show-more`}
                                    internal={true}
                                    plusSign={true}
                                    onClick={showMoreOrders}
                                ></TertiaryButton>
                            )}
                            <div
                                className={`${className}-pagination-container`}
                            >
                                <p
                                    className={`${className}-pagination-label`}
                                    data-testid={`${className}-pagination-label`}
                                >
                                    {orderHistoryContent.paginationLabelText}
                                    {' 1-'}
                                    {displayedOrderCount}{' '}
                                    {orderHistoryContent.paginationSeparator}{' '}
                                    {orderHistoryData.totalOrders}
                                </p>
                            </div>
                        </div>
                    </>
                )}
                {!ordersFinishedLoading ? (
                    <ActivityIndicator className={'full-height'} />
                ) : null}
            </div>
        </>
    );
};

export default OrderHistoryComponent;
