import { DSL_API_PATH, DSL_BASE_URL } from '@constants';
import HttpService from '../http-service/http-service';
import AppConfigurationService from '../app-configuration-service/app-configuration-service';
import AuthenticationService from '../authentication-service/authentication-service';

interface Response {
    httpStatus: string;
    status: string;
    requestStatus: string;
    error: any;
    lastRequested: string;
    version: string;
    statusCode: any;
    message: any;
}

interface PrivacyPreferences {
    preferenceType: string;
    preferenceCode: number;
}
export default class EuPreferenceService {
    private httpService: any;
    private appConfig = new AppConfigurationService();
    private dslUrl = this.appConfig.getAppConfiguration().dslUrl
        ? this.appConfig.getAppConfiguration().dslUrl
        : DSL_BASE_URL;
    private url: string = this.dslUrl + DSL_API_PATH.EU_PREFERENCES;
    public config: any;

    public authenticationService = new AuthenticationService();
    public threeLetterCountryCode = this.appConfig.get3LetterCountryCode();

    constructor(httpService: HttpService) {
        this.httpService = httpService;

        this.config = {
            headers:
                this.httpService.getConsumerKeyAndAuthTokenRequestHeaders(),
        };
    }

    public async updatePreferences(
        privacyPreferences: PrivacyPreferences[]
    ): Promise<Response> {
        const requestData = {
            countryCode: this.threeLetterCountryCode.toUpperCase(),
            privacyPreferences,
        };

        return await this.httpService
            .put(this.url, requestData, this.config)
            .then((response) => {
                return response.data;
            })
            .catch((e) => {
                if (e?.response?.status === 401) {
                    console.error(`User Timeout`);
                    this.authenticationService.login();
                } else {
                    return e?.message;
                }
            });
    }
}
