import React, { ChangeEvent, useEffect, useState } from 'react';
import './guest-order-view.scss';
import { InputField } from '@common/form-fields/form-fields';
import { PrimaryButton } from '@common/index';
import { Link, useNavigate } from 'react-router-dom';
import { useGuestLoginContent } from '@views/guest-order-view/hooks/use-guest-order-view-content';
import { ActivityIndicator } from '@common/activity-indicator/activity-indicator';
import { SearchBar } from '@/components/sections';
import { InputValidationParams } from '@views/vehicle-order-tracking-view/components/vehicle-order-tracking-component';
import {
    guestOrderViewErrors,
    GuestOrderViewErrors,
} from '@views/guest-order-view/components/guest-order-view-errors';
import { findPathByAlias } from '@routes/routesList';
import { LinkTargetValues } from '@constants';

const GuestOrderView = () => {
    const navigate = useNavigate();
    const guestLoginContent = useGuestLoginContent();
    const [errors, setErrors] =
        useState<GuestOrderViewErrors>(guestOrderViewErrors);
    const [emailAddress, setEmailAddress] = useState<string>('');
    const [orderNumber, setOrderNumber] = useState<string>('');
    const [orderNumberInputDisabled, setOrderNumberInputDisabled] =
        useState<boolean>(false);
    const submitButtonIsDisabled: boolean =
        !orderNumber ||
        !emailAddress ||
        errors['orderNumber']?.status ||
        errors['emailAddress']?.status;

    const prePopulateOrderNumber = () => {
        const orderNumberFromStorage = sessionStorage.getItem(
            'nvcOrderDetailsOrderNumber'
        );

        if (orderNumberFromStorage) {
            setOrderNumber(orderNumberFromStorage);
            setOrderNumberInputDisabled(true);
            sessionStorage.removeItem('nvcOrderDetailsOrderNumber');
        }
    };

    const handleSubmit = (): void => {
        navigate(
            findPathByAlias('OrderDetailsViewWithoutOrderNumber', orderNumber),
            {
                state: {
                    emailAddress: emailAddress,
                },
            }
        );
    };

    const isValidInputForField = ({
        value,
        field,
        pattern,
        message,
    }: InputValidationParams): boolean => {
        const regex = new RegExp(pattern);

        if (!regex.test(value)) {
            setErrors({
                [field]: {
                    status: true,
                    message: message,
                },
            });
        } else if (regex.test(value) && errors[field]?.status) {
            setErrors({
                [field]: {
                    status: false,
                    message: '',
                },
            });
        }
        return regex.test(value);
    };

    useEffect(() => {
        prePopulateOrderNumber();
    }, []);

    return (
        <>
            {guestLoginContent ? (
                <div
                    className="guest-order-view__container"
                    data-testid="guest-order-view__container"
                >
                    <div className="guest-order-view__content-container">
                        <h1 className="header-text" data-testid="header-text">
                            {guestLoginContent.headerText}
                        </h1>

                        <p
                            className="subheader-text"
                            data-testid="subheader-text"
                        >
                            {guestLoginContent.subheaderText}
                        </p>

                        <div className="input-container">
                            <InputField
                                id="order-number"
                                dataTestId="order-number-input"
                                value={orderNumber}
                                handleChange={(
                                    e: ChangeEvent<HTMLInputElement>
                                ) => {
                                    setOrderNumber(e.target.value);

                                    isValidInputForField({
                                        value: e.target.value,
                                        field: 'orderNumber',
                                        pattern:
                                            guestLoginContent.orderNumberInputValidationPattern,
                                        message:
                                            guestLoginContent.orderNumberInputErrorMessageInvalid,
                                    });
                                }}
                                name="orderNumber"
                                label={
                                    guestLoginContent.orderNumberInputLabelText
                                }
                                aria-label={
                                    guestLoginContent.orderNumberInputAriaLabel
                                }
                                error={errors?.orderNumber}
                                showLabel={false}
                                disabled={orderNumberInputDisabled}
                            />
                            <InputField
                                id="email-address"
                                dataTestId="email-address-input"
                                value={emailAddress}
                                handleChange={(
                                    e: ChangeEvent<HTMLInputElement>
                                ) => {
                                    setEmailAddress(e.target.value);

                                    isValidInputForField({
                                        value: e.target.value,
                                        field: 'emailAddress',
                                        pattern:
                                            guestLoginContent.emailAddressInputValidationPattern,
                                        message:
                                            guestLoginContent.emailAddressInputErrorMessageInvalid,
                                    });
                                }}
                                name="emailAddress"
                                label={
                                    guestLoginContent.emailAddressInputLabelText
                                }
                                aria-label={
                                    guestLoginContent.emailAddressInputAriaLabel
                                }
                                validationPattern={
                                    guestLoginContent.emailAddressInputValidationPattern
                                }
                                validationRules={[
                                    'validateNonEmpty',
                                    'validateMatchesPattern',
                                ]}
                                error={errors?.emailAddress}
                                showLabel={false}
                            />
                        </div>

                        <PrimaryButton
                            id="submit-details-button"
                            tabIndex={0}
                            testId="submit-details-button"
                            ariaLabel={
                                guestLoginContent.submitDetailsButtonAriaLabel
                            }
                            onClick={handleSubmit}
                            disabled={submitButtonIsDisabled}
                        >
                            {guestLoginContent.submitDetailsButtonLabelText}
                        </PrimaryButton>

                        <div
                            className="help-text"
                            data-testid="help-text-section"
                        >
                            <p className="help-text__prefix">
                                {guestLoginContent.cantFindOrderNumberText}
                            </p>
                            <Link
                                to={
                                    guestLoginContent.contactCustomerServiceLinkHref
                                }
                                aria-label={
                                    guestLoginContent.contactCustomerServiceLinkAriaLabel
                                }
                                className="contact-customer-service-link"
                                target={LinkTargetValues.BLANK}
                            >
                                {
                                    guestLoginContent.contactCustomerServiceLinkText
                                }
                            </Link>
                        </div>
                    </div>
                </div>
            ) : (
                <ActivityIndicator />
            )}
            <hr className="guest-order-view__divider" />
            <SearchBar />
        </>
    );
};

export default GuestOrderView;
