import React, { ReactNode, useEffect, useState } from 'react';
import AppConfigurationService from '@services/app-configuration-service/app-configuration-service';
import { ActivityIndicator } from '@common/activity-indicator/activity-indicator';
import SecondaryButton from '@common/secondary-button/secondary-button';
import { VehicleCarouselContent } from '../vehicle-tabs/hooks/use-vehicle-carousel-content';
import { useVehicleImage } from '../../hooks/use-vehicle-image';
import './card.scss';
import { CustomerBooking } from '@services/gcct-service/gcct-service';
import { useAnalytics } from '@hooks/use-analytics';
import { VehicleAttributes } from '@models/vehicle-attributes';
import { CVOT_AUTHENTICATED_ORDER_STORAGE_KEY } from '@constants';
import { findPathByAlias } from '@routes/routesList';

interface Props {
    data: any;
    identification?: number;
    cardContentFragments?: VehicleCarouselContent;
    cardTitle: string;
    isLoading: boolean;
    children?: ReactNode;
    cardClass?: string;
    orderStatusAlertType?: string;
    orderStatusAlertText?: string;
    hasCvotOrderNumber?: boolean;
    activeOrderCardNumberLabel?: string;
    secondaryBtnLink: string;
    displayCTA: boolean;
    ctaLabel: string;
    onCTAClick: () => void;
    booking?: CustomerBooking;
    isExploreYourFord?: boolean;
    hasChecklist?: boolean;
    isNonConnectedVehicle?: boolean;
    odometerValue?: number;
    uomDistanceUnit?: string;
}

export const Card = ({
    data,
    cardContentFragments,
    identification,
    cardTitle,
    activeOrderCardNumberLabel,
    children,
    displayCTA,
    ctaLabel,
    isLoading,
    onCTAClick,
    secondaryBtnLink,
    orderStatusAlertText = '',
    orderStatusAlertType = '',
    cardClass = '',
    booking,
    isExploreYourFord,
    odometerValue,
    uomDistanceUnit,
    hasCvotOrderNumber,
}: Props) => {
    const appConfig: AppConfigurationService = new AppConfigurationService();
    const [fireAnalytics] = useAnalytics();
    const isOrderCard = !!data.status;
    const hasStatusAndVin: boolean = data.status && data.vin;
    const isCVOTOrder: boolean = hasStatusAndVin && data.id.length === 4;
    const imageUrl = data.imageUrl || null;
    const showGuidedVirtualTour: boolean =
        !!cardContentFragments.gvtMainCopy && !!booking;
    const gvtCtaHref: string = booking
        ? `${cardContentFragments.gvtCTAHref + booking.bookingId}`
        : '';
    const cardDefaultImage =
        process.env.PUBLIC_URL + '/vehicle-images/default-vehicle.png';
    const generateVinForVehicleImageLookup = (): VehicleAttributes => {
        return isOrderCard && data.vin
            ? { vin: data.vin, year: data.year }
            : data.vehicleAttributes;
    };
    const cardImage = useVehicleImage(
        generateVinForVehicleImageLookup(),
        imageUrl,
        'undefined',
        appConfig.current3LetterCountryCapitalizedCode
    );
    const [imageIsAngleFour, setImageIsAngleFour] = useState<boolean>(false);
    const [cardImageIsPlaceholder, setCardImageIsPlaceholder] =
        useState<boolean>(true);
    const [odometerFullText, setOdometerFullText] = useState<string>(null);
    const gvtClass: string = booking ? 'gvt-image-alignment' : '';
    const cvotClass: string = isCVOTOrder ? 'four-digit-order-number' : '';
    const eyfClass: string = isExploreYourFord ? 'eyf-box-size' : '';
    const standardVehicleCardClass: string = data.description
        ? 'standard-vehicle-card'
        : '';

    const generateDynamicTitle = (): string => {
        return isOrderCard && data.model ? data.model : data.title;
    };

    const generateYmmString = (): string => {
        return data.model || data.title
            ? data.year + ' ' + (generateDynamicTitle() || '')
            : cardContentFragments.eComVehicleCardModelUnavailable;
    };

    const generateModelUnavailableClass = (): string => {
        return !data.model && !data.title ? 'model-unavailable' : '';
    };

    const generateMenuLinkClass = (): string => {
        return cardContentFragments.menuLink1LabelText &&
            cardContentFragments.menuLink1CtaHref
            ? 'has-menu-links'
            : 'connected-vehicle-card';
    };

    useEffect(() => {
        if (cardImage && cardImage.includes('&angle=4')) {
            setImageIsAngleFour(true);
            setCardImageIsPlaceholder(false);
        }
    }, [cardImage]);

    useEffect(() => {
        if (uomDistanceUnit && odometerValue) {
            setOdometerFullText(
                `${odometerValue.toLocaleString()} ${uomDistanceUnit}`
            );
        }
    }, [uomDistanceUnit, odometerValue]);

    return (
        <>
            {isLoading && (
                <ActivityIndicator
                    className={'fds-activity-indicator__center'}
                />
            )}
            <div
                className={`fmc-cards__card fds-layout-grid__cell ${cardClass} ${gvtClass} ${generateMenuLinkClass()}`}
                data-testid="card-id"
            >
                <div className="fmc-cards__media-area fds-display--flex fds-layout-grid__inner--span-12 fds-flex__items--center fds-color__bg--gray1">
                    <img
                        id={`vehicle-image-${identification}`}
                        className={`fmc-cards__image ${
                            cardImageIsPlaceholder
                                ? 'placeholder-image'
                                : 'ordered-vehicle-image'
                        } ${imageIsAngleFour ? 'reset-transform' : ''}`}
                        src={cardImage || cardDefaultImage}
                        alt="a placeholder"
                        data-testid="card-image"
                        crossOrigin="anonymous"
                    />

                    {showGuidedVirtualTour && (
                        <div className="gvt-banner" data-testid="gvt-banner">
                            <SecondaryButton
                                className="fmc-text-button"
                                aria-label={
                                    cardContentFragments.gvtCTAAriaLabel
                                }
                                link={gvtCtaHref}
                                linkTarget="_blank"
                                dataDark={true}
                                onClick={() => {
                                    fireAnalytics('gvtOnclickEvent');
                                }}
                            >
                                <img
                                    alt=""
                                    src={
                                        process.env.REACT_APP_AEM_BASE_URL +
                                        cardContentFragments.gvtIconPath
                                    }
                                />
                                <span className="gvt-banner__main-copy fmc-type--content2">
                                    {cardContentFragments.gvtMainCopy}
                                </span>
                                <span className="fds-icon fds-icon--offset-right fds-font--ford-icons__chevron-right"></span>
                            </SecondaryButton>
                        </div>
                    )}
                </div>
                <div className="fmc-cards__content fds-layout-grid__inner--span-12">
                    <h2 className="fmc-cards__title fmc-type--heading6 fds-color__text--primary fds-align--center fds-layout-grid__cell">
                        {cardTitle}
                    </h2>

                    <h3
                        className={`fmc-cards__subtitle ${generateModelUnavailableClass()} fmc-type--body1 fds-color__text--primary fds-align--center fds-layout-grid__cell`}
                    >
                        {generateYmmString()}
                    </h3>

                    <div
                        className={`order-number-and-vin-box ${
                            hasStatusAndVin ? 'has-both' : ''
                        } ${cvotClass} ${standardVehicleCardClass}`}
                    >
                        {data.status ? (
                            <p
                                className="fmc-cards__body fds-layout-grid__cell fmc-type--content3 fds-color__text--gray3 fds-align--center"
                                data-testid="order-number"
                            >
                                {`${activeOrderCardNumberLabel}: ${data.id}`}
                            </p>
                        ) : null}

                        {!data.status ? (
                            <>
                                <p
                                    className="fmc-cards__body fds-layout-grid__cell fmc-type--content3 fds-color__text--gray3 fds-align--center"
                                    data-testid="standard-card-odometer"
                                >
                                    {odometerFullText}
                                </p>
                                <p
                                    className="fmc-cards__body fds-layout-grid__cell fmc-type--content3 fds-color__text--gray3 fds-align--center"
                                    data-testid="standard-card-vin"
                                >
                                    {`${cardContentFragments?.vinText}: ${data.description}`}
                                </p>
                            </>
                        ) : null}

                        {hasStatusAndVin && (
                            <>
                                <p
                                    className="fmc-cards__body fds-layout-grid__cell fmc-type--content3 fds-color__text--gray3 fds-align--center fmc-cards__body--2"
                                    data-testid="order-card-vin"
                                >
                                    {`${cardContentFragments?.vinText}: ${data.vin}`}
                                </p>
                            </>
                        )}
                    </div>

                    {data.status && (
                        <div
                            data-testid="ordered-vehicle-status-box"
                            className={`fmc-cards__order-status--box ${eyfClass} ${orderStatusAlertType} fds-align--center`}
                        >
                            <p className="fmc-cards__order-status--box-label">
                                {cardContentFragments?.eComOrderStatusBoxText}
                            </p>
                            <p className="fmc-cards__order-status--value">
                                {orderStatusAlertText}
                            </p>
                        </div>
                    )}

                    {children}

                    <div className="fmc-cards__footer fds-layout-grid__cell fds-flex--center">
                        {displayCTA && (
                            <>
                                {hasCvotOrderNumber ? (
                                    <SecondaryButton
                                        id={`view-vehicle-btn-${identification}`}
                                        className="fmc-text-button fmc-text-button--chevron-right"
                                        link={findPathByAlias(
                                            'VehicleOrderTrackingStatusView'
                                        )}
                                        internal={true}
                                        dataTestId="order-card-cvot-cta"
                                        onClick={() => {
                                            localStorage.setItem(
                                                CVOT_AUTHENTICATED_ORDER_STORAGE_KEY,
                                                JSON.stringify({
                                                    customerOrderNumber:
                                                        data.id,
                                                    vin: data.vin,
                                                })
                                            );
                                        }}
                                        aria-label={data.ctaAriaLabel}
                                    >
                                        {ctaLabel}
                                    </SecondaryButton>
                                ) : (
                                    <SecondaryButton
                                        id={`view-vehicle-btn-${identification}`}
                                        className="fmc-text-button fmc-text-button--chevron-right"
                                        link={secondaryBtnLink}
                                        onClick={onCTAClick}
                                        linkTarget={data.ctaTarget || '_blank'}
                                        aria-label={data.ctaAriaLabel}
                                    >
                                        {ctaLabel}
                                    </SecondaryButton>
                                )}
                            </>
                        )}
                    </div>
                </div>
            </div>
        </>
    );
};
