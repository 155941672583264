import React, { useEffect, useState } from 'react';
import './create-return-view.scss';
import { Breadcrumbs } from '@/components/sections/breadcrumbs/breadcrumbs';
import AppConfigurationService from '@/services/app-configuration-service/app-configuration-service';
import { SearchBar } from '@/components/sections';
import CreateReturnComponent from './components/create-return-component';
import ScrollUtil from '@/components/utils/scroll-to-top-util/scroll-to-top-util';
import AuthenticationService from '@/services/authentication-service/authentication-service';
import { CacheService } from '@/services/cache-service/cache-service';
import ProfileService from '@/services/profile-service/__mocks__/profile-service';
import ErrorPage from '@/components/sections/profile-error/error-page';
import { ActivityIndicator } from '@/components/common/activity-indicator/activity-indicator';
import { useLocation } from 'react-router-dom';
import { findPathByAlias } from '@/routes/routesList';
import { useOrderDetailsContent } from '../order-details-view/hooks/use-order-details-content';

const CreateReturnView = () => {
    const [showErrorPage, handleShowErrorPage] = useState(false);
    const appConfig = new AppConfigurationService();
    const authenticationService = new AuthenticationService();
    const cacheService = new CacheService();
    const profileService = new ProfileService();
    const location = useLocation();
    const createReturnContent = useOrderDetailsContent();
    const [profileRequestCompleted, setProfileRequestCompleted] =
        useState<boolean>(false);

    const checkAuthenticationStatus = () => {
        cacheService.evictProfileServiceCache();
        authenticationService
            .onIsAuthenticated()
            .then((isAuthenticated: boolean) => {
                if (isAuthenticated) {
                    profileService
                        .request()
                        .then((profile) => {
                            if (profile) {
                                setProfileRequestCompleted(true);
                            }
                        })
                        .catch(() => {
                            cacheService.evictProfileServiceCache();
                        });
                } else {
                    cacheService.evictProfileServiceCache();
                    authenticationService.login();
                }
            });
    };

    useEffect(() => {
        checkAuthenticationStatus();
    }, []);

    useEffect(() => {
        new ScrollUtil().scrollPageToTop();
        handleShowErrorPage(false);
    }, []);

    return (
        <>
            {!showErrorPage && profileRequestCompleted ? (
                <div
                    className="create-return-container"
                    data-test-id="create-return-view"
                >
                    <Breadcrumbs
                        currentPage={
                            createReturnContent.createReturnBreadcrumbTitle
                        }
                        previousPage={
                            createReturnContent.createReturnBreadcrumbPreviousTitle
                        }
                        secondPreviousPage={
                            createReturnContent.createReturnBreadcrumbSecondPreviousTitle
                        }
                        previousLink={
                            location.state?.orderDetails
                                ? findPathByAlias(
                                      'OrderDetailsViewWithoutOrderNumber',
                                      location.state?.orderDetails
                                  )
                                : findPathByAlias('OrderHistoryView')
                        }
                        secondPreviousLink={findPathByAlias('OrderHistoryView')}
                        crumbSeparator={appConfig.brand === 'ford' ? '/' : '>'}
                    ></Breadcrumbs>
                    <CreateReturnComponent />
                    <hr className="hr-line" aria-hidden={true} />
                    <SearchBar />
                </div>
            ) : showErrorPage ? (
                <ErrorPage />
            ) : (
                <ActivityIndicator className={'full-height'} />
            )}
        </>
    );
};

export default CreateReturnView;
