import React, {
    Dispatch,
    SetStateAction,
    useEffect,
    useRef,
    useState,
} from 'react';
import './tooltip.scss';

const cancelCloseIcon = './icons/cancel-close.svg';
interface TooltipProps {
    content: NonNullable<React.ReactNode>;
    children: NonNullable<React.ReactNode>;
    direction?: 'top' | 'bottom' | 'left' | 'right'; // Tooltip will be positioned on top by default
    delay?: number; // By default tooltip will be display immediately.
    tooltipStyles?: any; // custom tooltip styles
    onDisplayTooltip?: any;
    showTooltip: boolean;
    toggleTooltip: Dispatch<SetStateAction<boolean>>;
    isLincoln: boolean;
    isFord: boolean;
}
const Tooltip = (props: TooltipProps) => {
    const tooltipContentRef = useRef(null);
    const tooltipCustomStyles =
        props.isLincoln && props.tooltipStyles
            ? { ...props.tooltipStyles }
            : {};
    const [closeTooltip, setCloseTooltip] = useState(true);

    const onClick = () => {
        if (closeTooltip) {
            setCloseTooltip(false);
        } else {
            setCloseTooltip(true);
        }
        if (props.showTooltip !== false) {
            props.toggleTooltip(false);
            setCloseTooltip(true);
        } else {
            props.toggleTooltip(true);
        }
    };

    const handleClickOutsideTooltip = (e) => {
        if (
            tooltipContentRef.current &&
            !tooltipContentRef.current.contains(e.target)
        ) {
            props.toggleTooltip(false);
        }
    };

    useEffect(() => {
        document.addEventListener('click', handleClickOutsideTooltip, false);
        return () => {
            document.removeEventListener(
                'click',
                handleClickOutsideTooltip,
                false
            );
        };
    }, []);

    return (
        <div className="tooltip-wrapper">
            {props.children}
            {props.showTooltip && (
                <div
                    ref={tooltipContentRef}
                    id="add-vehicle-tooltip"
                    data-testid="add-vehicle-tooltip"
                    role="dialog"
                    aria-label="Where do I find my VIN?"
                    className={`tooltip-tip ${props.direction || 'top'}`}
                    style={{ ...tooltipCustomStyles }}
                >
                    {props.isFord && (
                        <button
                            ref={tooltipContentRef}
                            data-testid="test-close"
                            onClick={onClick}
                            aria-label="Close Dialog"
                            className="fmc-dialog__close"
                        >
                            <img src={cancelCloseIcon} alt="close-icon"></img>
                        </button>
                    )}
                    {props.content}
                </div>
            )}
        </div>
    );
};

export default Tooltip;
