import React, { useEffect, useState } from 'react';
import SecondaryButton from '@common/secondary-button/secondary-button';
import TertiaryButton from '@/components/common/tertiary-button/tertiary-button';
import AppConfigurationService from '@/services/app-configuration-service/app-configuration-service';
import {
    OmsOrderDetailsResponse,
    OrderLineDetails,
} from '@/services/oms-service/oms-service';
import { ActivityIndicator } from '@/components/common/activity-indicator/activity-indicator';
import { OrderDetailsContent } from '../hooks/use-order-details-content';
import { ProfileWithVehiclesResponse } from '@/models/profile-with-vehicles';
import OrderTotals from '@views/order-details-view/components/order-totals/order-totals';
import OrderDetailsItem from '@views/order-details-view/components/order-details-item/order-details-item';
import randomNumberUtil from '@utils/random-number-util/random-number-util';
import { OrderStatusContent } from '@views/order-details-view/hooks/use-nvc-order-status-content';
import {
    NVC_ORDER_STATUS_MAP,
    OrderStatusOption,
} from '@views/order-details-view/nvc-status-map';
import { useNavigate } from 'react-router-dom';
import { findPathByAlias } from '@/routes/routesList';

const className = 'order-details-component';

interface Props {
    orderDetailsContent: OrderDetailsContent;
    orderStatusContent: OrderStatusContent;
    orderDetailsData: OmsOrderDetailsResponse;
    ordersFinishedLoading: boolean;
    authenticated: ProfileWithVehiclesResponse;
}

const OrderDetailsComponent = (props: Props) => {
    const {
        orderDetailsContent,
        orderDetailsData,
        ordersFinishedLoading,
        orderStatusContent,
    } = props;
    const date = new Date(orderDetailsData?.orderDate);
    const appConfig: AppConfigurationService = new AppConfigurationService();
    const [orderDetailsItems, setOrderDetailsItems] =
        useState<OrderLineDetails[]>(null);
    const languageRegionCode = appConfig.getLanguageRegionCode().toLowerCase();
    const formattedDate = date.toLocaleDateString(languageRegionCode, {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
    });
    const showStartReturnButton = orderDetailsData?.orderLine?.some(
        (item) => item.isStorefrontReturnable === 'Y'
    );
    const deliveryMethod: string = orderDetailsData.orderLine[0].deliveryMethod;
    const navigate = useNavigate();

    const sortItems = (): OrderLineDetails[] => {
        let finalItems: OrderLineDetails[] = [];
        let deliveryMethodTransformed = '';

        if (deliveryMethod === 'SHP') {
            deliveryMethodTransformed = 'shipToHome';
        } else if (deliveryMethod === 'PICK') {
            deliveryMethodTransformed = 'pickUpOrder';
        } else {
            deliveryMethodTransformed = 'installLines';
        }

        NVC_ORDER_STATUS_MAP[deliveryMethodTransformed].forEach(
            (statusOption: OrderStatusOption) => {
                const filteredList = orderDetailsData.orderLine.filter((item) =>
                    statusOption.options.includes(item.status.toLowerCase())
                );

                if (filteredList.length) {
                    finalItems = [...finalItems, ...filteredList];
                }
            }
        );

        return finalItems;
    };

    useEffect(() => {
        if (orderDetailsData && orderDetailsData.orderLine.length) {
            setOrderDetailsItems(sortItems());
        }
    }, [orderDetailsData]);

    return (
        <>
            {orderDetailsData &&
                ordersFinishedLoading &&
                orderDetailsContent.billingAddress && (
                    <div
                        className={`${className}-container`}
                        data-test-id={`${className}`}
                    >
                        <h1
                            className={
                                props.authenticated
                                    ? `${className}-title`
                                    : `${className}-title-unauthenticated`
                            }
                        >
                            {orderDetailsContent.order}{' '}
                            {orderDetailsData.orderNo}
                        </h1>
                        <div className={`${className}-details`}>
                            <p className={`${className}-order-date`}>
                                {orderDetailsContent.orderDate}
                            </p>
                            <p className={`${className}-date`}>
                                {formattedDate}
                            </p>
                            <div className={`${className}-address-container`}>
                                <div
                                    className={`${className}-billing-address-container`}
                                >
                                    <p
                                        className={`${className}-billing-address-title`}
                                    >
                                        {orderDetailsContent.billingAddress}
                                    </p>
                                    <p
                                        className={`${className}-billing-address`}
                                    >
                                        {
                                            orderDetailsData.personInfoBillTo
                                                .addressLine1
                                        }{' '}
                                        {
                                            orderDetailsData.personInfoBillTo
                                                .addressLine2
                                        }{' '}
                                        {', '}
                                        {orderDetailsData.personInfoBillTo.city}
                                        {', '}
                                        {
                                            orderDetailsData.personInfoBillTo
                                                .state
                                        }{' '}
                                        {
                                            orderDetailsData.personInfoBillTo
                                                .zipCode
                                        }
                                    </p>
                                </div>
                                <div
                                    className={`${className}-shipping-address-container`}
                                >
                                    <p
                                        className={`${className}-shipping-address-title`}
                                    >
                                        {orderDetailsContent.shippingAddress}
                                    </p>
                                    <p
                                        className={`${className}-shipping-address`}
                                    >
                                        {
                                            orderDetailsData.personInfoShipTo
                                                .addressLine1
                                        }{' '}
                                        {
                                            orderDetailsData.personInfoShipTo
                                                .addressLine2
                                        }{' '}
                                        {', '}
                                        {orderDetailsData.personInfoShipTo.city}
                                        {', '}
                                        {
                                            orderDetailsData.personInfoShipTo
                                                .state
                                        }{' '}
                                        {
                                            orderDetailsData.personInfoShipTo
                                                .zipCode
                                        }{' '}
                                    </p>
                                </div>
                            </div>
                            {showStartReturnButton ? (
                                <div
                                    className={`${className}-return-buttons-container`}
                                >
                                    <SecondaryButton
                                        dataTestId="start-return-button"
                                        aria-label={
                                            orderDetailsContent.startReturn
                                        }
                                        onClick={() => {
                                            navigate(
                                                findPathByAlias(
                                                    'CreateReturnView'
                                                ),
                                                {
                                                    state: {
                                                        orderDetails:
                                                            'FAE:' +
                                                            window.location?.pathname.split(
                                                                'FAE:'
                                                            )[1],
                                                    },
                                                }
                                            );
                                        }}
                                    >
                                        {orderDetailsContent.startReturn}
                                    </SecondaryButton>
                                    <TertiaryButton
                                        labelText={
                                            orderDetailsContent.returnPolicy
                                        }
                                        ariaLabel={
                                            orderDetailsContent.returnPolicy
                                        }
                                        internal={true}
                                        link={'link'}
                                    ></TertiaryButton>
                                </div>
                            ) : (
                                <div
                                    className={`${className}-return-policy-container`}
                                >
                                    <TertiaryButton
                                        labelText={
                                            orderDetailsContent.returnPolicy
                                        }
                                        ariaLabel={
                                            orderDetailsContent.returnPolicy
                                        }
                                        internal={true}
                                        link={'link'}
                                    ></TertiaryButton>
                                </div>
                            )}
                        </div>
                        <hr className="hr-line" aria-hidden={true} />
                        {orderStatusContent &&
                            orderDetailsItems?.map((item: OrderLineDetails) => {
                                const randomNumberAsKey = randomNumberUtil
                                    .getRandomNumber()
                                    .toFixed(4);

                                return (
                                    <OrderDetailsItem
                                        key={randomNumberAsKey}
                                        itemData={item}
                                        orderDetailsContent={
                                            orderDetailsContent
                                        }
                                        orderStatusContent={orderStatusContent}
                                    />
                                );
                            })}
                        <OrderTotals
                            orderDetailsContent={orderDetailsContent}
                            orderDetailsData={orderDetailsData}
                        />
                        {!ordersFinishedLoading ? (
                            <ActivityIndicator className={'full-height'} />
                        ) : null}
                    </div>
                )}
        </>
    );
};

export default OrderDetailsComponent;
