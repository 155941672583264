import axios from 'axios';
import { BRAND_ATTRIBUTES, SUPPORT_HUB_REGEX } from '../support-constants';
import HttpsProxyAgent from 'https-proxy-agent/dist/agent';

export const httpsAgent = new HttpsProxyAgent('http://internet.ford.com:83');

export default class AstuteService {
    private auth = {
        username: process.env.REACT_APP_ASTUTE_USER_NAME,
        password: process.env.REACT_APP_ASTUTE_USER_PASSWORD,
    };
    public async getSessionIdForSearch(
        languageRegionCode: string,
        brand: string
    ) {
        const searchUrl = (BRAND_ATTRIBUTES as any)[brand].searchUrl;
        return await axios
            .post(
                searchUrl.https + '/init',
                {
                    market: languageRegionCode,
                },
                { httpsAgent, auth: this.auth }
            )
            .then((value) => {
                return value.data.sessionID;
            })
            .catch(async () => {
                return await axios
                    .post(
                        searchUrl.http + '/init',
                        {
                            market: languageRegionCode,
                        },
                        { httpsAgent, auth: this.auth }
                    )
                    .then((value) => {
                        return value.data.sessionID;
                    })
                    .catch((e) => {
                        console.error(
                            `http getSessionIdForSearch - Astute Upstream Failure - ${e.message} for supportedMarket - ${languageRegionCode}`
                        );
                        return e.message;
                    });
            });
    }
    public async getSuggestedResults(
        currentSearchTerm: string,
        sessionID: string,
        brand: string
    ) {
        const searchUrl = (BRAND_ATTRIBUTES as any)[brand].searchUrl;

        if (sessionID) {
            return await axios
                .post(
                    searchUrl.https + '/autocomplete',
                    {
                        sessionID,
                        utterance: currentSearchTerm,
                        suggestionCount: 10,
                    },
                    { httpsAgent, auth: this.auth }
                )
                .then((result) => {
                    let suggestions = result.data.suggestions;
                    suggestions = suggestions.map((item: string) =>
                        item.replace(SUPPORT_HUB_REGEX.ALL_MARKUP_TAGS, '')
                    );
                    return suggestions;
                })
                .catch((e) => {
                    console.error(e.message);
                    return e.message;
                });
        }
        return undefined;
    }
}
