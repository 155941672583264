import React, { useEffect, useState } from 'react';
import { Location, NavLink, useLocation } from 'react-router-dom';
import { ConnectedServicesModalContentProps } from './hook/use-connected-services-modal-content';
import ProfileService from '@services/profile-service/profile-service';
import AuthenticationService from '@services/authentication-service/__mocks__/authentication-service';
import { useAnalytics } from '@hooks/use-analytics';
import { findPathByAlias } from '@routes/routesList';
import AppConfigurationService from '@services/app-configuration-service/app-configuration-service';
import { ModalContextProps, useModalContext } from '@contexts/modalContext';
import { SubscriptionCountsWithVIN } from '@smart-tiles/smart-tile-connected-services/smart-tile-connected-services';
import {
    buildCampaignIdQueryParameter,
    buildChargerAdapterStatusQueryParameter,
} from '@utils/router-util/router-util';
import './connected-services-modal.scss';

interface Props {
    isVisible: boolean;
    visibleAddNewVehicleModal?: string;
    onClose?: () => void;
    connectedServicesModalContent: ConnectedServicesModalContentProps;
    subscriptionCountsWithVINList: SubscriptionCountsWithVIN[];
}

interface VehicleOptions {
    model: string;
    nickname: string;
    vin: string;
    ctaLabel: string;
    year: string;
    ctaHref: string;
    paidSubscriptions: number;
    paidSubscriptionsExpiring: number;
    freeSubscriptions: number;
    freeSubscriptionsExpiring: number;
}

const ConnectedServicesModal = (props: Props) => {
    const isLincoln = new AppConfigurationService().brand === 'lincoln';
    const [fireAnalytics] = useAnalytics();
    const [profileData, setProfileData] = useState<any>(null);
    const profileService = new ProfileService();
    const authenticationService = new AuthenticationService();
    const { setContext, resetContext } = useModalContext();
    const location = useLocation();
    const chargerAdapterStatus = new URLSearchParams(location.search).get(
        'keyName'
    );

    const closeOrCancelModal = () => {
        props.onClose();
        resetContext();
    };

    useEffect(() => {
        authenticationService.onIsAuthenticated().then((isAuthenticated) => {
            if (isAuthenticated) {
                profileService.request().then((profile) => {
                    if (profile) setProfileData(profile);
                    else setProfileData(null);
                });
            }
        });
    }, [props.isVisible === true]);

    const connectedServiceUrl = findPathByAlias('ConnectedServicesView');

    const vehicleData = profileData?.vehicles.map((data) => {
        const { modelName, vin, modelYear, nickName } = data;
        if (props.connectedServicesModalContent) {
            const subscriptionCounts =
                props.subscriptionCountsWithVINList?.find(
                    (object) => object.vin === vin
                );

            return {
                model: modelName,
                nickname: nickName,
                vin: vin,
                ctaLabel: props.connectedServicesModalContent.btnSelect,
                year: modelYear,
                ctaHref: `${connectedServiceUrl}?vin=${vin}${buildCampaignIdQueryParameter()}${buildChargerAdapterStatusQueryParameter(
                    !!chargerAdapterStatus
                )}`,
                paidSubscriptions: subscriptionCounts?.paidSubscriptions,
                paidSubscriptionsExpiring:
                    subscriptionCounts?.paidSubscriptionsExpiring,
                freeSubscriptions: subscriptionCounts?.freeSubscriptions,
                freeSubscriptionsExpiring:
                    subscriptionCounts?.freeSubscriptionsExpiring,
            } as VehicleOptions;
        }
    });

    const handleVehicleSelect = () => {
        fireAnalytics('chooseVehicleOnclickEvent');
    };
    const modalContent = () => (
        <>
            <h2
                className={`fmc-type--heading5 ${
                    isLincoln ? 'fmc-mb-6 fmc-pb-1' : ''
                }`}
                data-testId="connected-services-modal-header"
            >
                {props.connectedServicesModalContent?.modalHeader}
            </h2>
            {vehicleData?.length > 0 &&
                vehicleData.map((vehicle, index) => {
                    if (vehicle.vin) {
                        return (
                            <div
                                className={`vehicle-listing${
                                    index > 0 ? ' multiple-vehicle' : ''
                                }`}
                                key={`${index}-${vehicle.vin}`}
                            >
                                <div className="vehicle-info">
                                    {isLincoln && (
                                        <h3 className="fmc-type--heading4 vehicle-info__name">
                                            {vehicle.nickname
                                                ? vehicle.nickname
                                                : `My ${vehicle.model}`}
                                        </h3>
                                    )}
                                    <p
                                        className={`fmc-type--body1 ${
                                            isLincoln
                                                ? 'fds-color__text--primary vehicle-info__ymm'
                                                : ''
                                        }`}
                                    >
                                        {vehicle.year} {vehicle.model}
                                    </p>
                                    <p className="fmc-type--content2 fds-color__text--gray3 vehicle-info__vin">
                                        {`${props.connectedServicesModalContent.vinLabel} ${vehicle.vin}`}
                                    </p>
                                    <div className="subscription-counts-container">
                                        {vehicle.paidSubscriptions &&
                                        props.connectedServicesModalContent
                                            .paidSubscriptionsActiveText ? (
                                            <p
                                                className="fmc-type--content2 vehicle-info__subscription-count active"
                                                data-testid={`connected-services-modal-paid-active-${index}`}
                                            >
                                                {`${vehicle.paidSubscriptions} ${props.connectedServicesModalContent.paidSubscriptionsActiveText}`}
                                            </p>
                                        ) : null}
                                        {vehicle.freeSubscriptions &&
                                        props.connectedServicesModalContent
                                            .freeTrialSubscriptionsActiveText ? (
                                            <p
                                                className="fmc-type--content2 vehicle-info__subscription-count active"
                                                data-testid={`connected-services-modal-free-trial-active-${index}`}
                                            >
                                                {`${vehicle.freeSubscriptions} ${props.connectedServicesModalContent.freeTrialSubscriptionsActiveText}`}
                                            </p>
                                        ) : null}
                                        {vehicle.paidSubscriptionsExpiring &&
                                        props.connectedServicesModalContent
                                            .paidSubscriptionsExpiringText ? (
                                            <p
                                                className="fmc-type--content2 vehicle-info__subscription-count expiring"
                                                data-testid={`connected-services-modal-paid-expiring-${index}`}
                                            >
                                                {`${vehicle.paidSubscriptionsExpiring} ${props.connectedServicesModalContent.paidSubscriptionsExpiringText}`}
                                            </p>
                                        ) : null}
                                        {vehicle.freeSubscriptionsExpiring &&
                                        props.connectedServicesModalContent
                                            .freeTrialSubscriptionsExpiringText ? (
                                            <p
                                                className="fmc-type--content2 vehicle-info__subscription-count expiring"
                                                data-testid={`connected-services-modal-free-trial-expiring-${index}`}
                                            >
                                                {`${vehicle.freeSubscriptionsExpiring} ${props.connectedServicesModalContent.freeTrialSubscriptionsExpiringText}`}
                                            </p>
                                        ) : null}
                                    </div>
                                </div>
                                <div className="connected-services-button-container">
                                    <div>
                                        <NavLink
                                            to={vehicle.ctaHref}
                                            onClick={() => {
                                                handleVehicleSelect();
                                                closeOrCancelModal();
                                            }}
                                            className="fmc-button"
                                            data-testid={`connected-services-modal-cta-${index}`}
                                        >
                                            <span className="secondary-button-text">
                                                {
                                                    props
                                                        .connectedServicesModalContent
                                                        .btnSelect
                                                }
                                            </span>
                                            {isLincoln && (
                                                <span className="fds-icon fds-font--ford-icons__chevron-right fds-icon--offset-right"></span>
                                            )}
                                        </NavLink>
                                    </div>
                                </div>
                            </div>
                        );
                    }
                })}
        </>
    );
    const connectedServiceModalProps: ModalContextProps = {
        modalType: {
            name: 'connected-services-modal',
            primaryButtonLabel: '',
            secondaryButtonLabel: props.connectedServicesModalContent.btnCancel,
            onPrimaryButtonClick: closeOrCancelModal,
            onSecondaryButtonClick: closeOrCancelModal,
            onAfterClose: closeOrCancelModal,
            children: modalContent(),
        },
    };

    useEffect(() => {
        if (
            props.connectedServicesModalContent &&
            props.isVisible &&
            vehicleData?.length >= 2
        ) {
            setContext(connectedServiceModalProps);
        }
    }, [props.isVisible, vehicleData?.length]);

    return <></>;
};

export default ConnectedServicesModal;
