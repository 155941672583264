import { DSL_BASE_URL, DSL_API_PATH } from '@constants';
import HttpService from '../http-service/http-service';
import httpAdapter from 'axios/lib/adapters/http';
import { proxyAgent } from '../vehicle-list-service/vehicle-list-base-service';
import AuthenticationService from '../authentication-service/authentication-service';

export default class UserCommunicationPreferenceService {
    public async updateNaCommunicationPreference(
        userPreference,
        getAppConfiguration
    ): Promise<boolean> {
        const dslUrl = getAppConfiguration.dslUrl
            ? getAppConfiguration.dslUrl
            : DSL_BASE_URL;
        const url =
            dslUrl +
            DSL_API_PATH.UPDATE_NA_PRIVACY_PREFERENCE +
            '?sendMarketingEmails=' +
            userPreference +
            '&countryCode=' +
            getAppConfiguration.threeLetterCountryCode.toUpperCase();
        const authenticationService = new AuthenticationService();
        const payloadRegion = {};
        return HttpService.post<any>(url, payloadRegion, {
            headers: HttpService.getConsumerKeyAndAuthTokenRequestHeaders(),
            adapter: httpAdapter,
            proxy: false,
            httpAgent: proxyAgent,
        })
            .then((response) => {
                return response.data;
            })
            .catch((e) => {
                if (e.response.status === 401) {
                    console.error(`User Timeout`);
                    authenticationService.login();
                } else return false;
            });
    }

    public async updateImgCommunicationPreference(
        userPreference,
        getAppConfiguration,
        threeLetterCountryCode
    ): Promise<boolean> {
        const dslUrl = getAppConfiguration.dslUrl
            ? getAppConfiguration.dslUrl
            : DSL_BASE_URL;
        const url = dslUrl + DSL_API_PATH.UPDATE_IMG_PRIVACY_PREFERENCE;
        const authenticationService = new AuthenticationService();
        const payloadRegion = {
            optIn: userPreference,
            countryCode: threeLetterCountryCode.toUpperCase(),
            region: 'img',
        };
        return HttpService.post<any>(url, payloadRegion, {
            headers: HttpService.getConsumerKeyAndAuthTokenRequestHeaders(),
            adapter: httpAdapter,
            proxy: false,
            httpAgent: proxyAgent,
        })
            .then((response) => {
                return response.data;
            })
            .catch((e) => {
                if (e.response.status === 401) {
                    console.error(`User Timeout`);
                    authenticationService.login();
                } else return false;
            });
    }
}
