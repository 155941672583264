import React, { Dispatch, SetStateAction, useState } from 'react';
import PrimaryButton from '@common/primary-button/primary-button';
import { LinkButton } from '@common/modal/link-button/link-button';
import { UnitsOfMeasurementLabels } from '@views/account-settings-view/hooks/use-units-of-measurement-content';
import { RadioButtonGroup } from '@common/form-fields/form-fields';
import { useAnalytics } from '@hooks/use-analytics';
import UnitsOfMeasurementService from '@services/units-of-measurement-service/units-of-measurement-service';
import HttpService from '@services/http-service/http-service';
import { CacheService } from '@services/cache-service/cache-service';
import {
    NotificationType,
    useNotificationContext,
} from '@contexts/notificationContext';
import ScrollUtil from '@utils/scroll-to-top-util/scroll-to-top-util';
import AuthenticationService from '@services/authentication-service/authentication-service';
import { useWindowSize } from '@hooks/use-window-size';
import { TABLET_BREAKPOINT } from '@services/support-constants';
import { Measurements } from '@views/account-settings-view/account-settings-view';
import './units-of-measurement.scss';
import { DSL_API_PATH, DSL_BASE_URL, SESSION_KEY_API_CACHE } from '@constants';

interface UnitsOfMeasurementProps {
    appConfig: any;
    uomDistance: number;
    uomPressure: string;
    uomTemperature: string;
    uomWeight: string;
    updateSuccess: (measurement: Measurements) => void;
    unitsOfMeasurementContent: UnitsOfMeasurementLabels;
    setNotificationStatus: Dispatch<SetStateAction<NotificationType>>;
}

interface UnitsOfMeasurementOptions {
    name: string;
    value: string | number;
    displayName: string;
}

export const UnitsOfMeasurement = ({
    appConfig,
    uomDistance,
    uomPressure,
    uomTemperature,
    uomWeight,
    updateSuccess,
    unitsOfMeasurementContent,
    setNotificationStatus,
}: UnitsOfMeasurementProps) => {
    const [fireAnalytics] = useAnalytics();
    const isLincoln: boolean = appConfig.brand === 'lincoln';
    const isFord: boolean = appConfig.brand === 'ford';
    const size = useWindowSize();
    const isMobile = size.width <= TABLET_BREAKPOINT;
    const httpService = HttpService;
    const authenticationService = new AuthenticationService();
    const { setNotificationContext } = useNotificationContext();
    const unitsOfMeasurementService = new UnitsOfMeasurementService(
        httpService,
        authenticationService
    );
    const scrollUtil = new ScrollUtil();
    const [isMeasurementEditable, setIsMeasurementsEditable] = useState(false);

    const handleEdit = () => {
        setIsMeasurementsEditable(!isMeasurementEditable);
    };
    const distanceOptions: UnitsOfMeasurementOptions[] = [
        {
            name: 'uomDistance',
            value: 2,
            displayName: unitsOfMeasurementContent?.kilometers,
        },
        {
            name: 'uomDistance',
            value: 1,
            displayName: unitsOfMeasurementContent?.miles,
        },
    ];
    const pressureOptions: UnitsOfMeasurementOptions[] = [
        {
            name: 'uomPressure',
            value: 'BAR',
            displayName: unitsOfMeasurementContent?.bar,
        },
        {
            name: 'uomPressure',
            value: 'KPA',
            displayName: unitsOfMeasurementContent?.kPa,
        },
        {
            name: 'uomPressure',
            value: 'PSI',
            displayName: unitsOfMeasurementContent?.psi,
        },
    ];

    const temperatureOptions: UnitsOfMeasurementOptions[] = [
        {
            name: 'uomTemperature',
            value: 'C',
            displayName: unitsOfMeasurementContent?.celsius,
        },
        {
            name: 'uomTemperature',
            value: 'F',
            displayName: unitsOfMeasurementContent?.fahrenheit,
        },
    ];

    const weightOptions: UnitsOfMeasurementOptions[] = [
        {
            name: 'uomWeight',
            value: 'KG',
            displayName: unitsOfMeasurementContent?.kilos,
        },
        {
            name: 'uomWeight',
            value: 'LB',
            displayName: unitsOfMeasurementContent?.pounds,
        },
    ];
    const [distanceValue, setDistanceValue] = useState(uomDistance);
    const [tirePressureValue, setTirePressureValue] = useState(uomPressure);
    const [temperatureValue, setTemperatureValue] = useState(uomTemperature);
    const [weightValue, setWeightValue] = useState(uomWeight);

    // handle distance input change
    const handleDistanceChange = (updatedValue) => {
        setDistanceValue(updatedValue);
    };

    // handle tire pressure input change
    const handleTirePressureChange = (updatedValue) => {
        setTirePressureValue(updatedValue);
    };

    // handle temperature input change
    const handleTemperatureChange = (updatedValue) => {
        setTemperatureValue(updatedValue);
    };

    // handle weight input change
    const handleWeightChange = (updatedValue) => {
        setWeightValue(updatedValue);
    };

    //mapping distance string value with the received number
    const handleDistanceValue = (number) => {
        if (number === 2) {
            return unitsOfMeasurementContent?.kilometers;
        }
        if (number === 1) {
            return unitsOfMeasurementContent?.miles;
        }
    };

    const handleUpdate = async () => {
        setNotificationStatus(null);
        const distanceVal = Number(distanceValue);
        const pressureVal = tirePressureValue;
        const temperatureVal = temperatureValue;
        const weightVal = weightValue;
        const cacheService = new CacheService();

        if (distanceVal || pressureVal || temperatureVal || weightVal) {
            await unitsOfMeasurementService
                .updateMeasurements(
                    distanceVal,
                    pressureVal,
                    temperatureVal,
                    weightVal
                )
                .then((responseForMeasurements) => {
                    if (responseForMeasurements?.status == 200) {
                        cacheService.evictProfileServiceCache();
                        cacheService.evictFromCache(
                            SESSION_KEY_API_CACHE,
                            `${
                                DSL_BASE_URL + DSL_API_PATH.UNITS_OF_MEASUREMENT
                            }`
                        );
                        updateSuccess({
                            distanceVal,
                            pressureVal,
                            temperatureVal,
                            weightVal,
                        });
                        if (isFord) {
                            setNotificationStatus(NotificationType.Success);
                        } else {
                            setNotificationContext(
                                NotificationType.Success,
                                false
                            );
                        }
                        setIsMeasurementsEditable(false);
                    } else {
                        scrollUtil.scrollPageToTop();
                        if (isFord) {
                            setNotificationStatus(NotificationType.Error);
                        } else {
                            setNotificationContext(
                                NotificationType.Error,
                                false
                            );
                        }
                    }
                });
        }
    };

    const handleCancel = () => {
        setDistanceValue(uomDistance);
        setTirePressureValue(uomPressure);
        setTemperatureValue(uomTemperature);
        setWeightValue(uomWeight);
        setIsMeasurementsEditable(false);
        scrollUtil.scrollPageToTop();
    };

    return (
        <div className="fmc-mb-8-xs" data-testid="units-of-measurement">
            <div className="account-view-section fmc-mt-4-xs">
                <h2 className="fmc-type--heading6 fds-color__text--primary account-view-section__title">
                    {unitsOfMeasurementContent?.unitsOfMeasurement}
                </h2>

                <div
                    className="edit-button-container"
                    aria-hidden={isMeasurementEditable}
                >
                    {!isMeasurementEditable ? (
                        <LinkButton
                            label={unitsOfMeasurementContent?.btnEdit}
                            ariaLabel={
                                unitsOfMeasurementContent?.editUnitsOfMeasurementAriaLabel
                            }
                            dataTestId="units-of-measurement-edit-btn"
                            class="edit-link"
                            onClick={() => {
                                fireAnalytics(
                                    'unitsOfMeasurementEditOnclickEvent'
                                );
                                handleEdit();
                            }}
                        />
                    ) : null}
                </div>
            </div>
            {!isMeasurementEditable || isFord ? (
                <hr className="hr-line mB0" aria-hidden={true} />
            ) : null}

            <div
                className={`units-of-measurement-info-container${
                    isMeasurementEditable ? ' edit' : ''
                } ${
                    isLincoln ? 'fmc-pt-5-xs fmc-pt-5-sm fmc-pt-8 fmc-pb-8' : ''
                }`}
            >
                {!isMeasurementEditable ? (
                    <>
                        {unitsOfMeasurementContent?.distanceSectionHeader && (
                            <div className="fds-layout-grid__inner units-of-measurement-wrapper fmc-pt-1-xs">
                                <h3 className="fmc-type--heading6 fmc-mb-1-xs fds-layout-grid__cell--span-4">
                                    {
                                        unitsOfMeasurementContent?.distanceSectionHeader
                                    }
                                </h3>
                                <div
                                    className="fmc-type--body1 fds-layout-grid__cell--span-4-xs fds-layout-grid__cell--span-8"
                                    data-testid="uom-distance-value"
                                >
                                    {handleDistanceValue(Number(uomDistance))}
                                </div>
                            </div>
                        )}
                        {unitsOfMeasurementContent?.tirePressureSectionHeader && (
                            <div className="fds-layout-grid__inner units-of-measurement-wrapper fmc-pb-1-xs fmc-pt-5">
                                <h3 className="fmc-type--heading6 fmc-mb-1-xs fds-layout-grid__cell--span-4">
                                    {
                                        unitsOfMeasurementContent?.tirePressureSectionHeader
                                    }
                                </h3>
                                <div
                                    className="fmc-type--body1 fmc-mb-2-xs fds-layout-grid__cell--span-4-xs fds-layout-grid__cell--span-8"
                                    data-testid="uom-tire-pressure-value"
                                >
                                    {uomPressure.toLowerCase() === 'kpa'
                                        ? 'kPa'
                                        : uomPressure.toLowerCase()}
                                </div>
                            </div>
                        )}
                        {unitsOfMeasurementContent?.temperatureSectionHeader && (
                            <div
                                className={`fds-layout-grid__inner units-of-measurement-wrapper fmc-pb-1-xs fmc-pt-3 ${
                                    isMobile ? 'fmc-pt-md-5' : 'fmc-pt-5'
                                }`}
                            >
                                <h3 className="fmc-type--heading6 fmc-mb-1-xs fds-layout-grid__cell--span-4">
                                    {
                                        unitsOfMeasurementContent?.temperatureSectionHeader
                                    }
                                </h3>
                                <div
                                    className="fmc-type--body1 fmc-mb-2-xs fds-layout-grid__cell--span-4-xs fds-layout-grid__cell--span-8"
                                    data-testid="uom-temperature-value"
                                >
                                    {uomTemperature?.toLowerCase() === 'c'
                                        ? unitsOfMeasurementContent?.celsius
                                        : uomTemperature?.toLowerCase() === 'f'
                                        ? unitsOfMeasurementContent.fahrenheit
                                        : null}
                                </div>
                            </div>
                        )}
                        {unitsOfMeasurementContent?.weightSectionHeader && (
                            <div
                                className={`fds-layout-grid__inner units-of-measurement-wrapper fmc-pb-1-xs fmc-pt-3 ${
                                    isMobile ? 'fmc-pt-md-5' : 'fmc-pt-5'
                                }`}
                            >
                                <h3 className="fmc-type--heading6 fmc-mb-1-xs fds-layout-grid__cell--span-4">
                                    {
                                        unitsOfMeasurementContent?.weightSectionHeader
                                    }
                                </h3>
                                <div
                                    className="fmc-type--body1 fmc-mb-2-xs fds-layout-grid__cell--span-4-xs fds-layout-grid__cell--span-8"
                                    data-testid="uom-weight-value"
                                >
                                    {uomWeight?.toLowerCase() === 'kg'
                                        ? unitsOfMeasurementContent?.kilos
                                        : uomWeight?.toLowerCase() === 'lb'
                                        ? unitsOfMeasurementContent.pounds
                                        : null}
                                </div>
                            </div>
                        )}
                    </>
                ) : (
                    <div
                        className={`units-of-measurement-container ${
                            isLincoln
                                ? 'fmc-px-3-xs fmc-px-9-sm fmc-px-9-md fmc-px-11'
                                : ''
                        }`}
                    >
                        {unitsOfMeasurementContent?.distanceFieldLabel && (
                            <div className="units-of-measurement-wrapper edit fmc-px-0 fmc-px-1-lg fmc-px-1-xl">
                                <RadioButtonGroup
                                    handleChange={handleDistanceChange}
                                    RadioButtonOptions={distanceOptions}
                                    defaultCheckedOption={distanceValue}
                                    radioGroupName={
                                        unitsOfMeasurementContent?.distanceFieldLabel
                                    }
                                    radioGroupNameClass={
                                        'fmc-type--heading6 fmc-pb-4 fmc-mb-1'
                                    }
                                />
                            </div>
                        )}
                        {unitsOfMeasurementContent?.tirePressureFieldLabel && (
                            <div className="units-of-measurement-wrapper edit fmc-px-0 fmc-px-1-lg fmc-px-1-xl">
                                <RadioButtonGroup
                                    handleChange={handleTirePressureChange}
                                    RadioButtonOptions={pressureOptions}
                                    defaultCheckedOption={tirePressureValue}
                                    radioGroupName={
                                        unitsOfMeasurementContent?.tirePressureFieldLabel
                                    }
                                    radioGroupNameClass={
                                        'fmc-type--heading6 fmc-pb-4 fmc-mb-1'
                                    }
                                />
                            </div>
                        )}
                        {unitsOfMeasurementContent?.temperatureFieldLabel && (
                            <div className="units-of-measurement-wrapper edit fmc-px-0 fmc-px-1-lg fmc-px-1-xl">
                                <RadioButtonGroup
                                    handleChange={handleTemperatureChange}
                                    RadioButtonOptions={temperatureOptions}
                                    defaultCheckedOption={temperatureValue}
                                    radioGroupName={
                                        unitsOfMeasurementContent?.temperatureFieldLabel
                                    }
                                    radioGroupNameClass={
                                        'fmc-type--heading6 fmc-pb-4 fmc-mb-1'
                                    }
                                />
                            </div>
                        )}
                        {unitsOfMeasurementContent?.weightFieldLabel && (
                            <div className="units-of-measurement-wrapper edit fmc-px-0 fmc-px-1-lg fmc-px-1-xl">
                                <RadioButtonGroup
                                    handleChange={handleWeightChange}
                                    RadioButtonOptions={weightOptions}
                                    defaultCheckedOption={weightValue}
                                    radioGroupName={
                                        unitsOfMeasurementContent?.weightFieldLabel
                                    }
                                    radioGroupNameClass={
                                        'fmc-type--heading6 fmc-pb-4 fmc-mb-1'
                                    }
                                />
                            </div>
                        )}

                        {isLincoln && (
                            <div className="units-of-measurement-buttons-container">
                                <PrimaryButton
                                    onClick={handleUpdate}
                                    testId="edit-measurements-update-btn"
                                >
                                    {unitsOfMeasurementContent?.btnUpdate}
                                </PrimaryButton>

                                <LinkButton
                                    label={unitsOfMeasurementContent?.btnCancel}
                                    onClick={handleCancel}
                                />
                            </div>
                        )}
                        {isFord && (
                            <div className="units-of-measurement-buttons-container">
                                <LinkButton
                                    label={unitsOfMeasurementContent?.btnCancel}
                                    onClick={handleCancel}
                                    class="units-of-measurement-cancel"
                                />
                                <PrimaryButton
                                    onClick={handleUpdate}
                                    testId="edit-measurements-update-btn"
                                >
                                    {unitsOfMeasurementContent?.btnUpdate}
                                </PrimaryButton>
                            </div>
                        )}
                    </div>
                )}
            </div>
        </div>
    );
};
