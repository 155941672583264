import { useEffect, useState } from 'react';
import { ProfileWithVehiclesResponse } from '../../../../models/profile-with-vehicles';
import ProfileService from '@services/profile-service/profile-service';
import AuthenticationService from '@services/authentication-service/authentication-service';

export const useProfileData = (): ProfileWithVehiclesResponse | null => {
    const [profileData, setProfileData] =
        useState<ProfileWithVehiclesResponse | null>(null);

    const getProfileData = () => {
        new AuthenticationService()
            .onIsAuthenticated()
            .then((isAuthenticated: boolean) => {
                if (isAuthenticated) {
                    new ProfileService()
                        .request()
                        .then((profile) => {
                            if (profile) {
                                profile.getProfileData = getProfileData;
                                setProfileData(profile);
                            } else {
                                setProfileData(null);
                            }
                        })
                        .catch((err) => {
                            setProfileData(err);
                        });
                } else {
                    setProfileData(null);
                    new AuthenticationService().login();
                }
            });
    };

    useEffect(() => {
        getProfileData();
    }, []);
    return profileData;
};
