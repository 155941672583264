import React, { useEffect, useState } from 'react';
import { useOrderDetailsContent } from '@views/order-details-view/hooks/use-order-details-content';
import { ActivityIndicator } from '@common/activity-indicator/activity-indicator';
import AuthenticationService from '@services/authentication-service/authentication-service';
import { SearchBar } from '@/components/sections';
import './return-confirmation-view.scss';
import { Breadcrumbs } from '@sections/breadcrumbs/breadcrumbs';
import AppConfigurationService from '@services/app-configuration-service/app-configuration-service';
import { findPathByAlias } from '@routes/routesList';
import { useLocation } from 'react-router-dom';

const ReturnConfirmationView = () => {
    const appConfig = new AppConfigurationService();
    const location = useLocation();
    const orderDetailsContent = useOrderDetailsContent();
    const authenticationService = new AuthenticationService();
    const [authenticated, setAuthenticated] = useState<boolean>(false);
    const hasOrderDetailsContent: boolean =
        Object.keys(orderDetailsContent).length > 0;

    useEffect(() => {
        authenticationService.onIsAuthenticated().then((isAuthenticated) => {
            if (isAuthenticated) {
                setAuthenticated(true);
            } else {
                authenticationService.login();
            }
        });
    }, []);

    return (
        <>
            {hasOrderDetailsContent && authenticated ? (
                <div
                    className="return-confirmation-view__container"
                    data-testid="return-confirmation-view-container"
                >
                    <Breadcrumbs
                        currentPage={orderDetailsContent.returnsbreadcrumbTitle}
                        previousPage={
                            orderDetailsContent.createReturnBreadcrumbPreviousTitle
                        }
                        previousLink={findPathByAlias(
                            'OrderDetailsViewWithoutOrderNumber',
                            location.state?.orderDetails
                        )}
                        secondPreviousPage={
                            orderDetailsContent.createReturnBreadcrumbSecondPreviousTitle
                        }
                        secondPreviousLink={findPathByAlias('OrderHistoryView')}
                        crumbSeparator={appConfig.brand === 'ford' ? '/' : '>'}
                    />
                    <div
                        className="return-confirmation-view__content"
                        data-testid="return-confirmation-view-content"
                    >
                        <h1
                            className="return-confirmation-view__header"
                            data-testid="return-confirmation-view-header"
                        >
                            {orderDetailsContent.completeReturnPageHeaderText}
                        </h1>
                        <p
                            className="return-confirmation-view__subheader"
                            data-testid="return-confirmation-view-subheader"
                        >
                            {
                                orderDetailsContent.completeReturnPageSubheaderText
                            }
                        </p>
                    </div>
                </div>
            ) : (
                <ActivityIndicator className="return-confirmation-view__loading-spinner" />
            )}
            <hr className="return-confirmation-view__divider" />
            <SearchBar />
        </>
    );
};

export default ReturnConfirmationView;
